import { PlatformLayout } from 'assets/layouts/PlatformLayout';
import { Home } from 'assets/pages/Home';
import { ImageEdit } from 'assets/pages/ImageEdit';
import { NewVerification } from 'assets/pages/NewVerification';
import { NewVerificationRegistration } from 'assets/pages/NewVerificationRegistration';
import { AllVerificationsAdminView } from 'assets/pages/AllVerificationsAdminView';
import { PendingVerifications } from 'assets/pages/PendingVerifications';
import { LogoDetection } from 'assets/pages/LogoDetection';
import { CompletedVerifications } from 'assets/pages/CompletedVerifications';
import { ViewStats } from 'assets/pages/ViewStats';
import { SignUp } from 'assets/pages/SignUp';
import { ViewVerification } from 'assets/pages/ViewVerification';
import React, { useEffect, useState } from 'react'
import { Route, Routes } from "react-router-dom"
import { Login } from 'assets/pages/Login';
import { WebsiteLayout } from 'assets/layouts/WebsiteLayout';
import { Landing } from 'assets/pages/Landing';
import { AdminOverview } from 'assets/pages/AdminOverview';
import { auth, isLoggedIn, useAuthListener } from './firebase'
import { VerifierTest } from 'assets/pages/VerifierTest';
import { TestProducts } from 'assets/pages/TestProducts';
import { TestVerification } from 'assets/pages/TestVerification';
import { TestExplanation } from 'assets/pages/TestExplanation';
import { LandingHome } from 'assets/pages/landing/LandingHome';
import { LandingVerifiers } from 'assets/pages/landing/LandingVerifiers';
import { LandingAbout } from 'assets/pages/landing/LandingAbout';
import { AccountSettings } from 'assets/pages/AccountSettings';
import { MyPayments } from 'assets/pages/MyPayments';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TestCompleted } from 'assets/pages/TestCompleted';

import ReactGA from 'react-ga';
let Hotjar = require('util/hotjar.js');

ReactGA.initialize('UA-169828688-01');
Hotjar(1871968, 6)


const App = () => {
  const notify = (string) => toast(string);

  // console.log("REACTGA ", window.location.pathname)
  ReactGA.pageview(window.location.pathname + window.location.search);

  return (
    <div className='min-h-screen' >
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <Routes>
          <Route path="/home" element={<PlatformLayout item={<Home notify={notify} />} />} />
          <Route path="/new-verification" element={<PlatformLayout item={<NewVerification />} />} />
          <Route path="/stats" element={<PlatformLayout item={<ViewStats />} />} />
          <Route path="/create/:id" element={<PlatformLayout item={<NewVerificationRegistration />} />}/>
          <Route path="/pending" element={<PlatformLayout item={<PendingVerifications />} />}/>
          <Route path="/performed" element={<PlatformLayout item={<CompletedVerifications />} />}/>
          <Route path="/verifications-admin-view" element={<PlatformLayout item={<AllVerificationsAdminView />} />} />
          <Route path="/items/:id" element={<PlatformLayout item={<ViewVerification />} />} />
          <Route path="/my-payments/" element={<PlatformLayout item={<MyPayments />} />} />
          <Route path="/review/:product/:image" element={<PlatformLayout item={<ImageEdit />}/>} />
          <Route path="/login" element={<WebsiteLayout item={<Login />} />} />

          <Route path="/test-explanation" element={<PlatformLayout item={<TestExplanation />} />} />

          <Route path="/find-logos" element={<PlatformLayout item={<LogoDetection />} />} />
          <Route path="/signup" element={<WebsiteLayout item={<SignUp notify={notify} />} />}/>
          {/* <Route path="/login" element={<PlatformLayout item={<Login />} />} /> */}
          <Route path="/find-logos" element={<PlatformLayout item={<LogoDetection />} />} />

          <Route path="/test" element={<PlatformLayout item={<VerifierTest />} />} />
          {/* <Route path="/test/:type" element={<PlatformLayout item={<TestProducts />} />} />
          <Route path="/start-test/:type/:id" element={<PlatformLayout item={<TestVerification />} />} /> */}
          <Route path="/start-test/" element={<PlatformLayout item={<TestVerification notify={notify}/>} />} />
          <Route path="/end-test/" element={<PlatformLayout item={<TestCompleted notify={notify}/>} />} />

          <Route path="/admin" element={<PlatformLayout item={<AdminOverview notify={notify} />} />} />


          <Route path="/" element={<WebsiteLayout item={<LandingHome/>} />} />
          <Route path="/verifiers" element={<WebsiteLayout item={<LandingVerifiers/>} />} />
          <Route path="/marketplace" element={<WebsiteLayout item={<LandingHome/>} />} />
          <Route path="/about" element={<WebsiteLayout item={<LandingAbout notify={notify} />} />} />

          <Route path="/account" element={<PlatformLayout item={<AccountSettings  notify={notify}/>} />}/>


        </Routes>
    </div>
  );
}

export default App;
