import * as React from "react"

function QualityIconYellow(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_698_142)">
        <path
          d="M40.5 38.75l-3.5-3.5c.625-1 1-2.25 1-3.5 0-3.5-2.75-6.25-6.25-6.25s-6.25 2.75-6.25 6.25S28.25 38 31.75 38c1.25 0 2.5-.375 3.5-1l3.5 3.5 1.75-1.75zm-12.5-7c0-2.125 1.625-3.75 3.75-3.75s3.75 1.625 3.75 3.75-1.625 3.75-3.75 3.75S28 33.875 28 31.75zM13 23h12.5v2.5H13V23zm0-7.5h15V18H13v-2.5z"
          fill="#010101"
        />
        <path
          d="M20.5 37.625l-6.5-3.5c-3.75-1.875-6-5.75-6-9.875V8h25v13.75h2.5V8c0-1.375-1.125-2.5-2.5-2.5H8A2.507 2.507 0 005.5 8v16.25c0 5.125 2.75 9.75 7.25 12.125L20.5 40.5v-2.875z"
          fill="#010101"
        />
        <path
          d="M37.5 36.75l-3.5-3.5c.625-1 1-2.25 1-3.5 0-3.5-2.75-6.25-6.25-6.25s-6.25 2.75-6.25 6.25S25.25 36 28.75 36c1.25 0 2.5-.375 3.5-1l3.5 3.5 1.75-1.75zm-12.5-7c0-2.125 1.625-3.75 3.75-3.75s3.75 1.625 3.75 3.75-1.625 3.75-3.75 3.75S25 31.875 25 29.75zM10 21h12.5v2.5H10V21zm0-7.5h15V16H10v-2.5z"
          fill="#F1D848"
        />
        <path
          d="M17.5 35.625l-6.5-3.5c-3.75-1.875-6-5.75-6-9.875V6h25v13.75h2.5V6c0-1.375-1.125-2.5-2.5-2.5H5A2.507 2.507 0 002.5 6v16.25c0 5.125 2.75 9.75 7.25 12.125L17.5 38.5v-2.875z"
          fill="#F1D848"
        />
      </g>
      <defs>
        <clipPath id="clip0_698_142">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default QualityIconYellow
