import * as React from "react"

function SixYellow(props) {
  return (
    <svg
      width={153}
      height={51}
      viewBox="0 0 153 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.84 26.4L23.6 35l-5.76 8.68h-4.4L18.8 35l-5.36-8.6h4.4zm1.92 6.8v3.64H5.36V33.2h14.4zm54.268 13.2c-2.533 0-4.706-.533-6.52-1.6-1.813-1.093-3.2-2.667-4.16-4.72s-1.44-4.587-1.44-7.6c0-3.2.574-5.907 1.72-8.12 1.174-2.213 2.787-3.893 4.84-5.04 2.08-1.147 4.467-1.72 7.16-1.72 1.414 0 2.76.147 4.04.44 1.307.293 2.414.747 3.32 1.36l-1.92 3.92a7.21 7.21 0 00-2.52-1.04c-.88-.213-1.813-.32-2.8-.32-2.64 0-4.733.813-6.28 2.44-1.546 1.627-2.32 4.027-2.32 7.2 0 .507.014 1.107.04 1.8.027.667.12 1.347.28 2.04l-1.6-1.76c.48-1.093 1.134-2 1.96-2.72a8.487 8.487 0 013-1.68c1.174-.373 2.44-.56 3.8-.56 1.84 0 3.48.36 4.92 1.08 1.44.693 2.587 1.693 3.44 3 .854 1.28 1.28 2.787 1.28 4.52 0 1.84-.453 3.44-1.36 4.8-.906 1.36-2.133 2.413-3.68 3.16-1.546.747-3.28 1.12-5.2 1.12zm-.28-4.08c1.067 0 2.014-.187 2.84-.56.827-.4 1.467-.96 1.92-1.68.48-.72.72-1.56.72-2.52 0-1.467-.506-2.627-1.52-3.48-1.013-.853-2.373-1.28-4.08-1.28-1.12 0-2.106.213-2.96.64-.826.4-1.493.96-2 1.68-.48.72-.72 1.547-.72 2.48 0 .853.227 1.64.68 2.36.454.72 1.107 1.293 1.96 1.72.88.427 1.934.64 3.16.64zm59.483-15.92l5.76 8.6-5.76 8.68h-4.4l5.36-8.68-5.36-8.6h4.4zm1.92 6.8v3.64h-14.4V33.2h14.4z"
        fill="#010101"
      />
      <path
        d="M14.84 24.4L20.6 33l-5.76 8.68h-4.4L15.8 33l-5.36-8.6h4.4zm1.92 6.8v3.64H2.36V31.2h14.4zm54.268 13.2c-2.533 0-4.706-.533-6.52-1.6-1.813-1.093-3.2-2.667-4.16-4.72s-1.44-4.587-1.44-7.6c0-3.2.574-5.907 1.72-8.12 1.174-2.213 2.787-3.893 4.84-5.04 2.08-1.147 4.467-1.72 7.16-1.72 1.414 0 2.76.147 4.04.44 1.307.293 2.414.747 3.32 1.36l-1.92 3.92a7.21 7.21 0 00-2.52-1.04c-.88-.213-1.813-.32-2.8-.32-2.64 0-4.733.813-6.28 2.44-1.546 1.627-2.32 4.027-2.32 7.2 0 .507.014 1.107.04 1.8.027.667.12 1.347.28 2.04l-1.6-1.76c.48-1.093 1.134-2 1.96-2.72a8.487 8.487 0 013-1.68c1.174-.373 2.44-.56 3.8-.56 1.84 0 3.48.36 4.92 1.08 1.44.693 2.587 1.693 3.44 3 .854 1.28 1.28 2.787 1.28 4.52 0 1.84-.453 3.44-1.36 4.8-.906 1.36-2.133 2.413-3.68 3.16-1.546.747-3.28 1.12-5.2 1.12zm-.28-4.08c1.067 0 2.014-.187 2.84-.56.827-.4 1.467-.96 1.92-1.68.48-.72.72-1.56.72-2.52 0-1.467-.506-2.627-1.52-3.48-1.013-.853-2.373-1.28-4.08-1.28-1.12 0-2.106.213-2.96.64-.826.4-1.493.96-2 1.68-.48.72-.72 1.547-.72 2.48 0 .853.227 1.64.68 2.36.454.72 1.107 1.293 1.96 1.72.88.427 1.934.64 3.16.64zm59.483-15.92l5.76 8.6-5.76 8.68h-4.4l5.36-8.68-5.36-8.6h4.4zm1.92 6.8v3.64h-14.4V31.2h14.4z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default SixYellow
