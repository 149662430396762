import * as React from "react"

function RotateIconFlip(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 980.01 853.5"
      {...props}
    >
      <path d="M384.36 1.53c-60.7 6.13-123.12 26.61-174.62 57.44-72.38 43.08-131.73 107.6-167.73 182.85-52.08 108.37-55.91 235.12-10.53 346.36 61.08 149.91 205.25 254.07 365.89 264.6 18.96 1.34 19.34 1.15 24.32-3.64l4.98-4.98v-81.18-81.18l-4.4-3.83c-3.26-3.06-8.62-4.6-22.79-6.32-54.76-6.51-103.4-29.48-143.03-67.97-49.98-48.63-75.25-107.79-75.44-177.1 0-65.48 23.17-122.73 68.74-170.21 29.67-31.02 62.03-51.31 102.24-64.14 28.34-9.19 44.81-11.49 76.59-11.49 67.97.38 126.36 25.08 174.23 73.52 26.24 26.62 46.72 59.93 58.21 94.78 5.55 17.04 10.72 39.44 9.38 41.55-.38.96-28.72 1.53-62.99 1.53-51.69 0-62.03.38-62.03 2.68 0 1.53 46.53 48.83 103.4 105.12 74.28 73.71 104.92 102.81 108.94 103.77 3.25.58 8.23 0 11.87-1.53 7.47-3.07 210.42-203.15 210.42-207.36 0-2.3-10.34-2.68-63.18-2.68h-63.18l-.77-4.4c-.57-2.3-1.91-12.44-3.06-22.4-1.35-9.96-5.17-29.49-8.62-43.08C796.97 146.66 644.37 16.66 464.01.96c-17.81-1.54-61.27-1.15-79.65.57z" />
    </svg>
  )
}

export default RotateIconFlip
