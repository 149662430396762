import * as React from "react"

function OneYellow(props) {
  return (
    <svg
      width={228}
      height={51}
      viewBox="0 0 228 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.24 46V20l2.28 2.36h-8.2V18h11.12v28h-5.2zm56.998-19.6l5.76 8.6-5.76 8.68h-4.4l5.36-8.68-5.36-8.6h4.4zm1.92 6.8v3.64h-14.4V33.2h14.4z"
        fill="#010101"
      />
      <path
        d="M6.24 44V18l2.28 2.36H.32V16h11.12v28h-5.2zm56.998-19.6l5.76 8.6-5.76 8.68h-4.4l5.36-8.68-5.36-8.6h4.4zm1.92 6.8v3.64h-14.4V31.2h14.4z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default OneYellow
