
import { InformationCircleIcon } from "@heroicons/react/solid";
import React from "react";
import { useState } from "react";
import { RegionDropdownVerification } from "./RegionDropdownVerification";


export const RegionSidebarVerification = ({ 
    productDetail, 
    setProductDetail, 
    imageVerdicts, 
    setImageVerdicts, 
    selectedRegion, 
    setSelectedRegion, 
    regions, 
    handleRegionDataChange, 
    removeRegion, 
    createRegion, 
    setCreateRegion, 
    testVerificationData, 
    setTestVerificationData, 
    selectedImage, 
    questionIndex, 
    imageIndex, 
    setImageIndex,
    totalImages 
}) => {

  const likertScale = [1,2,3,4]
  const [confidence, setConfidence] = useState(0)
  const [secondOpinion, setSecondOpinion] = useState(false)

  const [testMode, setTestMode] = useState(window.location.href.includes('start-test') ? true : false)
  
  const [badImgCheck, setBadImg] = useState(false)
    const toggleBadImg = () => setBadImg(!badImgCheck)


  const reviewRequest = (verdict, x) => {
    console.log('Reviewing Request', verdict)
    console.log('test', window.location.href.includes('start-test'))
  }

  const saveAnswers = (verdict , x) => {

    console.log("save")
  }

  const handleChange = (input) => {
    let data = imageVerdicts
    data[imageIndex] = input
    setImageVerdicts([...data])
  }

  const getButtonColors = (input, yes) => {

    let grayButton = "bg-gray-100 text-gray-300 px-6 py-1 border-2 border-gray-400 rounded-lg cursor-pointer"
    let otherButton = input
    let item = imageVerdicts[imageIndex]
    if(imageVerdicts[imageIndex]){
      if(item === 0){
        return otherButton
      }
      if(item === 1) {
        if(yes) return otherButton
        else if(!yes) return grayButton
      }
      if(item === 2) {
        if(!yes) return otherButton
        else if(yes) return grayButton
      }
    }
    else return otherButton
  }

  return(<>
    <div className="bg-white rounded-xl p-5 pt-2 border-2 border-vipGreen ml-4 ">
      {/* <div className="border-b-[1px] mb-4 font-semibold border-vipGreen">
        {selectedRegion > -1 ? "Edit Region" : "Select a region to start"}
      </div> */}
      <div className="flex w-full py-1 text-gray-500 select-none">
        <div className={`w-fit ${imageIndex == 0 ? "text-gray-300 font-light" : "cursor-pointer"} `} onClick={()=>{
          if(imageIndex > 0) setImageIndex(imageIndex - 1)
        }}>
          Previous image
        </div> 
        <div className={`w-fit ml-auto text-right ${imageIndex < (totalImages-1) ? "cursor-pointer" : "text-gray-300 font-light"}`}onClick={()=>{
          if(imageIndex < totalImages-1) setImageIndex(imageIndex + 1)
        }}>
          Next image
        </div>
      </div>
      <div className="w-full">
        <div className="font-semibold text-lg">
          Region and Picture Information
        </div> 
        {regions.map((item, index) => (
          <div className="py-1">
            <RegionDropdownVerification
              selectedRegion={selectedRegion}
              setSelectedRegion={setSelectedRegion}
              index={index}
              removeRegion={removeRegion}
              productDetail={productDetail}
              setProductDetail={setProductDetail}
              selectedImage={selectedImage}
              questionIndex={questionIndex}
              imageIndex={imageIndex}
            />
          </div>
        ))}
      </div>
      <div
        className={`bg-white w-fit h-fit text-black cursor-pointer mt-4 py-1 hover:underline ${createRegion ? "border-2 rounded px-2" : ""}`}
        onClick={()=>{
          setCreateRegion(!createRegion)
          setSelectedRegion(-1)
          }}>
        {!createRegion ? "Add Region" : "Draw Region"}
      </div>
    
      <div className="pt-4 font-medium">
        Does this image show you the product may be counterfeit?
      </div>
      <div className="mt-8 flex" >
        <div className={`${getButtonColors("button-cancel", true)}  `} onClick ={() => {
          // if(!testMode) reviewRequest(true,regions[selectedRegion])
          // else saveAnswers(true,regions[selectedRegion])
          handleChange(1)
          if(imageIndex < productDetail.files.length-1)setImageIndex(imageIndex + 1)
        }}>
          Yes
        </div>
        <div className={`${getButtonColors("button-confirm", false)} ml-auto `} onClick = {() => {
          // if(!testMode) reviewRequest(false,regions[selectedRegion])
          // else saveAnswers(false,regions[selectedRegion])
          handleChange(2)
          if(imageIndex < productDetail.files.length-1)setImageIndex(imageIndex + 1)
        }}>
          No
        </div>
      </div>
      <div className="flex pt-8">
        <input 
          type="checkbox"
          className="mr-3"
          checked={badImgCheck}
          onChange={toggleBadImg}
        />
        <div>
          Report inappropriate image.
        </div>
      </div>
      <div className="flex pt-4 text-sm text-gray-500">
          <InformationCircleIcon className="w-[25px] mr-2 text-gray-300"/>
        <div>
          Contact <a className="hover:underline text-blue-500" href="mailto:support@vipficated.com">support@vipficated.com</a> if you are having any issues. 
        </div>
      </div>

        {/*<div className="border-b-[1px] pb-2">
          --- room for more fields / other things
        </div>*/}

        {/* <div className="mt-8 flex" >
          <div className="button-confirm pr-4" onClick ={() => {
            if(!testMode) reviewRequest(true,regions[selectedRegion])
            else saveAnswers(true,regions[selectedRegion])
          }}>
            Authentic
          </div>
          <div className="button-confirm ml-4" onClick = {() => {
            if(!testMode) reviewRequest(false,regions[selectedRegion])
            else saveAnswers(false,regions[selectedRegion])
          }}>
            Fake
          </div>
        </div> */}
    </div>
 </> )
}
