import { CheckIcon, ChevronRightIcon, TrashIcon } from "@heroicons/react/solid";
import React, { useEffect } from "react";
import { useState } from "react";
import ReactTextareaAutosize from "react-textarea-autosize"; 
import { ComboBoxRegion } from "../basic/ComboBoxRegion";

export const RegionDropdown = ({selectedRegion, setSelectedRegion, removeRegion, reviewRequest, index, selectedImage, testVerificationData, setTestVerificationData, questionIndex}) => {
  
  const [showCircle, setShowCircle] = useState(false)
  const [showCheck, setShowCheck] = useState(false)
  const [timer, setTimer] = useState(null)
 
  useEffect(()=>{
    if(showCheck){
      const newTimer = setTimeout(() => { 
        setShowCheck(false) 
        setShowCircle(false) 
      }, 2500) 
      setTimer(newTimer) 
    }
  }, [showCheck]) 

  const handleChangeTimeout = (input, handle) => { 
    const newValue = input 
    let data = testVerificationData
    clearTimeout(timer) 
    data[questionIndex].files.find(item => item.filename === selectedImage.filename).regionData[selectedRegion][handle] = newValue
    setTestVerificationData([...data])  
    if(newValue.length > 0){
      setShowCircle(true)
      setShowCheck(false) 
      const newTimer = setTimeout(() => { 
        // console.log('@TO-DO api call comes here') 
        setTestVerificationData([...data])
        console.log(data[questionIndex].files.find(item => item.filename === selectedImage.filename).regionData[selectedRegion])
        setShowCheck(true)  
      }, 1500) 
      setTimer(newTimer) 
    }
    else if (!newValue.length) {
      setShowCheck(false)
      setShowCircle(false)
      return 
    } 
  } 

  return(
    <div className="border-[1px] rounded border-gray-200 p-2 ">
      <div className="flex">
        <div className="flex-1">
          {selectedRegion == index ?
            <div className="w-full">
              <div>
                Change region name:
              </div>
              <input 
                value={testVerificationData[questionIndex].files.find(item => item.filename === selectedImage.filename).regionData[selectedRegion].label}
                placeholder={`Region ${index+1}`}
                onChange={(e)=>handleChangeTimeout(e.target.value, "label")}
                type="text"
                className="mt-1 w-full pl-2 py-1 focus:ring-1 ring-gray-200 focus:outline-none border-[1px] border-gray-200 rounded placeholder:text-gray-400 placeholder:text-light placeholder:text-sm"
              />
            </div>
            :
            <div
              className="cursor-pointer" 
              onClick={()=>{
                if(selectedRegion === index) setSelectedRegion(-1)
                else setSelectedRegion(index) 
              }}
            > 
              {testVerificationData[questionIndex].files.find(item => item.filename === selectedImage.filename).regionData[index].label || `Region ${index+1}`}
            </div>
          }
        </div>
        <div className={`w-fit m-auto ${selectedRegion === index ? "rotate-90" : "rotate-0"} animate cursor-pointer absolute right-7 pt-[2px]`}
          onClick={()=>{
            if(selectedRegion === index) setSelectedRegion(-1)
            else setSelectedRegion(index) 
          }}
        >          
          <ChevronRightIcon width={20}/>
        </div>
        <div className="absolute right-12 pt-[3px] mr-1">
          {showCircle &&
            <div className="w-fit pl-2">
              {showCheck ?
                <div className="w-[16px] h-[16px] rounded-full bg-vipGreen">
                  <CheckIcon className="w-[16px] text-white" />
                </div> : 
                <div className="w-[16px] h-[16px] rounded-full bg-orange-500 animate-pulse"/> 
              }
            </div>
          }
        </div>
      </div>
      {selectedRegion == index&&
        <div> 
          <div className="">
            <div className="pt-2">
              Select Category:
            </div>
            <div className="pb-2">
              <ComboBoxRegion 
                onChange={(e)=>console.log('combo box', e.target.value)} 
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}  
                index={index}
                removeRegion={removeRegion} 
                testVerificationData={testVerificationData}
                setTestVerificationData={setTestVerificationData}
                selectedImage={selectedImage}
                questionIndex={questionIndex}
                handleChangeTimeout={handleChangeTimeout}
              />
            </div>
            <div className="mb-1">
              Comment:
            </div>
            <div className="flex animate-all duration-200"> 
              <div className="flex-1">
                {/* {selectedRegion > -1 && console.log(regions[selectedRegion].data)} */}
                <ReactTextareaAutosize
                  className="mt-1 w-full pl-2 py-1 focus:ring-1 ring-gray-200 focus:outline-none border-[1px] border-gray-200 rounded placeholder:text-gray-400 placeholder:text-light placeholder:text-sm"
                  placeholder="Comments about selected area.."
                  type="textarea"
                  minRows={2}
                  maxRows={4}
                  value={testVerificationData[questionIndex].files.find(item => item.filename === selectedImage.filename).regionData[selectedRegion].comment || ""}
                  // onChange={e=>handleRegionDataChange(e)}
                  onChange={e=>handleChangeTimeout(e.target.value, "comment")}
                />
              </div>
              {/* {showCircle &&
                <div className="w-fit pl-2">
                  {showCheck ?
                    <div className="w-[16px] h-[16px] rounded-full bg-vipGreen">
                      <CheckIcon className="w-[16px] text-white" />
                    </div> : 
                    <div className="w-[16px] h-[16px] rounded-full bg-orange-500 animate-pulse"/> 
                  }
                </div>
              } */}
            </div>
            
            <div className="mt-0 flex" >
              <div
                onClick={e=>removeRegion(selectedRegion)}
                className="text-sm text-gray-400 hover:text-red-400 flex cursor-pointer animate border-2 border-white hover:border-red-400 m-2 mb-0 px-2 py-1 rounded-lg center-items mr-auto">
                <TrashIcon width={"20px"}/>
                <div className="pt-0 ml-1">
                  Remove Region
                </div>
              </div>
              {/* <div className="button text-vipGreen flex ml-auto cursor-pointer animate border-2 border-white hover:border-vipGreen m-2 px-2 py-1 rounded-lg" onClick={()=> console.log(regions[selectedRegion].data.comment)}>
                <SaveIcon width={"25px"}/>
                <div className="pt-0 ml-1">
                  Save Changes
                </div>
              </div> */}
            </div>
          </div>
        </div>
      }
    </div>
  )
}