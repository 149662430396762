import axios from "axios";

// Requests assigned to the user
const GetRequestQuery = `
query GetAllAssignedRequests ($status: String)  {
    GetAllAssignedRequests (status: $status) {
        category
        brand
        name
        description
        status
        dateOfLastUpdate
        uuid
        createdDate
        files
        createdDate
    }
}`;

const GetRequests = async (data, token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: GetRequestQuery,
      variables: {
        ...data,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data.GetAllAssignedRequests;
};

export { GetRequests };
