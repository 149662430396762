import * as React from "react"

function FourYellow(props) {
  return (
    <svg
      width={154}
      height={51}
      viewBox="0 0 154 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.84 26.4L23.6 35l-5.76 8.68h-4.4L18.8 35l-5.36-8.6h4.4zm1.92 6.8v3.64H5.36V33.2h14.4zm41.748 6.44v-3.6L75.308 18h5.56l-13.6 18.04-2.6-.8h22.2v4.4h-25.36zM76.868 46v-6.36l.16-4.4V29.6h4.88V46h-5.04zm58.433-19.6l5.76 8.6-5.76 8.68h-4.4l5.36-8.68-5.36-8.6h4.4zm1.92 6.8v3.64h-14.4V33.2h14.4z"
        fill="#010101"
      />
      <path
        d="M14.84 24.4L20.6 33l-5.76 8.68h-4.4L15.8 33l-5.36-8.6h4.4zm1.92 6.8v3.64H2.36V31.2h14.4zm41.748 6.44v-3.6L72.308 16h5.56l-13.6 18.04-2.6-.8h22.2v4.4h-25.36zM73.868 44v-6.36l.16-4.4V27.6h4.88V44h-5.04zm58.433-19.6l5.76 8.6-5.76 8.68h-4.4l5.36-8.68-5.36-8.6h4.4zm1.92 6.8v3.64h-14.4V31.2h14.4z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default FourYellow
