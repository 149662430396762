import { CalendarOverview } from "assets/components/calendar/calendar-overview";
import { ContentCard } from "assets/components/content-card/ContentCard";
import { GridCardHome } from "assets/components/grid-cards/GridCardHome";
import { PendingVerificationCard } from "assets/components/grid-cards/PendingVerificationCard";
import React, { useEffect, useState } from "react";
import { GetStatistics } from "api/GetStatistics.js";
import { DownloadCsv } from "api/DownloadCsv.js";
import { CSVLink, CSVDownload } from "react-csv";

import {
  XYPlot,
  VerticalGridLines,
  HorizontalGridLines,
  XAxis,
  YAxis,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  LabelSeries,
  RadialChart,
  ArcSeries,
  DiscreteColorLegend
} from "react-vis";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

//Pending verifications for the verifier aka verifications assifned to me
export const ViewStats = (props) => {
  const [startDate, setStartDate] = useState(new Date().setDate(new Date().getDate() - 7));
  const [endDate, setEndDate] = useState(new Date());
  const [csvData, setCsvData] = useState();
  const [chartData, setChartData] = useState()
  const [stackChartData, setStackChartData] = useState()
  const [categoriesForStack, setCategoriesForStack] = useState()

  const myData = [
    { angle: 1, color: "#216C38",  label: 'In Progress' },
    { angle: 4, color: "#3FBF61",  label: 'Completed' },
    { angle: 1, color: "#f56e67",  label: 'Pending Assignment' }
  ]

  const verdictData = [
    { angle: 1, color: "#216C38",  label: 'Authentic' },
    { angle: 5, color: "#f56e67",  label: 'Fake' }
  ]

  useEffect(() => {
    GetStatistics({startDate: new Date(startDate).toLocaleDateString('ko-KR'), endDate: new Date().toLocaleDateString('ko-KR')})
      .then((out) => {
        console.log('Out', out)
        let brandChartData = Object.keys(out.brands).map(item => {
          return {x: item, y: out.brands[item]}
        })
        let catChartData = Object.keys(out.categories).map(item => {
          return {x: item, y: out.categories[item]}
        })
        let statusChartData = Object.keys(out.status).map(item => {
          return {x: item, y: out.status[item]}
        })
        //setChartData([brandChartData, catChartData, statusChartData ])

        let stackChartData = out.sortedByCategory.map(x => {
          return Object.keys(x).map(item => {
            return {x: item, y: x[item]}
          })
        })
        //dummyData
        setChartData([brandChartData, catChartData, statusChartData ])
        //stackDummyData
        setStackChartData([...stackChartData])
        //stackDataCategories
        setCategoriesForStack(out.categories)
      })
  }, [startDate, endDate])

  useEffect(() => {
    DownloadCsv({startDate: new Date(startDate).toLocaleDateString('ko-KR'), endDate: new Date().toLocaleDateString('ko-KR')})
    .then(out => {
      console.log(out)
      setCsvData([...out])
      console.log('>', csvData)
    })
  }, [startDate, endDate])

  const dummyData = [
    [{x: 'Stone Island', y: 25}, {x: 'Adidas', y:5}, {x: 'Rains', y:50}, {x: 'Vans', y:100}, {x: 'Supreme', y:80}],
    [{x: 'Bags', y: 25}, {x: 'Shoes', y:5}, {x: 'Trousers', y:50}, {x: 'Jackets', y:80}, {x: 'T-shirt', y:80}],
    [{x: 'complete', y: 150}, {x: 'inProgress', y: 25}]
  ]

  const stackDummyData = [
    [{x: 'Stone Island', y: 10},{x: 'Rains', y: 10},{x: 'Vans', y: 5},{x: 'Supreme', y: 15},{x: 'Adidas', y: 20}],
    [{x: 'Stone Island', y: 10},{x: 'Rains', y: 10},{x: 'Vans', y: 5},{x: 'Supreme', y: 15},{x: 'Adidas', y: 20}],
    [{x: 'Adidas', y: 10},{x: 'Rains', y: 5},{x: 'Supreme', y: 15},{x: 'Adidas', y: 20}]
  ]

  const stackDataCategories = [{title: 'bags'},{title: 'hats'}, {title: 'shoes'}]

  const stackDummyDataForVerdict = [
    [{x: 'Stone Island', y: 10},{x: 'Rains', y: 10},{x: 'Vans', y: 5},{x: 'Supreme', y: 15},{x: 'Adidas', y: 20}],
    [{x: 'Stone Island', y: 2},{x: 'Rains', y: 10},{x: 'Vans', y: 5},{x: 'Supreme', y: 15},{x: 'Adidas', y: 0}],
  ]

  const colorsForVerdict = ['#216C38','#f56e67']
  const stackDummyDataForVerdictCategories = [{title: 'Authentic', color: '#216C38'},{title: 'Fake', color: '#f56e67'}]

  // const verdictData = [{x: 'true', y: 150}, {x: 'false', y: 25}]

  const BarSeries = VerticalBarSeries;
  return(
    <div className=" ">
      <div className="text-xl font-semibold py-4 px-6 select-none ">
        Your Statistics
      </div>
      <div className="text-xl font-semibold py-4 px-6 select-none ">
        Total Verifications: 85
        <div className="grid grid-cols-4 mt-4">
          <DatePicker selected={startDate} onChange={(date:Date) => setStartDate(date)} />
          <DatePicker selected={endDate} onChange={(date:Date) => setEndDate(date)} />
        </div>
      </div>
      {  csvData && <CSVLink data={csvData}><div className="button-confirm mt-4 ml-4">Download CSV Data </div></CSVLink>}
     <div className="w-full">
     <div className="text-xl font-semibold py-4 px-6 select-none mt-4 ">
        Statistics by Status
    </div>
    <div className="grid grid-cols-3">
      <RadialChart
        getLabel={d => d.label}
        data={myData}
        labelsStyle={{ fontSize: 16, fill: "#222" }}
        width={400}
        height={400}
        innerRadius={100}
        radius={180}
        colorDomain={[0, 1]}
         colorType="category"
         colorRange={['#216C38', '#3FBF61', '#f56e67']}

       />
     <DiscreteColorLegend
                items={[
                  { color: "#216C38",  title: 'In Progress' },
                  { color: "#3FBF61",  title: 'Completed' },
                  { color: "#f56e67",  title: 'Pending Assignment' }
                ]}
            />
    </div>
     </div>
    <div className="grid grid-cols-2 gap-4">
      <div>
        <div className="text-xl font-semibold py-4 px-6 select-none ">
           Statistics by Top 5 Brands
       </div>
          <XYPlot
            animation
            xType="ordinal"
            width={400}
            height={400}
            xDistance={20}
            colorDomain={[0, 1]}
            colorType="category"
            colorRange={['#216C38']}
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <BarSeries data={dummyData[0]} />
          </XYPlot>
      </div>
      <div>
        <div className="text-xl font-semibold py-4 px-6 select-none ">
           Statistics by Top 5 Categories
       </div>
          <XYPlot
            animation
            xType="ordinal"
            width={400}
            height={400}
            xDistance={10}
            colorDomain={[0, 1]}
            colorType="category"
            colorRange={['#216C38']}
          >
            <VerticalGridLines />
            <HorizontalGridLines />
            <XAxis />
            <YAxis />
            <BarSeries data={dummyData[1]} />
          </XYPlot>
        </div>
      </div>
      <div>
      <div className="text-xl font-semibold py-4 px-6 select-none ">
         Top 5 Category breakdown by brand
      </div>
      <div className="grid grid-cols-8">
        <XYPlot
          className="clustered-stacked-bar-chart-example"
          xType="ordinal"
          stackBy="y"
          width={400}
          height={400}
        >
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis />
          <YAxis />
          {/* hats */}
          {stackDummyData.map(item => <BarSeries data={item}/>)}
        </XYPlot>
        <DiscreteColorLegend

          items={stackDataCategories}
        />
      </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
      <div>
        <div className="text-xl font-semibold py-4 px-6 select-none mt-4 ">
           Statistics by Verdict
       </div>
       <div className="grid grid-cols-2">
         <RadialChart
           getLabel={d => d.label}
           data={verdictData}
           labelsStyle={{ fontSize: 16, fill: "#222" }}
           width={400}
           height={400}
           innerRadius={100}
           radius={180}
           colorDomain={[0, 1]}
            colorType="category"
            colorRange={['#216C38', '#f56e67']}

          />
        <DiscreteColorLegend
        items={[
           {
             title: 'Authentic',
             color: '#216C38'
           },
           {
             title: 'Fake',
             color: '#f56e67'
           }
         ]}
                   //items={verdictData.map(d => d.label)}
               />
       </div>
      </div>
      <div>
      <div className="text-xl font-semibold py-4 px-6 select-none ">
         Top 5 Brand breakdown by verdict
      </div>
      <div className="grid grid-cols-8">
        <XYPlot
          className="clustered-stacked-bar-chart-example"
          xType="ordinal"
          stackBy="y"
          width={400}
          height={400}
        >
          <VerticalGridLines />
          <HorizontalGridLines />
          <XAxis />
          <YAxis />
          {/* hats */}
          {stackDummyDataForVerdict.map((item,itt) => <BarSeries  color={colorsForVerdict[itt]} data={item}/>)}
        </XYPlot>
        <DiscreteColorLegend
          items={stackDummyDataForVerdictCategories}
        />
      </div>
      </div>
      </div>
    </div>
  )
}
