import axios from "axios";

const DownloadTestSummaryMutation = `
mutation DownloadTestSummary($email: String)  {
    DownloadTestSummary (email: $email) {
        category
        name
        brand
        model
        description 
        verdict
    }
}`;

const DownloadTestSummary = async (data, token) => {
    const res = await axios({
        url: process.env.REACT_APP_API,
        method: "post",
        data: {
            query: DownloadTestSummaryMutation,
            variables: {
                ...data,
            },
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });

    return res.data.data.DownloadTestSummary;
};

export { DownloadTestSummary };
