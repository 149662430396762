import axios from "axios";

const UpdateUserAccountMutation = `
  mutation UpdateUserAccount ($user: UserInputType) {
      UpdateUserAccount (user: $user){
          email
      }
}`;

const UpdateUserAccount = async (data, token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: UpdateUserAccountMutation,
      variables: {
        ...data,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data.UpdateUserAccount;
};

export { UpdateUserAccount };
