import React from "react";

export const VerifierCard = ({ verifierProfile }) => {

  return(
    <div className="flex-1 m-12 ">
      <div className="bg-vipGreen rounded-xl min-h-[250px] p-12 m-5 text-white"> 
        <div className="text-xl pb-12">
          Your verifier
        </div>
        <div>
          <div className="w-[250px] h-[320px] bg-white rounded-lg m-auto center-items font-bold text-black">
            Verifier Image
          </div>  
          <div className="w-fit m-auto font-semibold py-8">
            {verifierProfile.name}
          </div>
        </div>

        <div className="flex">
          <div className="verifier-title">
            Specialization
          </div>
          <div className="ml-auto flex ">
            {/* {JSON.stringify(verifierProfile.specialization)} */}
            {verifierProfile.specialization.map((item, index) => (
              <div>
                 <span>{item}</span>
                 {index < verifierProfile.specialization.length-1 &&
                  <span className="mr-1">{index < verifierProfile.specialization.length-1? ", " : ""}</span> 
                 }
              </div>
            ))}
          </div>
        </div>
        <div className="flex pb-4">
          <div className="verifier-title">
            Work experience
          </div>
          <div className="ml-auto mr-">
            {verifierProfile.experience} years
          </div>
        </div>

        <div className="text-xl font-semibold mb-4 border-b-[1px]">
          Information about your verification
        </div>
        <div className="flex">
          <div className="verifier-title">
            Started verification
          </div>
          <div className="ml-auto mr-">
            {verifierProfile.verificationStarted}
          </div>
        </div>
        <div className="flex">
          <div className="verifier-title">
            Feedback will be available
          </div>
          <div className="ml-auto mr-">
            {verifierProfile.feedbackDate}
          </div>
        </div>
      </div> 
    </div>
    
  )
}