import * as React from "react"

function EyeIconYellow(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38.664 26.944l-7.778 7.778-5.444-5.444 2.333-2.334 3.111 3.112 5.445-5.445 2.333 2.333zM20.775 14.5a4.667 4.667 0 110 9.334 4.667 4.667 0 010-9.334zm0-7c7.778 0 14.42 4.838 17.111 11.667a17.712 17.712 0 01-1.43 2.877A8.977 8.977 0 0033.5 20.91l.995-1.742a15.277 15.277 0 00-27.44 0 15.278 15.278 0 0013.72 8.555l1.882-.109a9.071 9.071 0 00-.326 2.443v.715l-1.556.062c-7.778 0-14.42-4.837-17.11-11.666C6.354 12.337 12.996 7.5 20.774 7.5z"
        fill="#010101"
      />
      <path
        d="M35.664 23.944l-7.778 7.778-5.444-5.444 2.333-2.334 3.111 3.112 5.445-5.445 2.333 2.333zM17.775 11.5a4.667 4.667 0 110 9.334 4.667 4.667 0 010-9.334zm0-7c7.778 0 14.42 4.838 17.111 11.667a17.712 17.712 0 01-1.43 2.877A8.977 8.977 0 0030.5 17.91l.995-1.742a15.277 15.277 0 00-27.44 0 15.277 15.277 0 0013.72 8.555l1.882-.109a9.071 9.071 0 00-.326 2.443v.715l-1.556.062c-7.778 0-14.42-4.837-17.11-11.666C3.354 9.337 9.996 4.5 17.774 4.5z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default EyeIconYellow
