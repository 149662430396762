import CheckIconCustom from "assets/files/images/check-icon";
import React from "react";

export const CheckmarkWithText = ({text, header, icon}) => {
  
  return(
    <div className="pt-5 min-h-[100px] max-w-[350px] overflow-hidden">
      <div className="ml-3 mb-1">
        {/* <div className="bg-vipYellow rounded-full h-[40px] w-[40px] relative">
          <CheckIconCustom className="w-[35px] absolute -right-1 -top-1" />
        </div> */}
        {icon}
      </div>
      <div className="grid place-content-center ">
        <div className="ml-4 font-semibold text-left">
          {header}
        </div>
        <div className="ml-4 text-left">
          {text}
        </div>
      </div>
    </div>
  )
}