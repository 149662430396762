import * as React from "react"

function CertificateIconYellow(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.9 34.9h-1.1a1.1 1.1 0 001.76.88l-.66-.88zm4.4-3.3l.66-.88a1.1 1.1 0 00-1.32 0l.66.88zm4.4 3.3l-.66.88a1.1 1.1 0 001.76-.88h-1.1zm-4.4-7.7a5.5 5.5 0 01-5.5-5.5h-2.2a7.7 7.7 0 007.7 7.7v-2.2zm5.5-5.5a5.5 5.5 0 01-5.5 5.5v2.2a7.7 7.7 0 007.7-7.7h-2.2zm-5.5-5.5a5.5 5.5 0 015.5 5.5H36a7.7 7.7 0 00-7.7-7.7v2.2zm0-2.2a7.7 7.7 0 00-7.7 7.7h2.2a5.5 5.5 0 015.5-5.5V14zm-5.5 12.1v8.8H25v-8.8h-2.2zm1.76 9.68l4.4-3.3-1.32-1.76-4.4 3.3 1.32 1.76zm3.08-3.3l4.4 3.3 1.32-1.76-4.4-3.3-1.32 1.76zm6.16 2.42v-8.8h-2.2v8.8h2.2zM36 14V6.3h-2.2V14H36zM32.7 3H6.3v2.2h26.4V3zM3 6.3v26.4h2.2V6.3H3zM6.3 36h14.3v-2.2H6.3V36zM3 32.7A3.3 3.3 0 006.3 36v-2.2a1.1 1.1 0 01-1.1-1.1H3zM6.3 3A3.3 3.3 0 003 6.3h2.2a1.1 1.1 0 011.1-1.1V3zM36 6.3A3.3 3.3 0 0032.7 3v2.2a1.1 1.1 0 011.1 1.1H36zM9.6 14h11v-2.2h-11V14zm0 6.6h6.6v-2.2H9.6v2.2z"
        fill="#010101"
      />
      <path
        d="M20.9 31.9h-1.1a1.1 1.1 0 001.76.88l-.66-.88zm4.4-3.3l.66-.88a1.1 1.1 0 00-1.32 0l.66.88zm4.4 3.3l-.66.88a1.1 1.1 0 001.76-.88h-1.1zm-4.4-7.7a5.5 5.5 0 01-5.5-5.5h-2.2a7.7 7.7 0 007.7 7.7v-2.2zm5.5-5.5a5.5 5.5 0 01-5.5 5.5v2.2a7.7 7.7 0 007.7-7.7h-2.2zm-5.5-5.5a5.5 5.5 0 015.5 5.5H33a7.7 7.7 0 00-7.7-7.7v2.2zm0-2.2a7.7 7.7 0 00-7.7 7.7h2.2a5.5 5.5 0 015.5-5.5V11zm-5.5 12.1v8.8H22v-8.8h-2.2zm1.76 9.68l4.4-3.3-1.32-1.76-4.4 3.3 1.32 1.76zm3.08-3.3l4.4 3.3 1.32-1.76-4.4-3.3-1.32 1.76zm6.16 2.42v-8.8h-2.2v8.8h2.2zM33 11V3.3h-2.2V11H33zM29.7 0H3.3v2.2h26.4V0zM0 3.3v26.4h2.2V3.3H0zM3.3 33h14.3v-2.2H3.3V33zM0 29.7A3.3 3.3 0 003.3 33v-2.2a1.1 1.1 0 01-1.1-1.1H0zM3.3 0A3.3 3.3 0 000 3.3h2.2a1.1 1.1 0 011.1-1.1V0zM33 3.3A3.3 3.3 0 0029.7 0v2.2a1.1 1.1 0 011.1 1.1H33zM6.6 11h11V8.8h-11V11zm0 6.6h6.6v-2.2H6.6v2.2z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default CertificateIconYellow
