import { CheckmarkWithText } from "assets/components/basic/CheckmarkWithText";
import CertificateIconYellow from "assets/files/images/certificate-icon";
import CheckIconCustom from "assets/files/images/check-icon";
import ClipboardIconYellow from "assets/files/images/clipboard-icon";
import CoinsIconYellow from "assets/files/images/coins-icon";
import ComputerIconYellow from "assets/files/images/computer-icon";
import DiceCircle from "assets/files/images/dice-circle";
import EasyApiImage from "assets/files/images/easy-api-image";
import EyeIconYellow from "assets/files/images/eye-icon";
import FolderCircle from "assets/files/images/folder-circle";
import QualityIconYellow from "assets/files/images/quality-icon";
import SamplesIconYellow from "assets/files/images/samples-icon";
import WorldIconYellow from "assets/files/images/world-icon";
import React from "react";

import YellowManImage from './../../img/card-images/laptop-man.png'
import TabletImage from './../../img/card-images/tablet-img.png'
import YellowStatueImage from './../../img/card-images/statue-yellow.png'
import CodeImage from './../../img/card-images/code-image.png'
import UsersIconYellow from "assets/files/images/users-icon";
import CostsIconYellow from "assets/files/images/costs-icon";
import GraphIconYellow from "assets/files/images/graph-icon";
import ReasonsImage from "assets/files/images/reasons-image"; 
import TimesThreeYellow from "assets/files/images/times-three";
import TimesElevenYellow from "assets/files/images/times-eleven";
import PercentYellow from "assets/files/images/36p";
import TimesFourtyYellow from "assets/files/images/times-fourty";
import { CallToActionModal } from "assets/components/basic/CallToActionModal";
import { useState } from "react";

export const LandingHome = () => {
  const [openCta, setOpenCta] = useState(false)

  let fraudPrevention = [
    {text: "It guarantees the authenticity of the products you sell, even before being transacted", icon: <ClipboardIconYellow />},
    {text: "So forget about spending time and money on fake products", icon: <CoinsIconYellow />},
    {text: "Forget about customers complaining about product quality", icon: <QualityIconYellow />}
  ]
  let userExperience = [
    {text: "VIP·Ficated cares about the user experience end-to-end", icon: <WorldIconYellow />},
    {text: "Certificate of authenticity for buyers and instant feedback on the uploaded photos for sellers", icon: <CertificateIconYellow />},
    {text: "A combination of trained eye verifiers and technology that keeps you informed about the process and data in real time", icon: <EyeIconYellow />}
  ]
  return(
    <div className="w-full min-h-screen text-lg mt-16"> 
      <CallToActionModal open={openCta} setOpen={setOpenCta} />
      <div className="bg-vipBgDark">
        <div className="max-w-[1280px] m-auto">
          <div className="flex w-full h-[650px] relative">
            <div className="hidden md:block flex-1 grid place-items-center font-bold text-2xl animate-wiggle absolute -top-3 origin-top z-2">
              <img
                className="w-[700px]"
                src={YellowManImage}
              />
            </div>
            <div className="flex-1 flex flex-col">
              <div className="flex-1"> 
              </div>
              <div className="md:w-[55%] ml-auto relative z-1 px-4 md:px-0">
                <div className="text-6xl font-semibold text-vipYellow drop-shadow-lg">
                  Empowering users trust with a snap of the fingers
                </div>
                <div className="pt-8">
                  Offering seamless verification to secondhand marketplaces to create a higher level of trust while buying secondhand luxury items online
                </div>
                <div className="yellow-button mt-4 relative z-3" onClick={()=>setOpenCta(!openCta)}>
                  Apply for a free trial
                </div>
              </div>
              <div className="flex-1">

              </div>
            </div>
          </div>
        </div>
      </div>
      {/* vip makes fashion meet tech~  */}
      <div className="bg-white max-w-7xl m-auto py-12 bg-no-repeat bg-right-bottom bg-50" style={{ backgroundImage: 'url(' + YellowStatueImage + ')'}}>
        <div className="relative">
          <div className="text-left font-bold text-3xl text-vipYellow max-w-5xl px-4 md:px-0">
            VIP·Ficated makes fashion meet technology to authenticate products in a convenient and compelling way
          </div> 
        </div> 
        <div className="flex">
          <div className="md:w-2/3 flex">
            <div className="flex-1 pt-8">
              <div className="flex-1 font-semibold text-xl min-h-[90px] md:min-h-[60px] max-w-[350px] ml-3">
                VIP·Ficated means fraud prevention 
              </div>
              {fraudPrevention.map((item) => (
                <CheckmarkWithText text={item.text} icon={item.icon}/>
              ))} 
            </div>
            <div className="flex-1 pt-8">
              <div className="flex-1 font-semibold text-xl min-h-[90px] md:min-h-[60px] max-w-[350px] ml-3">
                VIP·Ficated cares about the end-to-end user experience 
              </div>
              {userExperience.map((item) => (
                <CheckmarkWithText text={item.text} icon={item.icon} />
              ))} 
            </div>
          </div> 
        </div>
      </div>
      {/* easy api to start */}
      <div className="bg-vipBgDark w-screen ">
        <div className="max-w-7xl md:m-auto flex px-4 md:px-0">
          <div className="flex-1 flex flex-col py-12"> 
            <div className="text-left font-bold text-3xl text-vipYellow">
              Easy API to start
            </div>
            <div className="pt-4">
              Our business is Pay-as-you-Go. Fully adapted to yours, just like our API that aims to be implemented in days, not months. So your teams don't have to stitch together disparate systems when you expand the categories to authenticate or the countries you serve
            </div>
            <div className="flex pt-4 ">
              <div className="yellow-button min-w-[110px]"  onClick={()=>setOpenCta(!openCta)}>
                Apply for a free trial
              </div> 
            </div>
            <div className="flex">
              <div className="flex-1 pr-4">
                <div className="pt-6">
                  <ComputerIconYellow />
                </div>
                <div className="font-semibold pt-2">
                  Stack agnostic implementation
                </div>
                <div>
                  We offer client and server libraries in everything from React and PHP to .NET and iOS
                </div>
              </div>
              <div className="flex-1">
                <div className="pt-6">
                  <SamplesIconYellow />
                </div>
                <div className="font-semibold pt-2">
                  Tools for every stack
                </div>
                <div>
                  We offer client and server libraries in everything from React and PHP to .NET and iOS
                </div>
              </div>
            </div>
            <div className="flex-1"/>
          </div>
          <div className="flex-1 hidden md:block grid place-items-center font-bold text-2xl py-2">
            <img src={CodeImage} className="h-[500px]"/>
          </div>
        </div> 
      </div>
      {/* reasons for marketplace~ */}
      <div className="bg-white max-w-7xl m-auto py-12 flex px-4 md:px-0">
        <div className="flex-1">
          <div className="text-left font-bold text-3xl text-vipYellow max-w-5xl">
            Reasons for the marketplace to use VIP·Ficated:
          </div>
          <div className="flex flex-col md:flex-row pt-8 md:space-x-6 space-y-4 md:space-y-0">
            <div className="flex-1">
              <div>
                <UsersIconYellow />
              </div>
              <div className="pt-2">
                <div className="font-semibold">Do it for your users:</div>
                <div className="pt-2">Trust accelerates transactions and fairer pricing</div>
              </div>
            </div>
            <div className="flex-1">
              <div>
                <CostsIconYellow />
              </div>
              <div className="pt-2">
                <div className="font-semibold">Do it to save your costs:</div>
                <div className="pt-2">Avoid spending funds on counterfeit products: logistics, insurance, customer claims</div>
              </div>
            </div>
            <div className="flex-1">
              <div>
                <GraphIconYellow />
              </div>
              <div className="pt-2">
                <div className="font-semibold">Do it to gather brand insights:</div>
                <div className="pt-2">Counterfeits really scare clothing brands. You will get powerful data</div>
              </div>
            </div>
          </div>
          <div className="md:text-left pt-8 font-semibold text-justify ">
            And while you do it, you will witness in real time how you move from 100 to 1,000 products verified per day and then, you will realize that you don't have to be a giant marketplace to compete with eBay (<a href="https://www.voguebusiness.com/sustainability/inside-the-secretive-world-of-luxury-authentication-vestiaire-collective-the-realreal-ebay-stockx-goat" className="underline">read here the eBay authentication successful story</a>).
          </div>
        </div>
        <div className="ml-16 relative hidden md:block">
          {/* <ReasonsImage /> */}
          <div className="font-bold text-2xl text-vipYellow absolute -right-16 -top-4">
            1000
          </div>
          <div className="font-bold text-2xl text-vipYellow absolute -right-12 -bottom-4">
            100
          </div>
          <div className="bg-vipYellow w-[60px] h-full relative overflow-hidden"> 
            <div className="bg-white opacity-[60%] w-full h-[79%] origin-top animate-bar"> </div> 
            <div className="absolute -bottom-[350px] animate-arrows origin-bottom"> 
              <div className="w-[60px] h-[30px] overflow-hidden"> 
                <div className="bg-vipYellow w-[43px] m-auto mt-2 rotate-45 aspect-square"> 
                </div> 
              </div>
              <div className="w-[60px] h-[30px] overflow-hidden "> 
                <div className="bg-vipYellow w-[43px] m-auto mt-2 rotate-45 aspect-square"> 
                </div> 
              </div>
              <div className="w-[60px] h-[30px] overflow-hidden "> 
                <div className="bg-vipYellow w-[43px] m-auto mt-2 rotate-45 aspect-square"> 
                </div> 
              </div> 

              <div className="w-[60px] h-[30px] overflow-hidden mt-8"> 
                <div className="bg-vipYellow w-[43px] m-auto mt-2 rotate-45 aspect-square"> 
                </div> 
              </div>
              <div className="w-[60px] h-[30px] overflow-hidden "> 
                <div className="bg-vipYellow w-[43px] m-auto mt-2 rotate-45 aspect-square"> 
                </div> 
              </div>
              <div className="w-[60px] h-[30px] overflow-hidden "> 
                <div className="bg-vipYellow w-[43px] m-auto mt-2 rotate-45 aspect-square"> 
                </div> 
              </div>   
              <div className="w-[60px] h-[30px] overflow-hidden mt-8"> 
                <div className="bg-vipYellow w-[43px] m-auto mt-2 rotate-45 aspect-square"> 
                </div> 
              </div>

              <div className="w-[60px] h-[30px] overflow-hidden "> 
                <div className="bg-vipYellow w-[43px] m-auto mt-2 rotate-45 aspect-square"> 
                </div> 
              </div>
              <div className="w-[60px] h-[30px] overflow-hidden "> 
                <div className="bg-vipYellow w-[43px] m-auto mt-2 rotate-45 aspect-square"> 
                </div> 
              </div>   
            </div> 
          </div>
          
        </div>
      </div>
      {/*  do you want to become~ */}
      <div className="bg-vipBgDark w-screen py-12 px-4 md:px-0">
        <div className=" max-w-7xl m-auto flex">  
          <div className="flex-1">
            <div className="text-left font-bold text-3xl text-vipYellow max-w-5xl">
              Global arguments: brands, fraud, sustainability, self-made
            </div>  
            <div className="md:w-[60%]">
              <div className="py-4 font-semibold">
                Trust is the cornerstone of commerce and clothing resale commerce in particular.
                Product authentication has been a competitive advantage for large marketplaces with large capital investments (eBay, Vestiaire Collective, etc.). However, for the remaining 99% of marketplaces, 
                the Verification-as-a-Service system allows them to compete with a tailored solution.
              </div>
              <div>
              <div className="flex flex-col md:flex-row md:space-x-6 space-y-4 md:space-y-0">
                <div className="flex-1">
                  <div className="">
                    <TimesThreeYellow />
                    {/* <CheckmarkWithText header="x3 chance to buy x4 average ticket" text="when the product is verified by an independent professional"/> */}
                  </div>
                  <div className="">
                    chance to buy
                    and average ticket
                    when the product is verified 
                    by an independent professional
                  </div>
                </div> 
                <div className="flex-1"> 
                  <div className="">
                    <TimesElevenYellow />
                  </div>
                  <div className="">
                    fierce competence. The resale clothing market is growing 
                    11 times faster than that of new clothing (BCG)
                  </div>
                </div> 
              </div>
              <div className="flex flex-col md:flex-row md:space-x-6 space-y-4 md:space-y-0">
                <div className="flex-1">
                  <div className="">
                    <PercentYellow />
                    {/* <CheckmarkWithText header="x3 chance to buy x4 average ticket" text="when the product is verified by an independent professional"/> */}
                  </div>
                  <div className="">
                    of counterfeit products have 
                    been detected in some specific brands listed in popular resale marketplaces
                  </div>
                </div> 
                <div className="flex-1"> 
                  <div className="">
                    <TimesFourtyYellow/>
                  </div>
                  <div className="">
                    is the operating cost of dealing with counterfeit products and VIP·ficated can slump that cost
                  </div>
                </div> 
              </div>
            </div> 
            </div> 
          </div> 
        </div> 
      </div>
      {/* lets get started */}
      <div className="bg-white max-w-7xl m-auto py-12 px-4 md:px-0">
        <div className="text-left font-bold text-3xl text-vipYellow max-w-5xl">
          Lets get started
        </div>
        <div className="text-left font-bold text-3xl text-vipYellow max-w-5xl">
          There is no better way than a free trial of our capabilities
        </div>
        <div className="text-justify md:text-left pt-4">
          In a matter of minutes and without the need for additional logistics. VIP·ficated is the perfect solution for marketplaces that want to develop their business by relying on the authenticity of their products.
        </div> 
        <div className="yellow-button mr-auto mt-4" onClick={()=>setOpenCta(!openCta)}>
          Apply for a free trial
        </div>
      </div>
      {/* footer */}
      <div className="w-screen bg-vipGreen text-white py-12">
        <div className="text-center max-w-4xl m-auto font-semibold">
          Copyright VIP·Ficated 2022
        </div>
        <div className="text-center max-w-4xl m-auto">
          This website contains analytical cookies, accepted by visitor unless they are disactivated through the browser configuration.
        </div>
      </div>
    </div>
  )
}