import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";


import { MenuDropdown } from "./MenuDropdown";

export const Navbar = (props) => {
  // const location = useLocation();
  // const params = useParams();
  const navigate = useNavigate()

  let headerOptions = [
    {
      header: "Home",
      slug: "",
      headerItems: [],
    },
    {
      header: "Item One",
      slug: "item-one",
      headerItems: ["Dropdown Item One", "Dropdown Item Two"],
    },
  ];

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    window.location.href = "/login";
  };

  return (
    <>
<div className="fixed top-0 w-full bg-white dark:bg-gray-700 shadow-sm py-2 h-[65px] z-20">
  <div className="flex items-center mb-2">
    <div className="ml-4 mr-4">
      <img
        className="h-[70px]"
        src={require(!props.darkMode
          ? "./../../files/images/vip-logo-mock.png"
          : "./../../files/images/vip-logo-white-mock.png")}
        alt="..."
        href="/" 
        onClick={()=>navigate("/home")}
      />
    </div>
    {/*<div className="flex ">
      {headerOptions.map(item => (
        <MenuDropdown
          key={item.header}
          headerName={item.header}
          headerItems={item.headerItems}
          headerSlug={item.slug}
        />
      ))}
    </div> */}
    <div className="ml-auto flex items-center">
      <div className="relative inline-block w-12 mr-4 align-middle select-none transition-all duration-200 ease-in">
        <input
          type="checkbox"
          name="toggle"
          id="toggle"
          className={`absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer 
            ${props.darkMode ? "right-0" : ""}`}
          checked={props.darkMode}
          onChange={() => props.setDarkMode(!props.darkMode)}
        />
        <label
          htmlFor="toggle"
          className={`block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer transition-colors duration-200 ${
            props.darkMode ? "bg-blue-500" : ""
          }`}
        ></label>
        <label
          htmlFor="toggle"
          className={`absolute block h-6 w-6 rounded-full bg-white border-4 cursor-pointer top-0 left-0 transition-all duration-200 ease-in ${
            props.darkMode ? "transform translate-x-full" : ""
          }`}
        ></label>
      </div>
      <button className="button-confirm mt-1" onClick={() => logout()}>
        Log out
      </button>
    </div>
  </div>
</div>

    </>
  );
};
