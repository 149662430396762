import React from "react";
import { useNavigate } from "react-router-dom";
import { ProgressDots } from "../ProgressDots";

export const PendingVerificationCard = ({item, handleAssignment, admin}) => {
  const navigate = useNavigate()
  let statusLookup = {
    pendingReview: "Looking for Verifier",
    inProgress: "Verification in progress",
    complete: "Verification complete"
  }

  //TO DO Should be dynamic

  return(
    <div className="bg-white m-auto border-2 rounded-xl border-vipGreen p-2 flex min-h-[150px] mt-6">
      <div className="w-[300px] aspect-video rounded-xl border-[1px] border-vipGreen overflow-hidden">
      { item.files[0].key ? <img
          src={`https://vip-cms.ams3.digitaloceanspaces.com/${item.files[0].key}`}
        /> : ''}
      </div>
      <div className="center-items ml-5 mr-12">
        <div>
          <div className="font-semibold text-lg">
            {item.name}
          </div>

          <ProgressDots
            item={item}
          />
          <div className="font-light text-gray-400">
          {item.dateOfLastUpdate ? item.dateOfLastUpdate : item.createdDate }
          </div>
        </div>
      </div>
      <div className="center-items ml-auto mr-12" onClick={()=>navigate(`/items/${item.uuid}`)}>
        <div className="button-confirm">
          More details
        </div>
      </div>
  { admin && item.status === 'pendingReview' ? <div className="button-confirm" onClick={handleAssignment}>
      Assign Verifier
    </div> : ''}
    </div>
  )
}
