import * as React from "react"

function TimesFourtyYellow(props) {
  return (
    <svg
      width={82}
      height={51}
      viewBox="0 0 82 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.36 46.125l9.4-12.24-.08 2.68-8.96-11.8h5.56l6.28 8.4h-2.12l6.32-8.4h5.4l-9.04 11.8.04-2.68 9.36 12.24h-5.64l-6.6-8.92 2.08.28-6.48 8.64H3.36zm22.523-6.36v-3.6l13.8-18.04h5.56l-13.6 18.04-2.6-.8h22.2v4.4h-25.36zm15.36 6.36v-6.36l.16-4.4v-5.64h4.88v16.4h-5.04zm23.869.4c-2.213 0-4.213-.56-6-1.68-1.76-1.12-3.147-2.747-4.16-4.88-1.014-2.16-1.52-4.773-1.52-7.84s.506-5.667 1.52-7.8c1.013-2.16 2.4-3.8 4.16-4.92 1.786-1.12 3.786-1.68 6-1.68 2.24 0 4.24.56 6 1.68 1.76 1.12 3.147 2.76 4.16 4.92 1.04 2.133 1.56 4.733 1.56 7.8s-.52 5.68-1.56 7.84c-1.014 2.133-2.4 3.76-4.16 4.88-1.76 1.12-3.76 1.68-6 1.68zm0-4.52c1.306 0 2.44-.347 3.4-1.04.96-.72 1.707-1.813 2.24-3.28.56-1.467.84-3.32.84-5.56 0-2.267-.28-4.12-.84-5.56-.533-1.467-1.28-2.547-2.24-3.24-.96-.72-2.094-1.08-3.4-1.08-1.253 0-2.373.36-3.36 1.08-.96.693-1.72 1.773-2.28 3.24-.534 1.44-.8 3.293-.8 5.56 0 2.24.267 4.093.8 5.56.56 1.467 1.32 2.56 2.28 3.28.986.693 2.106 1.04 3.36 1.04z"
        fill="#010101"
      />
      <path
        d="M.36 44l9.4-12.24-.08 2.68-8.96-11.8h5.56l6.28 8.4h-2.12l6.32-8.4h5.4l-9.04 11.8.04-2.68L22.52 44h-5.64l-6.6-8.92 2.08.28L5.88 44H.36zm22.523-6.36v-3.6L36.683 16h5.56l-13.6 18.04-2.6-.8h22.2v4.4h-25.36zM38.243 44v-6.36l.16-4.4V27.6h4.88V44h-5.04zm23.869.4c-2.214 0-4.214-.56-6-1.68-1.76-1.12-3.147-2.747-4.16-4.88-1.014-2.16-1.52-4.773-1.52-7.84s.506-5.667 1.52-7.8c1.013-2.16 2.4-3.8 4.16-4.92 1.786-1.12 3.786-1.68 6-1.68 2.24 0 4.24.56 6 1.68 1.76 1.12 3.147 2.76 4.16 4.92 1.04 2.133 1.56 4.733 1.56 7.8s-.52 5.68-1.56 7.84c-1.014 2.133-2.4 3.76-4.16 4.88-1.76 1.12-3.76 1.68-6 1.68zm0-4.52c1.306 0 2.44-.347 3.4-1.04.96-.72 1.707-1.813 2.24-3.28.56-1.467.84-3.32.84-5.56 0-2.267-.28-4.12-.84-5.56-.533-1.467-1.28-2.547-2.24-3.24-.96-.72-2.093-1.08-3.4-1.08-1.254 0-2.373.36-3.36 1.08-.96.693-1.72 1.773-2.28 3.24-.534 1.44-.8 3.293-.8 5.56 0 2.24.267 4.093.8 5.56.56 1.467 1.32 2.56 2.28 3.28.986.693 2.106 1.04 3.36 1.04z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default TimesFourtyYellow
