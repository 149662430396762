import axios from "axios";

const AddUserToGroupMutation = `
  mutation AddUserToGroup ($user: String, $group: String) {
      AddUserToGroup (user: $user, group: $group){
          email
      }
}`;

const AddUserToGroup = async (data, token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: AddUserToGroupMutation,
      variables: {
        ...data,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data.AddUserToGroup;
};

export { AddUserToGroup };
