import * as React from "react"

function UsersIconYellow(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.321 16.286a3.137 3.137 0 00-2.248.95l-.463.477-.468-.477a3.138 3.138 0 00-4.498 0 3.3 3.3 0 000 4.591l4.966 5.066 4.96-5.066a3.3 3.3 0 000-4.591 3.138 3.138 0 00-2.249-.95zM4.5 37.5h2.357v-5.893a5.9 5.9 0 015.893-5.893h7.071a5.9 5.9 0 015.893 5.893V37.5h2.357v-5.893a8.26 8.26 0 00-8.25-8.25H12.75a8.26 8.26 0 00-8.25 8.25V37.5zM16.286 6.857a5.893 5.893 0 110 11.786 5.893 5.893 0 010-11.786zm0-2.357a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5z"
        fill="#010101"
      />
      <path
        d="M31.321 13.286a3.137 3.137 0 00-2.248.95l-.463.477-.468-.477a3.138 3.138 0 00-4.498 0 3.3 3.3 0 000 4.591l4.966 5.066 4.96-5.066a3.3 3.3 0 000-4.591 3.138 3.138 0 00-2.249-.95zM1.5 34.5h2.357v-5.893a5.9 5.9 0 015.893-5.893h7.071a5.9 5.9 0 015.893 5.893V34.5h2.357v-5.893a8.26 8.26 0 00-8.25-8.25H9.75a8.26 8.26 0 00-8.25 8.25V34.5zM13.286 3.857a5.893 5.893 0 110 11.786 5.893 5.893 0 010-11.786zm0-2.357a8.25 8.25 0 100 16.5 8.25 8.25 0 000-16.5z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default UsersIconYellow
