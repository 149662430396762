import * as React from "react"

function TwoYellow(props) {
  return (
    <svg
      width={163}
      height={51}
      viewBox="0 0 163 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.84 26.4L24.6 35l-5.76 8.68h-4.4L19.8 35l-5.36-8.6h4.4zm1.92 6.8v3.64H6.36V33.2h14.4zM62.548 46v-3.48l11.12-10.56c.934-.88 1.627-1.653 2.08-2.32.454-.667.747-1.28.88-1.84.16-.587.24-1.133.24-1.64 0-1.28-.44-2.267-1.32-2.96-.88-.72-2.173-1.08-3.88-1.08-1.36 0-2.6.24-3.72.72-1.093.48-2.04 1.213-2.84 2.2l-3.64-2.8c1.094-1.467 2.56-2.6 4.4-3.4 1.867-.827 3.947-1.24 6.24-1.24 2.027 0 3.787.333 5.28 1 1.52.64 2.68 1.56 3.48 2.76.827 1.2 1.24 2.627 1.24 4.28 0 .907-.12 1.813-.36 2.72-.24.88-.693 1.813-1.36 2.8-.666.987-1.64 2.093-2.92 3.32l-9.56 9.08-1.08-1.96h16.36V46h-20.64zm69.886-19.6l5.76 8.6-5.76 8.68h-4.4l5.36-8.68-5.36-8.6h4.4zm1.92 6.8v3.64h-14.4V33.2h14.4z"
        fill="#010101"
      />
      <path
        d="M14.84 24.4L20.6 33l-5.76 8.68h-4.4L15.8 33l-5.36-8.6h4.4zm1.92 6.8v3.64H2.36V31.2h14.4zM58.548 44v-3.48l11.12-10.56c.934-.88 1.627-1.653 2.08-2.32.454-.667.747-1.28.88-1.84.16-.587.24-1.133.24-1.64 0-1.28-.44-2.267-1.32-2.96-.88-.72-2.173-1.08-3.88-1.08-1.36 0-2.6.24-3.72.72-1.093.48-2.04 1.213-2.84 2.2l-3.64-2.8c1.094-1.467 2.56-2.6 4.4-3.4 1.867-.827 3.947-1.24 6.24-1.24 2.027 0 3.787.333 5.28 1 1.52.64 2.68 1.56 3.48 2.76.827 1.2 1.24 2.627 1.24 4.28 0 .907-.12 1.813-.36 2.72-.24.88-.693 1.813-1.36 2.8-.666.987-1.64 2.093-2.92 3.32l-9.56 9.08-1.08-1.96h16.36V44h-20.64zm69.886-19.6l5.76 8.6-5.76 8.68h-4.4l5.36-8.68-5.36-8.6h4.4zm1.92 6.8v3.64h-14.4V31.2h14.4z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default TwoYellow
