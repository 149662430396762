import * as React from "react"

function ComputerIconYellow(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.336 9.333A3.333 3.333 0 018.669 6h26.667a3.334 3.334 0 013.333 3.333v18.334A3.333 3.333 0 0135.336 31H23.669v3.333h5a1.667 1.667 0 010 3.334H15.336a1.667 1.667 0 010-3.334h5V31H8.669a3.333 3.333 0 01-3.333-3.333V9.333zm30 18.334V9.333H8.669v18.334h26.667z"
        fill="#010101"
      />
      <path
        d="M2.336 6.333A3.333 3.333 0 015.669 3h26.667a3.334 3.334 0 013.333 3.333v18.334A3.333 3.333 0 0132.336 28H20.669v3.333h5a1.667 1.667 0 010 3.334H12.336a1.667 1.667 0 010-3.334h5V28H5.669a3.333 3.333 0 01-3.333-3.333V6.333zm30 18.334V6.333H5.669v18.334h26.667z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default ComputerIconYellow
