import * as React from "react"

function EightYellow(props) {
  return (
    <svg
      width={94}
      height={51}
      viewBox="0 0 94 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.254 26.525l5.76 8.6-5.76 8.68h-4.4l5.36-8.68-5.36-8.6h4.4zm1.92 6.8v3.64h-14.4v-3.64h14.4zm53.389 13.2c-2.32 0-4.334-.347-6.04-1.04-1.707-.693-3.027-1.667-3.96-2.92-.934-1.28-1.4-2.773-1.4-4.48 0-1.707.453-3.147 1.36-4.32.906-1.173 2.213-2.067 3.92-2.68 1.706-.613 3.746-.92 6.12-.92 2.373 0 4.413.307 6.12.92 1.733.613 3.053 1.52 3.96 2.72.933 1.173 1.4 2.6 1.4 4.28 0 1.707-.48 3.2-1.44 4.48-.934 1.253-2.267 2.227-4 2.92-1.707.693-3.72 1.04-6.04 1.04zm0-4.08c1.92 0 3.44-.4 4.56-1.2 1.12-.8 1.68-1.907 1.68-3.32 0-1.387-.56-2.48-1.68-3.28-1.12-.8-2.64-1.2-4.56-1.2-1.92 0-3.427.4-4.52 1.2-1.094.8-1.64 1.893-1.64 3.28 0 1.413.546 2.52 1.64 3.32 1.093.8 2.6 1.2 4.52 1.2zm0-9.56c-2.16 0-4.014-.28-5.56-.84-1.547-.587-2.747-1.427-3.6-2.52-.827-1.12-1.24-2.453-1.24-4 0-1.6.426-2.973 1.28-4.12.88-1.173 2.106-2.08 3.68-2.72 1.573-.64 3.386-.96 5.44-.96 2.08 0 3.906.32 5.48.96 1.573.64 2.8 1.547 3.68 2.72.88 1.147 1.32 2.52 1.32 4.12 0 1.547-.427 2.88-1.28 4-.827 1.093-2.027 1.933-3.6 2.52-1.574.56-3.44.84-5.6.84zm0-3.2c1.653 0 2.96-.347 3.92-1.04.96-.72 1.44-1.68 1.44-2.88 0-1.253-.494-2.227-1.48-2.92-.987-.693-2.28-1.04-3.88-1.04s-2.88.347-3.84 1.04c-.96.693-1.44 1.667-1.44 2.92 0 1.2.466 2.16 1.4 2.88.96.693 2.253 1.04 3.88 1.04z"
        fill="#010101"
      />
      <path
        d="M14.84 24.4L20.6 33l-5.76 8.68h-4.4L15.8 33l-5.36-8.6h4.4zm1.92 6.8v3.64H2.36V31.2h14.4zm53.388 13.2c-2.32 0-4.333-.347-6.04-1.04-1.706-.693-3.026-1.667-3.96-2.92-.933-1.28-1.4-2.773-1.4-4.48 0-1.707.454-3.147 1.36-4.32.907-1.173 2.214-2.067 3.92-2.68 1.707-.613 3.747-.92 6.12-.92 2.374 0 4.414.307 6.12.92 1.734.613 3.054 1.52 3.96 2.72.934 1.173 1.4 2.6 1.4 4.28 0 1.707-.48 3.2-1.44 4.48-.933 1.253-2.266 2.227-4 2.92-1.706.693-3.72 1.04-6.04 1.04zm0-4.08c1.92 0 3.44-.4 4.56-1.2 1.12-.8 1.68-1.907 1.68-3.32 0-1.387-.56-2.48-1.68-3.28-1.12-.8-2.64-1.2-4.56-1.2-1.92 0-3.426.4-4.52 1.2-1.093.8-1.64 1.893-1.64 3.28 0 1.413.547 2.52 1.64 3.32 1.094.8 2.6 1.2 4.52 1.2zm0-9.56c-2.16 0-4.013-.28-5.56-.84-1.546-.587-2.746-1.427-3.6-2.52-.826-1.12-1.24-2.453-1.24-4 0-1.6.427-2.973 1.28-4.12.88-1.173 2.107-2.08 3.68-2.72 1.574-.64 3.387-.96 5.44-.96 2.08 0 3.907.32 5.48.96 1.574.64 2.8 1.547 3.68 2.72.88 1.147 1.32 2.52 1.32 4.12 0 1.547-.426 2.88-1.28 4-.826 1.093-2.026 1.933-3.6 2.52-1.573.56-3.44.84-5.6.84zm0-3.2c1.654 0 2.96-.347 3.92-1.04.96-.72 1.44-1.68 1.44-2.88 0-1.253-.493-2.227-1.48-2.92-.986-.693-2.28-1.04-3.88-1.04s-2.88.347-3.84 1.04c-.96.693-1.44 1.667-1.44 2.92 0 1.2.467 2.16 1.4 2.88.96.693 2.254 1.04 3.88 1.04z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default EightYellow
