import axios from "axios";

const GetRequestQuery = `
query GetRequest($uuid: String)  {
    GetRequest(uuid: $uuid) {
        category
        brand
        model
        name
        description
        status
        dateOfLastUpdate
        uuid
        createdDate
        files
        verdict
        confidence
        secondOpinionRequested
        _id
        certificate
    }
}`;

const GetRequest = async (data, token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: GetRequestQuery,
      variables: {
        ...data,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data.GetRequest;
};

export { GetRequest };
