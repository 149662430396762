import React from "react";  
import YellowManImage from './../../img/card-images/laptop-man.png'

export const CallToActionModal = ({open, setOpen}) => {
  return(
    <> 
      <div className={`h-screen w-screen fixed z-10 ${!open ?"hidden" : ""}`}>
        <div className="w-full h-full grid place-items-center relative backdrop-blur-sm z-10">
        {/* onClick={()=>setOpen(!open)} */}
          <div className="max-w-3xl w-full bg-white min-h-[550px] rounded-xl p-10 shadow-xl relative overflow-hidden relative">
            <div className="absolute top-0 right-0 font-bold pr-4 pt-4 text-2xl cursor-pointer" onClick={()=>setOpen(!open)}>
              x
            </div>
            <div className="text-vipYellow font-bold text-4xl w-[60%] ml-auto pb-4" style={{backgroundImage: "url("+YellowManImage+")"}}>
              Apply for a free trial
            </div>
            <div className="absolute -top-3 -left-52 animate-wiggle origin-top">
              <img src={YellowManImage} className="w-[700px] " />
            </div>
            <div className="w-[60%] ml-auto font-semibold">
              Fill out your email below and we will get in touch with you!
            </div>
            <div className="w-[60%] ml-auto pt-4 relative z-30">
              <input 
                type="text"
                className="focus:ring-0 focus:border-2 border-vipYellow px-4 py-1 border-2 rounded-lg focus:outline-none border-opacity-50 focus:border-opacity-100 placeholder:text-sm"
                placeholder="Email address .. "
              />
            </div>
            <div className="w-[60%] ml-auto pt-20 relative z-30">
              <div className=" yellow-button " onClick={()=>setOpen(!open)}>
                Submit
              </div> 
              <div className="max-w-lg text-sm flex w-fit pt-12">
                <input 
                  className="pt-1 mr-2 mt-1"
                  type="checkbox"
                />
                <div className="text-justify">
                  By checking the box you agree to be contacted by the Vipficated team for the requested trial and possible other Vipficated related correspondence. 
                </div>
              </div>
            </div>
            
          </div>
        </div>
      </div> 
    </>
    
  )
}