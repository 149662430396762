import axios from "axios";

const AssignVerifierMutation = `
mutation AssignVerifier($verifier: String, $requests: [String])  {
    AssignVerifier (verifier: $verifier, requests: $requests)
}`;

const AssignVerifier = async (data, token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: AssignVerifierMutation,
      variables: {
        ...data,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data.AssignVerifier;
};

export { AssignVerifier };
