import * as React from "react"

function CheckIconCustom(props) {
  return (
    <svg
      width={54}
      height={44}
      viewBox="0 0 54 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 22l18.75 20L52 2"
        stroke="#282828"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CheckIconCustom
