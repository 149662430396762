import * as React from "react"

function RotateIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 1000 1000"
      {...props}
    >
      <path
        d="M5260 4367.9c-1803.6-157-3329.6-1457-3771.9-3212.8-34.5-135.9-72.7-331.2-86.2-430.8-11.5-99.6-24.9-201-30.6-224l-7.7-44H731.8c-528.4 0-631.8-3.8-631.8-26.8 0-42.1 2029.5-2042.9 2104.2-2073.6 36.4-15.3 86.2-21.1 118.7-15.3 40.2 9.6 346.6 300.6 1089.4 1037.7 568.7 562.9 1034 1035.9 1034 1051.2 0 23-103.4 26.8-620.3 26.8-342.7 0-626.1 5.7-629.9 15.3-13.4 21.1 38.3 245.1 93.8 415.5 114.9 348.5 319.7 681.6 582.1 947.8 478.7 484.4 1062.6 731.4 1742.3 735.2 317.8 0 482.5-23 765.9-114.9 402.1-128.3 725.7-331.2 1022.4-641.4C7858.3 1339 8090 766.5 8090 111.7c-1.9-693.1-254.6-1284.7-754.4-1771-396.3-384.9-882.7-614.6-1430.3-679.7-141.7-17.2-195.3-32.6-227.9-63.2l-44-38.3v-1623.6l49.8-49.8c49.8-47.9 53.6-49.8 243.2-36.4 1606.4 105.3 3048.1 1146.9 3658.9 2646 453.8 1112.4 415.5 2379.9-105.3 3463.6-360 752.5-953.5 1397.7-1677.3 1828.5-515 308.3-1139.2 513.1-1746.2 574.4-183.8 17.2-618.4 21.1-796.5 5.7z"
        transform="matrix(.1 0 0 -.1 0 511)"
      />
    </svg>
  )
}

export default RotateIcon
