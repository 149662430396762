import axios from "axios";

const ReviewRequestMutation = `
mutation ReviewRequest($uuid: String, $verdict: Int, $comments: JSON, $secondOpinionRequested: Boolean){
   ReviewRequest(verdict: $verdict, uuid: $uuid, comments: $comments, secondOpinionRequested: $secondOpinionRequested){
    category
    uuid
  }
}`;

const ReviewRequest = async (data, token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: ReviewRequestMutation,
      variables: {
        ...data,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data.ReviewRequest;
};

export { ReviewRequest };
