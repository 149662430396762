import * as React from "react"

function TimesElevenYellow(props) {
  return (
    <svg
      width={82}
      height={51}
      viewBox="0 0 82 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.36 46.125l9.4-12.24-.08 2.68-8.96-11.8h5.56l6.28 8.4h-2.12l6.32-8.4h5.4l-9.04 11.8.04-2.68 9.36 12.24h-5.64l-6.6-8.92 2.08.28-6.48 8.64H3.36zm29.24 0v-26l2.28 2.36h-8.2v-4.36H37.8v28h-5.2zm15.234 0v-26l2.28 2.36h-8.2v-4.36h11.12v28h-5.2z"
        fill="#010101"
      />
      <path
        d="M.36 44l9.4-12.24-.08 2.68-8.96-11.8h5.56l6.28 8.4h-2.12l6.32-8.4h5.4l-9.04 11.8.04-2.68L22.52 44h-5.64l-6.6-8.92 2.08.28L5.88 44H.36zm29.24 0V18l2.28 2.36h-8.2V16H34.8v28h-5.2zm15.234 0V18l2.28 2.36h-8.2V16h11.12v28h-5.2z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default TimesElevenYellow
