export const calendarRandomData = [
  {
    "range": 1
  },
  {
    "range": 1
  },
  {
    "range": 8
  },
  {
    "range": 9
  },
  {
    "range": 6
  },
  {
    "range": 3
  },
  {
    "range": 7
  },
  {
    "range": 4
  },
  {
    "range": 2
  },
  {
    "range": 3
  },
  {
    "range": 7
  },
  {
    "range": 9
  },
  {
    "range": 4
  },
  {
    "range": 4
  },
  {
    "range": 2
  },
  {
    "range": 0
  },
  {
    "range": 2
  },
  {
    "range": 8
  },
  {
    "range": 5
  },
  {
    "range": 3
  },
  {
    "range": 1
  },
  {
    "range": 9
  },
  {
    "range": 5
  },
  {
    "range": 9
  },
  {
    "range": 1
  },
  {
    "range": 5
  },
  {
    "range": 1
  },
  {
    "range": 5
  },
  {
    "range": 8
  },
  {
    "range": 9
  },
  {
    "range": 1
  },
  {
    "range": 8
  },
  {
    "range": 6
  },
  {
    "range": 6
  },
  {
    "range": 3
  },
  {
    "range": 6
  },
  {
    "range": 9
  },
  {
    "range": 4
  },
  {
    "range": 1
  },
  {
    "range": 1
  },
  {
    "range": 9
  },
  {
    "range": 9
  },
  {
    "range": 5
  },
  {
    "range": 8
  },
  {
    "range": 7
  },
  {
    "range": 3
  },
  {
    "range": 0
  },
  {
    "range": 9
  },
  {
    "range": 5
  },
  {
    "range": 3
  },
  {
    "range": 8
  },
  {
    "range": 2
  },
  {
    "range": 6
  },
  {
    "range": 9
  },
  {
    "range": 8
  },
  {
    "range": 3
  },
  {
    "range": 0
  },
  {
    "range": 0
  },
  {
    "range": 2
  },
  {
    "range": 7
  },
  {
    "range": 5
  },
  {
    "range": 3
  },
  {
    "range": 0
  },
  {
    "range": 6
  },
  {
    "range": 4
  },
  {
    "range": 9
  },
  {
    "range": 1
  },
  {
    "range": 6
  },
  {
    "range": 0
  },
  {
    "range": 0
  },
  {
    "range": 3
  },
  {
    "range": 4
  },
  {
    "range": 6
  },
  {
    "range": 1
  },
  {
    "range": 6
  },
  {
    "range": 3
  },
  {
    "range": 8
  },
  {
    "range": 6
  },
  {
    "range": 7
  },
  {
    "range": 8
  },
  {
    "range": 8
  },
  {
    "range": 9
  },
  {
    "range": 6
  },
  {
    "range": 4
  },
  {
    "range": 0
  },
  {
    "range": 9
  },
  {
    "range": 6
  },
  {
    "range": 4
  },
  {
    "range": 2
  },
  {
    "range": 6
  },
  {
    "range": 7
  },
  {
    "range": 3
  },
  {
    "range": 4
  },
  {
    "range": 8
  },
  {
    "range": 3
  },
  {
    "range": 1
  },
  {
    "range": 8
  },
  {
    "range": 4
  },
  {
    "range": 9
  },
  {
    "range": 9
  },
  {
    "range": 2
  },
  {
    "range": 8
  },
  {
    "range": 2
  },
  {
    "range": 5
  },
  {
    "range": 4
  },
  {
    "range": 8
  },
  {
    "range": 8
  },
  {
    "range": 0
  },
  {
    "range": 1
  },
  {
    "range": 3
  },
  {
    "range": 5
  },
  {
    "range": 8
  },
  {
    "range": 6
  },
  {
    "range": 3
  },
  {
    "range": 1
  },
  {
    "range": 1
  },
  {
    "range": 0
  },
  {
    "range": 5
  },
  {
    "range": 5
  },
  {
    "range": 8
  },
  {
    "range": 7
  },
  {
    "range": 6
  },
  {
    "range": 5
  },
  {
    "range": 0
  },
  {
    "range": 8
  },
  {
    "range": 2
  },
  {
    "range": 2
  },
  {
    "range": 6
  },
  {
    "range": 5
  },
  {
    "range": 1
  },
  {
    "range": 9
  },
  {
    "range": 2
  },
  {
    "range": 0
  },
  {
    "range": 2
  },
  {
    "range": 1
  },
  {
    "range": 9
  },
  {
    "range": 8
  },
  {
    "range": 0
  },
  {
    "range": 6
  },
  {
    "range": 3
  },
  {
    "range": 8
  },
  {
    "range": 8
  },
  {
    "range": 6
  },
  {
    "range": 6
  },
  {
    "range": 2
  },
  {
    "range": 7
  },
  {
    "range": 0
  },
  {
    "range": 2
  },
  {
    "range": 8
  },
  {
    "range": 4
  },
  {
    "range": 2
  },
  {
    "range": 0
  },
  {
    "range": 6
  },
  {
    "range": 0
  },
  {
    "range": 2
  },
  {
    "range": 8
  },
  {
    "range": 2
  },
  {
    "range": 7
  },
  {
    "range": 2
  },
  {
    "range": 1
  },
  {
    "range": 0
  },
  {
    "range": 8
  },
  {
    "range": 0
  },
  {
    "range": 8
  },
  {
    "range": 4
  },
  {
    "range": 8
  },
  {
    "range": 2
  },
  {
    "range": 3
  },
  {
    "range": 0
  },
  {
    "range": 2
  },
  {
    "range": 7
  },
  {
    "range": 2
  },
  {
    "range": 7
  },
  {
    "range": 2
  },
  {
    "range": 8
  },
  {
    "range": 4
  },
  {
    "range": 4
  },
  {
    "range": 6
  },
  {
    "range": 1
  },
  {
    "range": 7
  },
  {
    "range": 0
  },
  {
    "range": 2
  },
  {
    "range": 1
  },
  {
    "range": 4
  },
  {
    "range": 6
  },
  {
    "range": 0
  },
  {
    "range": 9
  },
  {
    "range": 3
  },
  {
    "range": 9
  },
  {
    "range": 1
  },
  {
    "range": 1
  },
  {
    "range": 8
  },
  {
    "range": 0
  },
  {
    "range": 8
  },
  {
    "range": 8
  },
  {
    "range": 0
  },
  {
    "range": 9
  },
  {
    "range": 0
  },
  {
    "range": 9
  },
  {
    "range": 7
  },
  {
    "range": 2
  },
  {
    "range": 9
  },
  {
    "range": 0
  },
  {
    "range": 5
  },
  {
    "range": 7
  },
  {
    "range": 2
  },
  {
    "range": 4
  },
  {
    "range": 7
  },
  {
    "range": 2
  },
  {
    "range": 5
  },
  {
    "range": 5
  },
  {
    "range": 2
  },
  {
    "range": 1
  },
  {
    "range": 4
  },
  {
    "range": 1
  },
  {
    "range": 4
  },
  {
    "range": 2
  },
  {
    "range": 5
  },
  {
    "range": 7
  },
  {
    "range": 4
  },
  {
    "range": 2
  },
  {
    "range": 6
  },
  {
    "range": 9
  },
  {
    "range": 7
  },
  {
    "range": 6
  },
  {
    "range": 1
  },
  {
    "range": 9
  },
  {
    "range": 2
  },
  {
    "range": 2
  },
  {
    "range": 6
  },
  {
    "range": 9
  },
  {
    "range": 5
  },
  {
    "range": 4
  },
  {
    "range": 9
  },
  {
    "range": 0
  },
  {
    "range": 0
  },
  {
    "range": 1
  },
  {
    "range": 5
  },
  {
    "range": 2
  },
  {
    "range": 6
  },
  {
    "range": 5
  },
  {
    "range": 1
  },
  {
    "range": 3
  },
  {
    "range": 9
  },
  {
    "range": 8
  },
  {
    "range": 1
  },
  {
    "range": 9
  },
  {
    "range": 0
  },
  {
    "range": 2
  },
  {
    "range": 0
  },
  {
    "range": 4
  },
  {
    "range": 0
  },
  {
    "range": 9
  },
  {
    "range": 8
  },
  {
    "range": 0
  },
  {
    "range": 7
  },
  {
    "range": 6
  },
  {
    "range": 3
  },
  {
    "range": 2
  },
  {
    "range": 1
  },
  {
    "range": 7
  },
  {
    "range": 8
  },
  {
    "range": 8
  },
  {
    "range": 5
  },
  {
    "range": 6
  },
  {
    "range": 5
  },
  {
    "range": 3
  },
  {
    "range": 0
  },
  {
    "range": 4
  },
  {
    "range": 5
  },
  {
    "range": 3
  },
  {
    "range": 2
  },
  {
    "range": 7
  },
  {
    "range": 8
  },
  {
    "range": 6
  },
  {
    "range": 4
  },
  {
    "range": 5
  },
  {
    "range": 5
  },
  {
    "range": 3
  },
  {
    "range": 8
  },
  {
    "range": 5
  },
  {
    "range": 2
  },
  {
    "range": 6
  },
  {
    "range": 9
  },
  {
    "range": 1
  },
  {
    "range": 9
  },
  {
    "range": 7
  },
  {
    "range": 8
  },
  {
    "range": 2
  },
  {
    "range": 2
  },
  {
    "range": 4
  },
  {
    "range": 7
  },
  {
    "range": 7
  },
  {
    "range": 7
  },
  {
    "range": 0
  },
  {
    "range": 3
  },
  {
    "range": 2
  },
  {
    "range": 0
  },
  {
    "range": 4
  },
  {
    "range": 2
  },
  {
    "range": 5
  },
  {
    "range": 3
  },
  {
    "range": 9
  },
  {
    "range": 6
  },
  {
    "range": 9
  },
  {
    "range": 5
  },
  {
    "range": 8
  },
  {
    "range": 7
  },
  {
    "range": 4
  },
  {
    "range": 2
  },
  {
    "range": 8
  },
  {
    "range": 5
  },
  {
    "range": 1
  },
  {
    "range": 6
  },
  {
    "range": 1
  },
  {
    "range": 2
  },
  {
    "range": 1
  },
  {
    "range": 2
  },
  {
    "range": 3
  },
  {
    "range": 7
  },
  {
    "range": 8
  },
  {
    "range": 7
  },
  {
    "range": 0
  },
  {
    "range": 9
  },
  {
    "range": 1
  },
  {
    "range": 6
  },
  {
    "range": 4
  },
  {
    "range": 5
  },
  {
    "range": 1
  },
  {
    "range": 1
  },
  {
    "range": 4
  },
  {
    "range": 5
  },
  {
    "range": 9
  },
  {
    "range": 7
  },
  {
    "range": 3
  },
  {
    "range": 0
  },
  {
    "range": 9
  },
  {
    "range": 9
  },
  {
    "range": 6
  },
  {
    "range": 8
  },
  {
    "range": 8
  },
  {
    "range": 2
  },
  {
    "range": 1
  },
  {
    "range": 2
  },
  {
    "range": 9
  },
  {
    "range": 9
  },
  {
    "range": 8
  },
  {
    "range": 7
  },
  {
    "range": 8
  },
  {
    "range": 7
  },
  {
    "range": 0
  },
  {
    "range": 5
  },
  {
    "range": 2
  },
  {
    "range": 3
  },
  {
    "range": 4
  },
  {
    "range": 9
  },
  {
    "range": 9
  },
  {
    "range": 2
  },
  {
    "range": 9
  },
  {
    "range": 5
  },
  {
    "range": 3
  },
  {
    "range": 1
  },
  {
    "range": 9
  },
  {
    "range": 2
  },
  {
    "range": 6
  }
]