import axios from "axios";

const UpdateRequestMutation = `
mutation UpdateRequest($uuid: String, $update: RequestInputType){
  UpdateRequest(update: $update, uuid: $uuid){
    category
    uuid
  }
}`;

const UpdateRequest = (data, cb) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: UpdateRequestMutation,
      variables: {
        ...data,
      },
    },
  })
    .then((result) => cb(false, result.data.data.UpdateRequest))
    .catch((err) => cb(true, err));
};

export { UpdateRequest };
