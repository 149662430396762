import { CheckIcon, XIcon } from "@heroicons/react/solid";
import { CalendarOverview } from "assets/components/calendar/calendar-overview";
import { ContentCard } from "assets/components/content-card/ContentCard";
import { GridCardHome } from "assets/components/grid-cards/GridCardHome";
import React, { useEffect, useState } from "react";
import { getHeaders } from "../../firebase";
import { useNavigate } from "react-router-dom";

import { GetUsers } from "api/GetUsers.js";
import { SendReminderEmail } from "api/SendReminderEmail.js";
import { AddUserToGroup } from "api/AddUserToGroup.js";
import { GetAllVerifications } from "api/GetAllVerifications.js";
import { AssignVerifier } from "api/AssignVerifier.js";
import { CalculateScore } from "api/CalculateScore.js";
import { InviteUser } from "api/InviteUser.js";
import { GetAllPayments } from "api/GetAllPayments.js";
import { ReviewPayment } from "api/ReviewPayment.js";
import { DownloadVerificationHistory } from "api/DownloadVerificationHistory.js";
import { DownloadTransactions } from "api/DownloadTransactions.js";
import { DownloadAllPaymentHistory } from "api/DownloadAllPaymentHistory";
import DatePicker from "react-datepicker";
import { CSVLink, CSVDownload } from "react-csv";

import "react-datepicker/dist/react-datepicker.css";
import { DownloadTestSummary } from "api/DownloadTestSummary";

export const AdminOverview = (props) => {
  const [selectedItem, setSelectedItem] = useState(0);
  const [newVerifiers, setNewVerifiers] = useState([]);
  const [allVerifiers, setAllVerifiers] = useState([]);
  const [newVerifications, setNewVerifications] = useState([]);
  const [inprogressVerifications, setInprogressVerifications] = useState([]);
  const [completedVerifications, setCompletedVerifications] = useState([]);
  const [
    secondOpinionRequestedVerifications,
    setSecondOpinionRequestedVerifications,
  ] = useState([]);
  const [assignmentMode, setAssignmentMode] = useState(false);
  const [activeAssignment, setActiveAssignment] = useState();
  const [inviteeName, setInviteeName] = useState();
  const [inviteeEmail, setInviteeEmail] = useState();
  const [viewScoreMode, setViewScoreMode] = useState(false);
  const [currentScore, setCurrentScore] = useState([]);
  const [pendingPayments, setPendingPayments] = useState([]);
  const [startDate, setStartDate] = useState(
    new Date().setDate(new Date().getDate() - 7)
  );
  const [endDate, setEndDate] = useState(new Date());
  const [csvData, setCsvData] = useState();
  const navigate = useNavigate();
  const headers = localStorage.getItem("token");

  const [data, setData] = useState([]);
  const [triggerEffect, setTriggerEffect] = useState(false);
  const [
    triggerEffecForVerifications,
    setTriggerEffecForVerifications,
  ] = useState(false);
  const addUserToGroup = (user, group) => {
    AddUserToGroup(
      {
        user: user,
        group: group,
      },
      headers
    ).then((out) => {
      console.log("Approved", out);
      // alert('Success')
      props.notify("Success");
      setTriggerEffect(true);
    });
  };

  //Get all verifiers
  useEffect(() => {
    GetUsers(
      {
        filter: {
          type: "verifier",
        },
      },
      headers
    ).then((out) => {
      const unapproved = out.filter((x) => x.status === "unapprovedVerifier");
      const approved = out.filter((x) => x.status === "approvedVerifier");
      setNewVerifiers([...unapproved]);
      setAllVerifiers([...approved]);
      console.log("calling all verifiers");
    });
  }, [triggerEffect]);

  //Get all pending verifications i.e. requests that are not assigned
  useEffect(() => {
    GetAllVerifications(
      {
        status: "pendingReview",
      },
      headers
    ).then((out) => {
      setNewVerifications([...out]);
    });
  }, []);

  useEffect(() => {
    GetAllVerifications(
      {
        status: "inProgress",
      },
      headers
    ).then((out) => {
      console.log("In Progress", out);
      setInprogressVerifications([...out]);
    });
  }, [triggerEffecForVerifications]);

  //Get all completed verifications
  useEffect(() => {
    GetAllVerifications(
      {
        status: "complete",
      },
      headers
    ).then((out) => {
      setCompletedVerifications([...out]);
    });
  }, []);

  useEffect(() => {
    GetAllVerifications(
      {
        secondOpinionRequested: true,
      },
      headers
    ).then((out) => {
      console.log("OUT second opinion", out);
      setSecondOpinionRequestedVerifications([...out]);
    });
  }, [triggerEffecForVerifications]);

  useEffect(() => {
    if (selectedItem === 6) {
      console.log("selected item six");
      GetAllPayments(
        {
          status: "pending",
        },
        headers
      ).then((out) => {
        console.log("OUT Payments", out);
        setPendingPayments([...out]);
      });
    }
  }, [selectedItem]);

  let adminCards = [
    {
      name: "Verifiers Overview",
    },
    {
      name: "Incoming verifications",
    },
    {
      name: "In progress verifications",
    },
    {
      name: "Completed verifications",
    },
    {
      name: "Second opinion request",
    },
    {
      name: "Invite Verifiers",
    },
    {
      name: "Pending Payments",
    },
    {
      name: "Approved Payments",
    },
  ];

  const reviewPayment = (id, status) => {
    ReviewPayment(
      {
        _id: id,
        status: status,
      },
      headers
    ).then((out) => {
      // alert('Success')
      props.notify("Success");
    });
  };

  const sendReminderEmail = (email, name) => {
    SendReminderEmail(
      {
        email: email,
        firstName: name,
      },
      headers
    ).then((out) => {
      console.log("Approved", out);
      // alert('Success')
      props.notify("Success");
    });
  };
  const [verificationCSV, setVerificationCSV] = useState([]);
  const [paymentCSV, setPaymentCSV] = useState([]);
  const [testCSV, setTestCSV] = useState([]);

  //Invite Verifiers
  const inviteVerifier = () => {
    InviteUser(
      {
        type: "verifier",
        email: inviteeEmail,
        name: inviteeName,
      },
      headers
    ).then((out) => {
      console.log("Send", out);
      //alert('Successlly invited user')
      props.notify("Successfully invited user");

      setInviteeName();
      setInviteeEmail();
    });
  };

  //Remove user from approved list and add to rejected
  const removeUserFromGroup = (user, group) => {};

  const assignItemToVerifier = (verifier, productToAssign) => {
    console.log("Assignin item", productToAssign, "to verifier", verifier);
    AssignVerifier(
      {
        verifier: verifier,
        requests: [productToAssign],
      },
      headers
    )
      .then((out) => {
        // alert('Success')
        props.notify("Successfully invited user");
        setAssignmentMode(false);
        triggerEffecForVerifications(true);
      })
      .catch((error) => console.log(error));
  };

  const showScore = (verifier) => {
    setViewScoreMode(true);
    CalculateScore(
      {
        verifierId: verifier,
      },
      headers
    )
      .then((out) => {
        console.log("score ", out);
        if (out !== "No answers found") {
          setCurrentScore([...out]);
        }
      })
      .catch((error) => console.log(error));
  };

  //Get CSV Data
  useEffect(() => {
    DownloadTransactions({
      startDate: new Date(startDate).toLocaleDateString("ko-KR"),
      endDate: new Date().toLocaleDateString("ko-KR"),
    }).then((out) => {
      console.log(out);
      setCsvData([...out]);
    });
  }, [startDate, endDate]);

  const [columnPolarity, setColumnPolarity] = useState({});

  const sortTable = (table, setTable, key) => {
    if (columnPolarity[key] === undefined)
      setColumnPolarity({ ...columnPolarity, [key]: 1 });
    else
      setColumnPolarity({ ...columnPolarity, [key]: columnPolarity[key] * -1 });

    let sorted = [...table];

    sorted.sort((a, b) => {
      if (a[key] < b[key]) {
        if (columnPolarity[key] === undefined) return -1 * 1;
        return -1 * columnPolarity[key];
      }
      if (a[key] > b[key]) {
        if (columnPolarity[key] === undefined) return 1 * 1;
        return 1 * columnPolarity[key];
      }
      return 0;
    });
    setTable([...sorted]);
  };

  return (
    <div className="">
      <div className="text-2xl font-semibold py-4 px-6 select-none underline">
        Admin overview
      </div>
      <div className="grid grid-cols-5 px-3">
        {adminCards.map((item, index) => (
          <div
            className="bg-vipGreen aspect-video m-4 rounded-xl center-items hover:shadow-xl cursor-pointer animate hover:opacity-90 bg-cover overflow-hidden text-white"
            onClick={() => {
              if (selectedItem === index) setSelectedItem(-1);
              else setSelectedItem(index);
            }}
          >
            <div className="h-full w-full bg-vipGreen hover:opacity-40 opacity-80 animate center-items"></div>
            <div className="absolute text-xl ">{item.name}</div>
          </div>
        ))}
      </div>

      {selectedItem === 0 && (
        <div className="grid gap-x-8 grid-cols-2">
          <div className="ml-7 mt-7 bg-white shadow w-fit rounded-xl p-5 ">
            <div className="font-bold text-xl pb-2">Verifier Applications</div>
            <div className="">
              <table>
                <thead className="text-left">
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(newVerifiers, setNewVerifiers, "lastName")
                    }
                  >
                    Name
                  </th>
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(newVerifiers, setNewVerifiers, "email")
                    }
                  >
                    Email
                  </th>
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(newVerifiers, setNewVerifiers, "phone")
                    }
                  >
                    Phone
                  </th>
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(newVerifiers, setNewVerifiers, "city")
                    }
                  >
                    City
                  </th>
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(newVerifiers, setNewVerifiers, "date")
                    }
                  >
                    Date
                  </th>
                  {/* <th className="mr-4 pr-4">Categories</th> */}
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(newVerifiers, setNewVerifiers, "progression")
                    }
                  >
                    Test Progression
                  </th>
                  <th className="mr-4 pr-4">Test Score</th>
                  <th className="mr-4 pr-4">Test Download</th>
                  <th className="mr-4 pr-4">Reminder</th>
                  <th className="mr-4 pr-4">Decision</th>
                </thead>
                {newVerifiers.length != 0 &&
                  newVerifiers.map((item) => (
                    <tr className="border-b-[1px] border-vipGreen">
                      <td className="pr-5 py-2">
                        {item.firstName} {item.lastName}
                      </td>
                      <td className="pr-5">{item.email}</td>
                      <td className="pr-5">{item.phone}</td>
                      <td className="pr-5">{item.city}</td>
                      <td className="pr-5">{item.date}</td>
                      {/* <td className="pr-5 py-2">
                        <div>
                          {'item.category'}
                        </div>
                      </td> */}
                      <td className="pr-5">
                        {item.progression ? `${item.progression + "%"}` : "NA"}
                      </td>
                      <td className="pr-5">
                        <div
                          className="button-confirm"
                          onClick={() => showScore(item.uuid)}
                        >
                          View
                        </div>
                      </td>
                      <td className="pr-5">
                        <div
                          className="button-confirm"
                          onClick={() =>
                            console.log("Download Data for User", item)
                          }
                        >
                          <CSVLink
                            data={testCSV.length > 0 ? testCSV : [[]]}
                            onClick={() => {
                              DownloadTestSummary(
                                {
                                  email: item.email,
                                },
                                headers
                              ).then((out) => {
                                setTestCSV([...out]);
                              });
                            }}
                          >
                            Download
                          </CSVLink>
                        </div>
                      </td>
                      <td className="pr-5">
                        <div
                          className="button-confirm"
                          onClick={() =>
                            sendReminderEmail(item.email, item.firstName)
                          }
                        >
                          Send Reminder
                        </div>
                      </td>
                      <td className="pr-5">
                        <div className="flex">
                          <div className="w-fit m-auto text-vipGreen cursor-pointer">
                            <CheckIcon
                              width={20}
                              onClick={() =>
                                addUserToGroup(item.email, "approvedVerifier")
                              }
                            />
                          </div>
                          <div className="w-fit m-auto text-red-500 cursor-pointer">
                            <XIcon
                              width={20}
                              onClick={() =>
                                addUserToGroup(item.email, "rejectedVerifier")
                              }
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </table>
            </div>
            <div className="font-bold text-xl pb-2 pt-6">
              All Approved Verifiers
            </div>
            <div className="">
              <table>
                <thead className="text-left">
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(allVerifiers, setAllVerifiers, "lastName")
                    }
                  >
                    Name
                  </th>
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(allVerifiers, setAllVerifiers, "email")
                    }
                  >
                    Email
                  </th>
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(allVerifiers, setAllVerifiers, "phone")
                    }
                  >
                    Phone
                  </th>
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(allVerifiers, setAllVerifiers, "city")
                    }
                  >
                    City
                  </th>
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(allVerifiers, setAllVerifiers, "date")
                    }
                  >
                    Date
                  </th>
                  <th className="mr-4 pr-4">Download Verifications</th>
                  <th className="mr-4 pr-4">Download Payment</th>
                  <th className="mr-4 pr-4">Remove User</th>
                </thead>
                {allVerifiers.length != 0 &&
                  allVerifiers.map((item) => (
                    <tr className="border-b-[1px] border-vipGreen">
                      <td className="pr-5 py-2">
                        {item.firstName} {item.lastName}
                      </td>
                      <td className="pr-5">{item.email}</td>
                      <td className="pr-5">{item.phone}</td>
                      <td className="pr-5">{item.city}</td>
                      <td className="pr-5">{item.date}</td>
                      <td className="pr-5">
                        <div
                          className="button-confirm"
                          onClick={() =>
                            console.log("Download Data for User", item)
                          }
                        >
                          <CSVLink
                            data={
                              verificationCSV.length > 0
                                ? verificationCSV
                                : [[]]
                            }
                            onClick={() => {
                              DownloadVerificationHistory(
                                //TO DO: Change this to the user's email. This is just for testing as only below user has the data
                                {
                                  email: item.email,
                                },
                                headers
                              ).then((out) => {
                                setVerificationCSV([...out]);
                              });
                            }}
                          >
                            Download
                          </CSVLink>
                        </div>
                      </td>
                      <td className="pr-5">
                        <div
                          className="button-confirm"
                          onClick={() =>
                            console.log("Download Data for User", item)
                          }
                        >
                          <CSVLink
                            data={paymentCSV.length > 0 ? paymentCSV : [[]]}
                            onClick={() => {
                              DownloadAllPaymentHistory(
                                {
                                  email: "dikshya.parajuli+test@gmail.com",
                                },
                                headers
                              ).then((out) => {
                                console.log("Payments", out);
                                // alert('Success')
                                setPaymentCSV([...out]);
                              });
                            }}
                          >
                            Download
                          </CSVLink>
                        </div>
                      </td>
                      <td className="pr-5">
                        <div className="flex">
                          <div className="w-fit m-auto text-red-500 cursor-pointer">
                            <XIcon
                              width={20}
                              onClick={() => removeUserFromGroup(item.email)}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
              </table>
            </div>
          </div>
          {viewScoreMode && (
            <div className=" ml-7 mt-7 bg-white shadow w-fit rounded-xl p-5">
              <div className="font-bold text-xl pb-2">
                Test Score Summary{" "}
                <XIcon
                  width={20}
                  onClick={() => {
                    setViewScoreMode(false);
                    setCurrentScore([]);
                  }}
                />
              </div>

              <div className="">
                <table>
                  <thead className="text-left">
                    <th className="mr-4 pr-4">Category</th>
                    <th className="mr-4 pr-4">Score</th>
                  </thead>
                  {currentScore.length !== 0
                    ? currentScore.map((item) => (
                        <tr className="border-b-[1px] border-vipGreen">
                          <td className="pr-5 py-2">{item.category}</td>
                          <td className="pr-5">{item.percentage * 100}</td>
                        </tr>
                      ))
                    : "No answers found"}
                </table>
              </div>
            </div>
          )}
        </div>
      )}

      {selectedItem === 1 && (
        <div className="grid  gap-x-8 grid-cols-2">
          <div className=" ml-7 mt-7 bg-white shadow w-fit rounded-xl p-5">
            <div className="font-bold text-xl pb-2">Incoming Verifications</div>
            <div className="">
              <table>
                <thead className="text-left">
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(
                        newVerifications,
                        setNewVerifications,
                        "createdDate"
                      )
                    }
                  >
                    Created Date
                  </th>
                  <th className="mr-4 pr-4">Marketplace</th>
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(newVerifications, setNewVerifications, "name")
                    }
                  >
                    Name
                  </th>
                  <th className="mr-4 pr-4">Category</th>
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(newVerifications, setNewVerifications, "brand")
                    }
                  >
                    Brand
                  </th>
                  <th
                    className="mr-4 pr-4"
                    onClick={(e) =>
                      sortTable(newVerifications, setNewVerifications, "model")
                    }
                  >
                    Model
                  </th>
                  <th className="mr-4 pr-4">Pictures</th>
                  <th className="mr-4 pr-4">Verifier</th>
                </thead>
                {newVerifications.map((item) => (
                  <tr className="border-b-[1px] border-vipGreen">
                    <td className="pr-5 py-2">{item.createdDate}</td>
                    <th className="mr-4 pr-4">Marketplace XX</th>
                    <td className="pr-5 py-2">{item.name}</td>
                    <td className="pr-5">{item.category}</td>
                    <td className="pr-5">{item.brand}</td>
                    <td className="pr-5">{item.model}</td>
                    <td className="pr-5">{item.files.length}</td>
                    <td className="pr-5">
                      {item.verifier ? (
                        <div>{item.verifier}</div>
                      ) : (
                        <div>
                          <div
                            className=" button-confirm"
                            onClick={() => {
                              setAssignmentMode(true);
                              setActiveAssignment(item.uuid);
                            }}
                          >
                            {" "}
                            Assign verifier{" "}
                          </div>
                        </div>
                      )}
                    </td>
                    <td className="pr-5">
                      <div
                        className="ml-2 button-confirm"
                        onClick={() => {
                          navigate("/items/" + item.uuid);
                        }}
                      >
                        {" "}
                        View{" "}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
          {assignmentMode && (
            <div className=" ml-7 mt-7 bg-white shadow w-fit rounded-xl p-5">
              <div className="font-bold text-xl pb-2">
                Verifiers to choose from
              </div>
              <div className="">
                <table>
                  <thead className="text-left">
                    <th className="mr-4 pr-4">Name</th>
                    <th className="mr-4 pr-4">Expertise</th>

                    <th className="mr-4 pr-4">Select</th>
                  </thead>
                  {allVerifiers.map((item) => (
                    <tr className="border-b-[1px] border-vipGreen">
                      <td className="pr-5 py-2">{item.firstName}</td>
                      <td className="pr-5">TBD</td>
                      <td className="pr-5">
                        <button
                          onClick={() =>
                            assignItemToVerifier(item._id, activeAssignment)
                          }
                        >
                          {" "}
                          Assign{" "}
                        </button>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          )}
        </div>
      )}

      {selectedItem === 2 && (
        <div className="ml-7 mt-7 bg-white shadow w-fit rounded-xl p-5">
          <div className="font-bold text-xl pb-2">
            In Progress Verifications
          </div>
          <div className="">
            <table>
              <thead className="text-left">
                <th
                  className="mr-4 pr-4"
                  onClick={(e) =>
                    sortTable(
                      inprogressVerifications,
                      setInprogressVerifications,
                      "createdDate"
                    )
                  }
                >
                  Created Date
                </th>
                <th className="mr-4 pr-4">Client</th>
                <th className="mr-4 pr-4" onClick={e => sortTable(inprogressVerifications, setInprogressVerifications, 'dateOfLastUpdate')}>Assigned Date</th>
                <th className="mr-4 pr-4" onClick={e => sortTable(inprogressVerifications, setInprogressVerifications, 'name')}>Name</th>
                <th className="mr-4 pr-4" onClick={e => sortTable(inprogressVerifications, setInprogressVerifications, 'category')}>Category</th>
                <th className="mr-4 pr-4" onClick={e => sortTable(inprogressVerifications, setInprogressVerifications, 'brand')}>Brand</th>
                <th className="mr-4 pr-4" onClick={e => sortTable(inprogressVerifications, setInprogressVerifications, 'model')}>Model</th>
                <th className="mr-4 pr-4">Verifier One</th>
                <th className="mr-4 pr-4">Verifier Two</th>
              </thead>
              {inprogressVerifications.map((item) => (
                <tr className="border-b-[1px] border-vipGreen">
                  <td className="pr-5 py-2">{item.createdDate}</td>
                  <td className="pr-5 py-2">Marketplace X</td>
                  <td className="pr-5 py-2">{item.dateOfLastUpdate}</td>
                  <td className="pr-5 py-2">{item.name}</td>
                  <td className="pr-5">{item.category}</td>
                  <td className="pr-5">{item.brand}</td>
                  <td className="pr-5">{item.model}</td>
                  {/*
                    <td className="pr-5">
                      {item.users.length > 0 && item.users[0].firstName}
                      { ` `}
                      {item.users.length > 0 && item.users[0].lastName}
                    </td>
                  */}
                  {item.users.length === 0 && (
                    <>
                      <td></td>
                      <td></td>
                    </>
                  )}
                  {item.users.length > 0 &&
                    item.users.map((x) => {
                      return (
                        <td>
                          {x.firstName} {x.lastName}{" "}
                        </td>
                      );
                    })}
                  {item.users.length === 1 && (
                    <td>
                      {item.users[0].firstName} {item.users[0].lastName}
                    </td>
                  )}
                  <td>
                    <div
                      className="ml-2 button-confirm"
                      onClick={() => {
                        navigate("/items/" + item.uuid);
                      }}
                    >
                      {" "}
                      View{" "}
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      )}

      {selectedItem === 3 && (
        <div className="ml-7 mt-7 bg-white shadow w-fit rounded-xl p-5">
          <div className="font-bold text-xl pb-2">Completed Verifications</div>
          <div className="">
            <table>
              <thead className="text-left">
                <th
                  className="mr-4 pr-4"
                  onClick={(e) =>
                    sortTable(
                      completedVerifications,
                      setCompletedVerifications,
                      "mcreatedDateodel"
                    )
                  }
                >
                  Created Date
                </th>
                <th className="mr-4 pr-4">Client</th>
                <th className="mr-4 pr-4" onClick={e => sortTable(completedVerifications, setCompletedVerifications, 'dateOfLastUpdate')}>Assigned Date</th>
                <th className="mr-4 pr-4" onClick={e => sortTable(completedVerifications, setCompletedVerifications, 'name')}>Name</th>
                <th className="mr-4 pr-4" onClick={e => sortTable(completedVerifications, setCompletedVerifications, 'category')}>Category</th>
                <th className="mr-4 pr-4" onClick={e => sortTable(completedVerifications, setCompletedVerifications, 'brand')}>Brand</th>
                <th className="mr-4 pr-4" onClick={e => sortTable(completedVerifications, setCompletedVerifications, 'model')}>Model</th>
                <th className="mr-4 pr-4">Verifier</th>
                <th className="mr-4 pr-4">Verdict</th>
              </thead>
              {completedVerifications.map((item) => (
                <tr className="border-b-[1px] border-vipGreen">
                  <td className="pr-5 py-2">{item.createdDate}</td>
                  <td className="pr-5 py-2">{"Marketplace X"}</td>
                  <td className="pr-5 py-2">{item.dateOfLastUpdate}</td>
                  <td className="pr-5 py-2">{item.name}</td>
                  <td className="pr-5">{item.category}</td>
                  <td className="pr-5">{item.brand}</td>
                  <td className="pr-5">{item.model}</td>
                  {item.users.length > 0 &&
                    item.users.map((x) => (
                      <td className="pr-5">
                        {x.firstName} {x.lastName}{" "}
                      </td>
                    ))}

                  <td>{item.verdict ? "Authentic" : "Fake"}</td>

                  <td>
                    <div
                      className="ml-2 button-confirm"
                      onClick={() => {
                        navigate("/items/" + item.uuid);
                      }}
                    >
                      {" "}
                      View{" "}
                    </div>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
      )}
      {selectedItem === 4 && (
        <div className="grid grid-cols-2">
          <div className=" ml-7 mt-7 bg-white shadow w-fit rounded-xl p-5">
            <div className="font-bold text-xl pb-2">
              Verfications requesting second opinion
            </div>
            <div className="">
              <table>
                <thead className="text-left">
                  <th className="mr-4 pr-4" onClick={e => sortTable(secondOpinionRequestedVerifications, setSecondOpinionRequestedVerifications, 'dateOfLastUpdate')}>Date</th>
                  <th className="mr-4 pr-4" onClick={e => sortTable(secondOpinionRequestedVerifications, setSecondOpinionRequestedVerifications, 'name')}>Name</th>
                  <th className="mr-4 pr-4" onClick={e => sortTable(secondOpinionRequestedVerifications, setSecondOpinionRequestedVerifications, 'category')}>Category</th>
                  <th className="mr-4 pr-4" onClick={e => sortTable(secondOpinionRequestedVerifications, setSecondOpinionRequestedVerifications, 'brand')}>Brand</th>
                  <th className="mr-4 pr-4" onClick={e => sortTable(secondOpinionRequestedVerifications, setSecondOpinionRequestedVerifications, 'model')}>Model</th>
                  <th className="mr-4 pr-4">Pictures</th>
                  <th className="mr-4 pr-4">Verifier Name</th>
                </thead>
                {secondOpinionRequestedVerifications.map((item) => (
                  <tr className="border-b-[1px] border-vipGreen">
                    <td className="pr-5 py-2">{item.dateOfLastUpdate}</td>
                    <td className="pr-5 py-2">{item.name}</td>
                    <td className="pr-5">{item.category}</td>
                    <td className="pr-5">{item.brand}</td>
                    <td className="pr-5">{item.model}</td>
                    <td className="pr-5">{item.files.length}</td>
                    {item.users.length > 0 &&
                      item.users.map((x) => (
                        <td className="pr-5">
                          {x.firstName} {x.lastName}{" "}
                        </td>
                      ))}
                    <td className="pr-5">
                      <div>
                        <div
                          className=" button-confirm"
                          onClick={() => {
                            setAssignmentMode(true);
                            setActiveAssignment(item.uuid);
                          }}
                        >
                          {" "}
                          Assign verifier{" "}
                        </div>
                      </div>
                    </td>
                    <td className="pr-5">
                      <div
                        className="ml-2 button-confirm"
                        onClick={() => {
                          navigate("/items/" + item.uuid);
                        }}
                      >
                        {" "}
                        View{" "}
                      </div>
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          </div>
          {assignmentMode && (
            <div className=" ml-7 mt-7 bg-white shadow w-fit rounded-xl p-5">
              <div className="font-bold text-xl pb-2">
                Verifiers to choose from
              </div>
              <div className="">
                <table>
                  <thead className="text-left">
                    <th className="mr-4 pr-4">Name</th>
                    <th className="mr-4 pr-4">Expertise</th>

                    <th className="mr-4 pr-4">Select</th>
                  </thead>
                  {allVerifiers.map((item) => (
                    <tr className="border-b-[1px] border-vipGreen">
                      <td className="pr-5 py-2">{item.firstName}</td>
                      <td className="pr-5">TBD</td>
                      <td className="pr-5">
                        <button
                          onClick={() =>
                            assignItemToVerifier(item._id, activeAssignment)
                          }
                        >
                          {" "}
                          Assign{" "}
                        </button>
                      </td>
                    </tr>
                  ))}
                </table>
              </div>
            </div>
          )}
        </div>
      )}
      {selectedItem === 5 && (
        <div className="ml-7 mt-7 bg-white shadow w-fit rounded-xl p-5">
          <div className="font-bold text-xl pb-2">Send invite</div>
          <div className="">
            <table>
              <thead className="text-left">
                <th className="mr-4 pr-4">First Name</th>
                <th className="mr-4 pr-4">Email</th>
              </thead>

              <tr className="border-b-[1px] border-vipGreen">
                <td className="pr-5 py-2">
                  <input
                    placeholder="first name"
                    className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                    onChange={(e) => setInviteeName(e.target.value)}
                  />
                </td>
                <td className="pr-5">
                  <input
                    placeholder="email"
                    className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                    onChange={(e) => setInviteeEmail(e.target.value)}
                  />
                </td>
                <td>
                  <div
                    className="ml-2 button-confirm"
                    onClick={() => inviteVerifier()}
                  >
                    {" "}
                    Invite{" "}
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>
      )}

      {selectedItem === 6 && (
        <div className="ml-7 mt-7 bg-white shadow w-fit rounded-xl p-5 ">
          <div className="font-bold text-xl pb-2">Pending Transactions</div>
          <div className="">
            <table>
              <thead className="text-left">
                <th className="mr-4 pr-4">Amount</th>
                <th className="mr-4 pr-4">Txn Id</th>

                <th className="mr-4 pr-4">Date</th>
                <th className="mr-4 pr-4">Request</th>
                <th className="mr-4 pr-4">Decision</th>
              </thead>
              {pendingPayments.length != 0 &&
                pendingPayments.map((item) => (
                  <tr className="border-b-[1px] border-vipGreen">
                    <td className="pr-5 py-2">2.0</td>
                    <td className="pr-5">{item.verificationId}</td>
                    <td className="pr-5">{item.date}</td>

                    <td className="pr-5">
                      <div
                        className="button-confirm"
                        onClick={() =>
                          window.open(`/items/${item.verificationId}`)
                        }
                      >
                        View
                      </div>
                    </td>
                    <td className="pr-5">
                      <div className="flex">
                        <div className="w-fit m-auto text-vipGreen cursor-pointer">
                          <CheckIcon
                            width={20}
                            onClick={() => reviewPayment(item._id, "approved")}
                          />
                        </div>
                        <div className="w-fit m-auto text-red-500 cursor-pointer">
                          <XIcon
                            width={20}
                            onClick={() => reviewPayment(item._id, "rejected")}
                          />
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
            </table>
          </div>
        </div>
      )}

      {selectedItem === 7 && (
        <div className="ml-7 mt-7 bg-white shadow w-fit rounded-xl p-5 ">
          <div className="font-bold text-xl pb-2">
            Approved Transactions - You can download transactions for given
            period
          </div>
          <div className="grid grid-cols-4 mt-4">
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
            />
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
            />
            {csvData && (
              <CSVLink data={csvData}>
                <div className="button-confirm mt-4">Download CSV Data </div>
              </CSVLink>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

{
  /* <div className="text-default flex ">
        <div className="font-light w-1/3 ml-5">
          <div className="text-huge font-light pt-10 border-b-2 pr-2 border-mainColor">
            Welcome back, Rene
          </div>
          <div className="text-header pt-6 pr-4">
            There are 9 verifications waiting for you
          </div>
          <div className="text-default hover:underline cursor-pointer w-fit pr-4 text-gray-400 hover:text-gray-700">
            go to tasks
          </div>

          <div className="text-header pt-6 pr-4">
            You have 5 pending reviews
          </div>
          <div className="text-default hover:underline cursor-pointer w-fit pr-4 text-gray-400 hover:text-gray-700">
            go to history
          </div>
        </div>
        <div className="w-1/3">
          <ContentCard
            header="This is the first header"
            footer="go to content"
            url="/content"
          >
            <div className="h-full center-items">
              This is the body
            </div>
          </ContentCard>
          <ContentCard
            header="This is the second header"
            footer="go to content"
            url="/content"
          >
            <div>
              This is the second body
            </div>
          </ContentCard>
        </div>
        <div className="w-1/3">
          <ContentCard
            header="This is the third header"
            footer="go to content"
            url="/content"
          >
            <div className="">
              This is the body
            </div>
          </ContentCard>
          <ContentCard
            header="This is the fourth header"
            footer="go to content"
            url="/content"
          >
            <div className="">
              This is the body
            </div>
          </ContentCard>
        </div>
      </div>  */
}
