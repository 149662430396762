
import axios from 'axios'

const ReviewPaymentMutation = `
mutation ReviewPayment ($status: String, $_id: String) {
    ReviewPayment (status: $status,  _id: $_id)
}`

const ReviewPayment = async (data, cb) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: ReviewPaymentMutation,
      variables: {
        ...data
      }
    }
  })
  return res.data.data.ReviewPayment
}

export {
  ReviewPayment
}
