'use strict';

var handleSize = 12;
var opacity = '80%'
var style = {

	Region: {
		position: 'absolute',
		//border: '2px dashed rgba(0,0,0,0.5)',
		boxShadow: '0 0 0 4px red',
		outline: 'dashed 4px blue',
		opacity: '90%',
		cursor: 'move'
	},
  
  	RegionSelected: {
		position: 'absolute',
		border: '4px dashed green',
		//outline: '1px dashed rgba(255,255,255,0.5)',
		cursor: 'move'
	},

	RegionHandleSE: {
		position: 'absolute',
		bottom: -1 * handleSize / 2,
		right: -1 * handleSize / 2,
		width: handleSize,
		height: handleSize,
		outline: '1px solid rgba(0,0,0,0.5)',
		border: '2px solid rgba(255,255,255,0.5)',
		cursor: 'se-resize',
		opacity: opacity,
	},
  
	RegionHandleSW: {
		position: 'absolute',
		bottom: -1 * handleSize / 2,
		left: -1 * handleSize / 2,
		width: handleSize,
		height: handleSize,
		outline: '1px solid rgba(0,0,0,0.5)',
		border: '2px solid rgba(255,255,255,0.5)',
		cursor: 'sw-resize',
		opacity: opacity,
	},
	RegionHandleNW: {
		position: 'absolute',
		top: -1 * handleSize / 2,
		left: -1 * handleSize / 2,
		width: handleSize,
		height: handleSize,
		outline: '1px solid rgba(0,0,0,0.5)',
		border: '2px solid rgba(255,255,255,0.5)',
		cursor: 'nw-resize',
		opacity: opacity,
	},
	RegionHandleNE: {
		position: 'absolute',
		top: -1 * handleSize / 2,
		right: -1 * handleSize / 2,
		width: handleSize,
		height: handleSize,
		outline: '1px solid rgba(0,0,0,0.5)',
		border: '2px solid rgba(255,255,255,0.5)',
		cursor: 'ne-resize',
		opacity: opacity,
	},

	RegionSelect: {
		position: 'relative', 
		display: 'inline-block'
	}
};

module.exports = style;