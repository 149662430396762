import { CalendarOverview } from "assets/components/calendar/calendar-overview";
import { ContentCard } from "assets/components/content-card/ContentCard";
import { GridCardHome } from "assets/components/grid-cards/GridCardHome";
import React, {useState, useEffect} from "react";

export const MyPayments = (props) => {
  let [homeCards, setHomeCards] = useState([])
  const [selectedItem, setSelectedItem] = useState(0)

  let paymentCards = [
    {
      name: "Pending Payments",
    },
    {
      name: "Approved Payments",
    }
  ]

let pendingPayments = [{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364dc99375350099fb95bb5"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364dcfa375350099fb95bc0"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364dd0c375350099fb95bcb"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364ddf5375350099fb95be6"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364df5a81b7a809e62883de"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364e01a81b7a809e62883f3"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364e08081b7a809e6288406"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364e0e181b7a809e6288419"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-14","status":"pending","_id":"63720d4cce02da8bc2f683b6"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-14","status":"pending","_id":"63721285e923c98e11d149d3"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-14","status":"pending","_id":"63721b5cf58f608e5b4f89e5"}]

let approvedPayments = [{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364dc99375350099fb95bb5"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364dcfa375350099fb95bc0"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364dd0c375350099fb95bcb"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364ddf5375350099fb95be6"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364df5a81b7a809e62883de"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364e01a81b7a809e62883f3"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364e08081b7a809e6288406"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-04","status":"pending","_id":"6364e0e181b7a809e6288419"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-14","status":"pending","_id":"63720d4cce02da8bc2f683b6"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-14","status":"pending","_id":"63721285e923c98e11d149d3"},{"verificationId":"7747a48d-1ab6-4583-adc4-eceda6a60110","date":"2022-11-14","status":"pending","_id":"63721b5cf58f608e5b4f89e5"}]

  return(
    <div className=" ">

      <div className="grid grid-cols-5 px-3">
        {paymentCards.map((item, index) => (
          <div
            className="bg-vipGreen aspect-video m-4 rounded-xl center-items hover:shadow-xl cursor-pointer animate hover:opacity-90 bg-cover overflow-hidden text-white"
            onClick={()=>{
              if(selectedItem === index) setSelectedItem(-1)
              else setSelectedItem(index)

            }}
          >
            <div className="h-full w-full bg-vipGreen hover:opacity-40 opacity-80 animate center-items">
            </div>
            <div className="absolute text-xl ">
              {item.name}
            </div>
          </div>
        ))}
      </div>
      {selectedItem === 0 &&
        <div className="ml-7 mt-7 bg-white shadow w-fit rounded-xl p-5 ">
          <div className="font-bold text-xl pb-2">
            Pending Transactions
          </div>
          <div className="">
            <table>
              <thead className="text-left">
              <th className="mr-4 pr-4">
                  Amount
                </th>
                <th className="mr-4 pr-4">
                  Txn Id
                </th>

                <th className="mr-4 pr-4">
                  Date
                </th>
                <th className="mr-4 pr-4">
                  Request
                </th>

              </thead>
              { pendingPayments.length != 0 && pendingPayments.map((item) => (
                <tr className="border-b-[1px] border-vipGreen">
                  <td className="pr-5 py-2">
                  2.0
                  </td>
                  <td className="pr-5">
                    {item.verificationId}
                  </td>
                  <td className="pr-5">
                  {item.date}
                  </td>

                  <td className="pr-5">
                    <div className='button-confirm' onClick={() => window.open(`/items/${item.verificationId}`)}>View</div>
                  </td>

                </tr>
              ))}
            </table>
          </div>
        </div>}
        {selectedItem === 1 &&
          <div className="ml-7 mt-7 bg-white shadow w-fit rounded-xl p-5 ">
            <div className="font-bold text-xl pb-2">
              Approved Payments
            </div>
            <div className="">
              <table>
                <thead className="text-left">
                <th className="mr-4 pr-4">
                    Amount
                  </th>
                  <th className="mr-4 pr-4">
                    Txn Id
                  </th>

                  <th className="mr-4 pr-4">
                    Date
                  </th>
                  <th className="mr-4 pr-4">
                    Request
                  </th>

                </thead>
                { approvedPayments.length != 0 && approvedPayments.map((item) => (
                  <tr className="border-b-[1px] border-vipGreen">
                    <td className="pr-5 py-2">
                    2.0
                    </td>
                    <td className="pr-5">
                      {item.verificationId}
                    </td>
                    <td className="pr-5">
                    {item.date}
                    </td>

                    <td className="pr-5">
                      <div className='button-confirm' onClick={() => window.open(`/items/${item.verificationId}`)}>View</div>
                    </td>

                  </tr>
                ))}
              </table>
            </div>
          </div>}
    </div>
  )
}
