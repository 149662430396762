import React, { useState } from "react";
import { auth } from '../../firebase'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import FacebookIcon from "assets/img/card-images/facebook-icon";
import GoogleIcon from "assets/img/card-images/google-icon";
import LinkedinIcon from "assets/img/card-images/linkedin-icon";
import { EyeIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import vipLogo from "assets/files/images/vip-logo-mock.png"

export const Login = () => {

  const [showPass, setShowPass] = useState(false)
  const navigate = useNavigate()
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  // let email = 'dikshya.parajuli+1@gmail.com'
  // let password = 'password1234'

  const handleSignUp = () => {
      createUserWithEmailAndPassword(auth, email, password)
      .then(userCredentials => {
        const user = userCredentials.user;
        console.log('Registered with:', user.email);
        // After this is successful make the API call to add user in the DB
      })
      .catch(error => alert(error.message))
  }

  const handleLogin = () => {
      signInWithEmailAndPassword(auth, email, password)
      .then(userCredentials => {
        const user = userCredentials.user;
        const accessToken = user.accessToken;
        localStorage.setItem('token', accessToken);
        navigate('/home')
      })
      .catch(error => alert(error.message))
  }

  return(
    <div className="m-0 pt-4 flex flex-col ">
      <div className="w-fit m-auto">
        <img
          className="scale-[80%]"
          src={vipLogo}
        />
      </div>
      <div className="max-w-2xl m-auto text-center flex-1">
        <div className="text-2xl font-semibold py-4 ">
          Login to your account
        </div>
        {/* <div className="py-4">
          Login using social networks
        </div>
        <div className="flex text-center w-fit space-x-4 m-auto">
          <div className="">
            <FacebookIcon className="hover:scale-105 animate cursor-pointer" width={30}/>
          </div>
          <div>
            <GoogleIcon className="hover:scale-105 animate cursor-pointer" width={30} />
          </div>
          <div>
            <LinkedinIcon className="hover:scale-105 animate cursor-pointer" width={30} />
          </div>
        </div>
        <div className="flex items-center w-fit m-auto py-4">
          <div className="mx-2 border-b-2 border-vipGreen w-[100px] pt-[2px]"/>
          <div className="font-bold px-3">
            Or
          </div>
          <div className="mx-2 border-b-2 border-vipGreen w-[100px] pt-[2px]"/>
        </div> */}
        <div className="w-fit m-auto text-left py-1">
          <div className="py-1 text-sm">
            E-mail
          </div>
          <div className="">
            <input
              placeholder="Email address"
              type="email"
              className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="w-fit m-auto text-left py-1">
          <div className="py-1 text-sm">
            Password
          </div>
          <div className="relative">
            <div className="text-gray-300 hover:text-gray-600 animate absolute z-10 top-[5px] right-2 cursor-pointer select-none"
              onClick={(e)=> setShowPass(!showPass) }
            >
              <EyeIcon width={20}/>
            </div>
            <input
              placeholder="Password"
              type={`${showPass ? "text" : "password"}`}
              className="relative md:w-[240px] bg-white pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="button-confirm w-fit m-auto my-8" onClick={handleLogin}>
          Sign in
        </div>
      </div>

      <div className="bg-vipGreen w-full text-white text-center py-14 pb-24">
        <div className="text-2xl py-4">
          New here?
        </div>
        <div className="text-lg py-4">
          Sign up and discover a great amount of new opportunities!
        </div>
        <div className="button-reverse w-fit m-auto my-8" onClick={()=>navigate('/signup')}>
          Create Account
        </div>
      </div>

      {/* <div>
        <button onClick={() => handleSignUp()}> Sign Up </button>
      </div>
      <div>
          <button onClick={() => handleLogin()}> Login </button>
      </div> */}
    </div>
  )
}
