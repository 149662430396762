import React from 'react';
import objectAssign from 'object-assign';
import { Region } from './Region';
import style from './style';
import { useState } from 'react';
import { useRef } from 'react'; 

export const RegionSelect = ( props ) => {  
  const reference = useRef(null)  
  const [clickOffset, setClickOffset] = useState({x: null, y: null})
  const [regionChangeData, setRegionChangeData] = useState({})
  const [createRegionStart, setCreateRegionStart] = useState({x:null, y:null})

  const [createRegionObject, setCreateRegionObject] = useState(
    {
      x:null, 
      y:null, 
      width:null, 
      height: null,
      comment:null,
      data:{
        comment: "",
        dropdown: 0, 
        selected: false 
      }
    })


	const getClientPos = (e) => {
 		let pageX, pageY; 
		if (e.touches) {
			pageX = e.touches[0].pageX;
			pageY = e.touches[0].pageY;
		} else {
			pageX = e.pageX;
			pageY = e.pageY;
		} 

		return {
			x: pageX,
			y: pageY
		};
	} 
 
	const onMouseMove = (event, input) => {    

    if (!props.mouseDown) {  
			return;
		} 
     
    let mousePos = getClientPos(event) 

    if(props.selectedRegion > -1 && props.clickedHandle === "" && !props.createRegion){   
      let newX, newY, worldX, worldY, mouseX, mouseY 

      worldX = props.regions[props.selectedRegion].x * (regionChangeData.imageWidth/100)
      worldY = props.regions[props.selectedRegion].y * (regionChangeData.imageHeight/100) 
      
      mouseX = mousePos.x - regionChangeData.imageOffsetLeft
      mouseY = mousePos.y - regionChangeData.imageOffsetTop 

      newX = 100 / (regionChangeData.imageWidth / ( mouseX  )) 
      newY = 100 / (regionChangeData.imageHeight / ( mouseY )) 
      
      const getXValue = (input, width) => {
        if (input < 0) return 0
        else if ((input + width) >= 100) return 100 - width
        else return input
      }

      const getYValue = (input, height) => {
        if (input < 0) return 0
        else if ((input + height) >= 100) return 102 - height
        else return input
      }

      let objArr = props.regions
      if(!isNaN(newX - clickOffset.x)) objArr[props.selectedRegion].x = getXValue(newX - clickOffset.x, objArr[props.selectedRegion].width) 
      if(!isNaN(newY - clickOffset.y)) objArr[props.selectedRegion].y = getYValue(newY - clickOffset.y, objArr[props.selectedRegion].height)
      props.setRegions([...objArr])
    }

    if(props.clickedHandle === "SE"){
      let nwX, nwY, neX, neY, diffX, diffXPercent, diffY, diffYPercent

      nwX = props.regions[props.selectedRegion].x * regionChangeData.imageWidth/100 + regionChangeData.imageOffsetLeft
      neX = mousePos.x
      diffX = neX - nwX
      diffXPercent = (diffX * 100) / regionChangeData.imageWidth

      nwY = props.regions[props.selectedRegion].y * regionChangeData.imageHeight/100 + regionChangeData.imageOffsetTop
      neY = mousePos.y
      diffY = neY - nwY
      diffYPercent = ((neY - nwY) * 100) / regionChangeData.imageHeight

      let objArr = props.regions
      objArr[props.selectedRegion].width = diffXPercent
      objArr[props.selectedRegion].height = diffYPercent
      props.setRegions([...objArr])
    }

    if(props.clickedHandle === "NW"){
      let newX, newY, newW, newH, currentSeX, currentSeY

      currentSeX = props.regions[props.selectedRegion].width + props.regions[props.selectedRegion].x
      currentSeY = props.regions[props.selectedRegion].height + props.regions[props.selectedRegion].y

      newX = (mousePos.x - regionChangeData.imageOffsetLeft) * 100 / regionChangeData.imageWidth
      newY = (mousePos.y - regionChangeData.imageOffsetTop) * 100 / regionChangeData.imageHeight
      
      newW = currentSeX - newX 
      newH = currentSeY - newY

      let objArr = props.regions
      objArr[props.selectedRegion].width = newW
      objArr[props.selectedRegion].height = newH
      objArr[props.selectedRegion].x = newX
      objArr[props.selectedRegion].y = newY
      props.setRegions([...objArr])
    }

    if(props.clickedHandle === "NE"){
      let mouseX, mouseY, newY, newW, newH, currentNwX, currentSeY, currentSeX

      currentNwX = props.regions[props.selectedRegion].x
      currentSeX = props.regions[props.selectedRegion].width + props.regions[props.selectedRegion].x
      currentSeY = props.regions[props.selectedRegion].height + props.regions[props.selectedRegion].y

      mouseX = (mousePos.x - regionChangeData.imageOffsetLeft) * 100 / regionChangeData.imageWidth
      mouseY = (mousePos.y - regionChangeData.imageOffsetTop) * 100 / regionChangeData.imageHeight

      newY = mouseY
      
      newH = currentSeY - mouseY
      newW = mouseX - currentNwX
      
      let objArr = props.regions
      objArr[props.selectedRegion].width = newW
      objArr[props.selectedRegion].height = newH
      objArr[props.selectedRegion].y = newY
      props.setRegions([...objArr]) 
    }

    if(props.clickedHandle === "SW"){
      let mouseX, mouseY, newW, newH, currentNwX, currentNwY, currentSeY, currentSeX

      currentNwX = props.regions[props.selectedRegion].x
      currentNwY = props.regions[props.selectedRegion].y
      currentSeX = props.regions[props.selectedRegion].width + props.regions[props.selectedRegion].x
      currentSeY = props.regions[props.selectedRegion].height + props.regions[props.selectedRegion].y

      mouseX = (mousePos.x - regionChangeData.imageOffsetLeft) * 100 / regionChangeData.imageWidth
      mouseY = (mousePos.y - regionChangeData.imageOffsetTop) * 100 / regionChangeData.imageHeight
      
      newH = mouseY - currentNwY
      newW = currentSeX - mouseX 

      let objArr = props.regions
      objArr[props.selectedRegion].width = newW
      objArr[props.selectedRegion].height = newH
      objArr[props.selectedRegion].x = mouseX
      props.setRegions([...objArr]) 
    }

    if(props.createRegion){
      
      let mouseX = (mousePos.x - regionChangeData.imageOffsetLeft) / reference.current.offsetWidth * 100
      let mouseY = (mousePos.y - regionChangeData.imageOffsetTop) / reference.current.offsetHeight * 100

 
      let xVals = [createRegionStart.x, mouseX]
      let yVals = [createRegionStart.y, mouseY]

      let lowestX, lowestY, highestX, highestY, width, height

      lowestX = Math.min(...xVals)
      lowestY = Math.min(...yVals)
      highestX = Math.max(...xVals)
      highestY = Math.max(...yVals)

      width = highestX - lowestX
      height = highestY - lowestY

      let _regions = props.regions  
      _regions[props.selectedRegion].x = lowestX 
      _regions[props.selectedRegion].y = lowestY 
      _regions[props.selectedRegion].width = width 
      _regions[props.selectedRegion].height = height  

      props.setRegions([..._regions])
    } 
	}
 

  const onMouseUp = (input) => {  
    props.setCreateRegion(false)
    props.setMouseDown(false)  

    if(props.mouseDown){ 
      props.setMouseDown(false)  
    } 

    let newRegionStartObj = {
      x: null,
      y: null
    }

    setCreateRegionStart({...newRegionStartObj})
  } 

	const onMouseDown = (event) => {  
 
    let mousePos = getClientPos(event) 

    const imageOffset = getElementOffset(reference)  

    let _regionChangeData = {
      imageOffsetLeft: imageOffset.left,
      imageOffsetTop: imageOffset.top,
      clientPosXStart: mousePos.x,
      clientPosYStart: mousePos.y,
      imageWidth: reference.current.offsetWidth,
      imageHeight: reference.current.offsetHeight,
      isMove: false
    }  

    setRegionChangeData({..._regionChangeData})

    if(!event.target.dataset.wrapper) props.setSelectedRegion(-1)
    
    if(!props.createRegion && event.target.dataset.wrapper > -1){  //move region


      let mouseX = mousePos.x - regionChangeData.imageOffsetLeft
      let mouseY = mousePos.y - regionChangeData.imageOffsetTop


      let newX = 100 / (regionChangeData.imageWidth / ( mouseX )) 
      let newY = 100 / (regionChangeData.imageHeight / ( mouseY )) 

      let _regionPos = {
        x: props.regions[event.target.dataset.wrapper].x,
        y: props.regions[event.target.dataset.wrapper].y
      } 
      
      let _offSet = {
        x: newX-_regionPos.x,
        y: newY-_regionPos.y
      }

      setClickOffset({..._offSet})
    }
    
    if(!props.mouseDown){
      props.setMouseDown(true) 

      if (event.target.dataset.wrapper) {  
        props.setSelectedRegion(event.target.dataset.wrapper)
        return;
      }     

      if(props.createRegion){   
        console.log('creating region')

        let newRegionStartObj = {
          x: (mousePos.x - imageOffset.left) / reference.current.offsetWidth * 100,
          y: (mousePos.y - imageOffset.top) / reference.current.offsetHeight * 100
        } 

        setCreateRegionStart({...newRegionStartObj})

        let _regions = props.regions 
        let obj = {...createRegionObject}
        obj.x = newRegionStartObj.x
        obj.y = newRegionStartObj.y
        obj.width = 3
        obj.height = 3 
        _regions.push(obj) 
        props.setRegions([..._regions]) 
        props.setSelectedRegion(_regions.length-1) 

      }

      else props.setSelectedRegion(-1) 
    } 
  }
 
	const getElementOffset = (el) => {
		const rect = reference.current.getBoundingClientRect();
		const docEl = document.documentElement;

		const rectTop = rect.top + window.pageYOffset - docEl.clientTop;
		const rectLeft = rect.left + window.pageXOffset - docEl.clientLeft;

		return {
			top: rectTop,
			left: rectLeft
		};
	} 

  const getLabel = (index) => {
    var label = ""
    var region 
    region = props.regions[props.selectedRegion]
    if(region){
      label = region.label
    } 
    return label
  }
	
  const renderRect = (rect, index) => { 
		return (
      <Region 
        clickedHandle={props.clickedHandle}
        setClickedHandle={props.setClickedHandle}
        selectedRegion={props.selectedRegion}
        setSelectedRegion={props.setSelectedRegion}
        wrapper={index}
        regions={props.regions}
        x={rect.x}
        y={rect.y}
        width={rect.width}
        height={rect.height}
        handles={props.selectedRegion !== -1}
        data={rect.data}
        key={index}
        index={index} 
        dataRenderer={props.regionRenderer} 
        label={getLabel(index)} 
        //data[questionIndex].files.find(item => item.filename === selectedImage.filename).regionData[selectedRegion][handle]
      />
    ) 
	} 
  
  return (
    <div
      ref={reference}
      style={objectAssign({}, style.RegionSelect, props.style)}
      className={props.className}
      //onTouchStart={onMouseDown}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove} 
    > 
      {props.regions.map((item, index) => ( 
        renderRect(item, index)  
      ))} 

      {props.debug &&
        <table className='bg-white' style={{position:'absolute', right: 0, top: 0}}>
          <tbody>
            {props.regions.map((region, index) => {
              return (
                <tr key={index}>
                  <td>x: {Math.round(region.x, 1)}</td>
                  <td>y: {Math.round(region.y, 1)}</td>
                  <td>width: {Math.round(region.width, 1)}</td>
                  <td>height: {Math.round(region.height, 1)}</td>
                  <td>selected: {JSON.stringify(region.selected)}</td>
                  <td>region: {JSON.stringify(props.selectedRegion)}</td>
                  <td>index: {JSON.stringify(index)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      } 
      <div className='select-none'>
        {props.children}
      </div>  
    </div>
  ); 
} 