import axios from "axios";

const GetTestQuery = `
query GetTests{
  GetTests {
    category
    uuid
    name
    brand
    model
    description
    files
    verdict
  }
}`;

const GetTests = async (data, token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: GetTestQuery,
      variables: {
        ...data,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data.GetTests;
};

export { GetTests };
