// Import the functions you need from the SDKs you need
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import React, { useEffect, useState } from 'react'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDnyCQuj3eLPQxnGOkaCC-Gw01JHSQIAVk",
  authDomain: "vipficated-5e36a.firebaseapp.com",
  projectId: "vipficated-5e36a",
  storageBucket: "vipficated-5e36a.appspot.com",
  messagingSenderId: "136812495623",
  appId: "1:136812495623:web:8f110960e9fc6d4a743ba2",
  measurementId: "G-9DLPQDB9KZ"
};

initializeApp(firebaseConfig)

const auth = getAuth()


const getHeaders = async() => {
    const _auth = getAuth()
    const _user = _auth.currentUser
    console.log('Calling get headers')
    console.log('_user', _user)
    if (_user) {
     let token = await _user.getIdToken(true)
      return ({
        headers:
          {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
      })
    }
    else {
      return({})
    }
  }


const useAuth = () => {
  const [authUser, setAuthUser] = useState()
  const user = auth.currentUser

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if(user) localStorage.setItem('token', user.accessToken)
      setAuthUser(user ?? false)
    })
    return unsubscribe
  }, [user])

  return authUser
}

const refreshToken = async() => {
  console.log('refreshing token...')
  console.log('auth:', auth)
  const _user = await auth.currentUser

  console.log('new _user:', _user)

  if(!_user) {
    localStorage.setItem('token', '');
    return true
  }

  console.log('user was actually defined')
  const accessToken = _user.accessToken;
  console.log('new token:', accessToken)
  localStorage.setItem('token', accessToken);
  return false
}

const isApprovedVerifier = async(companyId) => {
  return auth.currentUser.getIdTokenResult()
    .then((idTokenResult) => {
       if (idTokenResult.claims?.approvedVerifier === true ) {
         return 'approvedVerifier';
       } else if (idTokenResult.claims?.marketplace === true) {
         return 'marketplace';
       } else if (idTokenResult.claims?.admin === true) {
         return 'admin';
       } else if (idTokenResult.claims?.unapprovedVerifier === true) {
         return 'unapprovedVerifier';
       }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
}


export { auth, getHeaders, refreshToken, useAuth, isApprovedVerifier };
