import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useNavigate } from 'react-router-dom'

import vipLogo from './../../files/images/vip-logo-mock.png' //./../../files/images/vip-logo-mock.png

export const SubmitCompleteModal = ({ isOpen, setIsOpen }) => {
  const navigate = useNavigate()

  function closeModal(input) {
    //setIsOpen(false)
    if(input) navigate(input)
    else navigate('/new-verification')
  } 

  return(
    <>
      <Transition appear show={isOpen} as={Fragment}> 
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-vipGreen bg-opacity-[80%]" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[60vw] min-h-[60vh] transform overflow-hidden rounded-[25px] bg-[#fdfbff] p-6 text-left align-middle shadow-2xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-4xl font-bold leading-6 text-jigGreen pb-4 text-center"
                  > 
                    <div className='p-5'>  
                      <div className='font-bold text-center text-2xl text-black pb-8'>
                        Thank you!
                      </div> 
                      <div className='font-normal text-center text-xl text-black pb-2'>
                        Your item has been submitted.
                      </div>
                      <div className='w-fit m-auto py-12'>
                        <img 
                          src={vipLogo}
                        />
                      </div>
                         
                      <div className='flex w-full border-t-2 border-vipGreen pt-12 mt-12'> 
                        <div
                          className='button-cancel'
                          onClick={()=>closeModal('/one')}
                        >
                          Back to homepage
                        </div>  
                        <div 
                          className='button-confirm ml-auto '
                          onClick={()=>closeModal('/new-verification')}
                        >
                          New verification
                        </div> 
                      </div>    
                    </div> 
                  </Dialog.Title> 
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}