import { GridCardHome } from "assets/components/grid-cards/GridCardHome";
import React from "react";

import shoeImg from './../img/card-images/shoe-crop.png'
import watchImg from './../img/card-images/watch-crop.png'
import clothesImg from './../img/card-images/jacket-crop.png'
import bagsImg from './../img/card-images/bag-crop.png'
import jewelryImg from './../img/card-images/jewelry-crop.png'
import otherImg from './../img/card-images/other-crop.png'
import { useNavigate, useParams } from "react-router-dom";

export const VerifierTest = (props) => {
  const params = useParams()
  const navigate = useNavigate()
  let newVerificationCards = [
    {
      name: "Shoes",
      img: shoeImg
    },
    {
      name: "Clothes",
      img: clothesImg
    },
    {
      name: "Bags",
      img: bagsImg
    },
    {
      name: "Jewelry",
      img: jewelryImg
    },
    {
      name: "Other",
      img: otherImg
    },
  ]

  return(
    <div>
      <div className="text-xl font-semibold py-4 px-6 pt-12">
        Select a product category to start your test
      </div>
      <div className="grid grid-cols-3 px-3">
        {newVerificationCards.map((item) => (
          <div 
            className="bg-vipGreen aspect-video m-4 rounded-xl center-items hover:shadow-xl cursor-pointer animate hover:opacity-90 bg-cover overflow-hidden text-white" 
            onClick={()=>{ navigate(`/test/${item.name.toLowerCase()}`) }} 
            style={{
              backgroundImage: `url(${item.img})`
            }}
          >
            <div className="h-full w-full bg-vipGreen hover:opacity-40 opacity-80 animate center-items">
            </div> 
            <div className="absolute text-2xl ">
              {item.name} 
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}
