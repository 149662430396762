import { CalendarOverview } from "assets/components/calendar/calendar-overview";
import { ContentCard } from "assets/components/content-card/ContentCard";
import { GridCardHome } from "assets/components/grid-cards/GridCardHome";
import { PendingVerificationCard } from "assets/components/grid-cards/PendingVerificationCard";
import React, { useEffect, useState } from "react";
import { GetRequests } from "api/GetRequests.js";
import { getHeaders } from '../../firebase'

import imgOne from "./../img/card-images/bag-crop.png"
import imgTwo from "./../img/card-images/shoe-crop.png"

//Pending verifications for the verifier aka verifications assifned to me
export const PendingVerifications = (props) => {

  const [requests, setRequests] = useState([])
  const [status, setStatus] = useState('inProgress');
  const headers = localStorage.getItem('token')

  useEffect( () => {
    //localStorage.setItem('user', 'marketplace'
    console.log('headers', headers)
    GetRequests({status: status}, headers)
      .then((out) => {
        setRequests(out) 
      })
  }, [status])

    // TO DO: get this dynamically from logged in user
    //let user = 'marketplace'
    const user = localStorage.getItem('user');


    let statusLookup = {
      pendingReview: "Pending : Looking for verifiers",
      inProgress: "In Progress : Verifications that verifiers are currently working on",
      complete: "Complete : Verfications that have been completed"
    }


  return(
    <div className=" ">
    {
      user === 'marketplace' ?
      ( <>
        <div className="text-xl font-semibold py-4 px-6 select-none ">
       {statusLookup[status]}
      </div>
        <div className="grid grid-cols-8">
        <div className="button-confirm" onClick={() => setStatus('pendingReview')}>
          Pending
        </div>
      <div className="button-confirm" onClick={() => setStatus('inProgress')}>
        In Progress
      </div>
      </div>
    </> )
    : <div className="text-xl font-semibold py-4 px-6 select-none ">
      Pending verifications
      </div>
}


      <div className="w-full ">
        {requests && requests.map(item=> (
          <PendingVerificationCard
            item={item}
          />
        ))}
      </div>
    </div>
  )
}
