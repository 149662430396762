import React from "react";

export const ProgressDots = (props) => {

  let statusLookup = {
    pendingReview: "Looking for Verifier",
    inProgress: "Verification in progress",
    complete: "Verification complete"
  }

  let verdictLookup = {
    1: ": Fake Product",
    2: ": Authentic Product",
  }

  let confidenceLookup = {
    1: `Verifier's Confidence Level : 25 %`,
    2: `Verifier's Confidence Level : 50 %`,
    3: `Verifier's Confidence Level : 75 %`,
    4: `Verifier's Confidence Level : 100 %`
  }

  return(
    <div className="flex-1 py-6">
      <div className="flex items-center">
        <div className="bg-vipGreen w-[20px] h-[20px] rounded-full center-items">

        </div>
        <div className="mx-2 border-b-2 border-vipGreen w-[35px]"/>
        <div className="bg-vipGreen w-[20px] h-[20px] rounded-full center-items">
          {(props.item.status === 'pendingReview') &&
            <div className="bg-white w-[16px] h-[16px] rounded-full"/>
          }
        </div>
        <div className="mx-2 border-b-2 border-vipGreen w-[35px]"/>
        <div className="bg-vipGreen w-[20px] h-[20px] rounded-full center-items">
          { props.item.status !== 'complete'  &&
            <div className="bg-white w-[16px] h-[16px] rounded-full"/>
          }
        </div>
      </div>
      <div className="flex items-center mt-4 ">
        {statusLookup[props.item.status]} {props.item.status === 'complete' ?  verdictLookup[props.item.verdict] : ''}
      </div>
      <div className="flex items-center">
        {props.item.status === 'complete' ?  confidenceLookup[props.item.confidence] : ''}
      </div>
    </div>
  )
}
