import axios from "axios";

//all verifications on admin level
const GetAllVerficationsQuery = `
query GetAllVerifications ($status: String, $secondOpinionRequested: Boolean)  {
    GetAllVerifications (status: $status, secondOpinionRequested: $secondOpinionRequested) {
        category
        brand
        name
        description
        status
        model
        dateOfLastUpdate
        uuid
        verdict
        createdDate
        secondOpinionRequested
        files
        users
    }
}`;

const GetAllVerifications = async (data, token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: GetAllVerficationsQuery,
      variables: {
        ...data,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data.GetAllVerifications;
};

export { GetAllVerifications };
