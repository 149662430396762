import axios from "axios";

const SaveAnswersMutation = `
mutation SaveTestAnswers ($answers: JSON){
       SaveTestAnswers (answers: $answers)
}`;

const SaveAnswers = async (data, token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: SaveAnswersMutation,
      variables: {
        ...data,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data.SaveTestAnswers;
};

export { SaveAnswers };

{
  /*
  Example - same as the object in frontend
  {
   "answers":[
    {
        "category": "bags",
        "brand": "MCM",
        "model": "Liz Shopper Large Cognac",
        "name": "Bags Test Case No. 1",
        "description": "Boodschappentas",
        "status": "To Do",
        "uuid": "234565",
        "verdict": true,
        "confidence": "Confidence",
        "files": [
            {
                "filename": "FileNm1",
                "key": "https://statics-cdn.fashionette.net/5/3/8/d/538d498503ec2134e4b644572ef46083afd5360f_a0134570_mcm_2_pdp.jpeg",
                "date": "10-10-2022",
                "requestId": "34578",
                "regionData": [
                    {
                        "x": 22,
                        "y": 60,
                        "width": 16,
                        "height": 15,
                        "data": {
                            "comment": "",
                            "dropdown": 1,
                            "selected": false
                        },
                        "comment": "commentingggg",
                        "selected": true
                    }
                ]
            },
            {
                "filename": "FileNm2",
                "key": "https://statics-cdn.fashionette.net/4/f/9/1/4f91c6789244976fad0549dbb4e3b56f5808d371_a0134570_mcm_3_pdp.jpeg",
                "date": "10-10-2022",
                "requestId": "175234",
                "regionData": [
                    {
                        "x": 32,
                        "y": 75,
                        "width": 16,
                        "height": 15,
                        "data": {
                            "comment": "",
                            "dropdown": 1,
                            "selected": false
                        },
                        "comment": ""
                    },
                    {
                        "x": 17,
                        "y": 25,
                        "width": 33,
                        "height": 24,
                        "data": {
                            "comment": "",
                            "dropdown": 2,
                            "selected": false
                        },
                        "comment": ""
                    }
                ]
            },
            {
                "filename": "FileNm3",
                "key": "https://statics-cdn.fashionette.net/b/3/a/8/b3a87e853f346dc9e539bcd704b71f9b10487b1f_a0134570_mcm_4_pdp.jpeg",
                "date": "10-10-2022",
                "requestId": "23462",
                "regionData": [
                    {
                        "x": 68,
                        "y": 30,
                        "width": 16,
                        "height": 15,
                        "data": {
                            "comment": "",
                            "dropdown": 1,
                            "selected": false
                        }
                    },
                    {
                        "x": 27,
                        "y": 76,
                        "width": 33,
                        "height": 24,
                        "data": {
                            "comment": "",
                            "dropdown": 2,
                            "selected": false
                        }
                    }
                ]
            },
            {
                "filename": "FileNm4",
                "key": "https://statics-cdn.fashionette.net/7/c/0/2/7c0285caef29d2b75c59c373dbd1e21d7873978f_a0134570_mcm_1_pdp.jpeg",
                "date": "10-10-2022",
                "requestId": "1212534",
                "regionData": [
                    {
                        "x": 56,
                        "y": 30,
                        "width": 16,
                        "height": 15,
                        "data": {
                            "comment": "",
                            "dropdown": 1,
                            "selected": false
                        }
                    },
                    {
                        "x": 67,
                        "y": 88,
                        "width": 33,
                        "height": 24,
                        "data": {
                            "comment": "",
                            "dropdown": 2,
                            "selected": false
                        }
                    }
                ]
            }
        ]
    },
    {
        "category": "bags",
        "brand": "MCM",
        "model": "Liz Shopper Large Cognac",
        "name": "Bags Test Case No. 2",
        "description": "Boodschappentas",
        "status": "To Do",
        "uuid": "234565",
        "verdict": false,
        "confidence": "Confidence",
        "files": [
            {
                "filename": "FileNm1",
                "key": "https://statics-cdn.fashionette.net/5/3/8/d/538d498503ec2134e4b644572ef46083afd5360f_a0134570_mcm_2_pdp.jpeg",
                "date": "10-10-2022",
                "requestId": "34578",
                "regionData": [
                    {
                        "x": 22,
                        "y": 60,
                        "width": 16,
                        "height": 15,
                        "selected": true,
                        "comment": "commments xxxxxx"
                    }
                ]
            },
            {
                "filename": "FileNm2",
                "key": "https://statics-cdn.fashionette.net/4/f/9/1/4f91c6789244976fad0549dbb4e3b56f5808d371_a0134570_mcm_3_pdp.jpeg",
                "date": "10-10-2022",
                "requestId": "175234",
                "regionData": [
                    {
                        "x": 32,
                        "y": 75,
                        "width": 16,
                        "height": 15,
                        "selected": false,
                        "comment": ""
                    },
                    {
                        "x": 17,
                        "y": 25,
                        "width": 33,
                        "height": 24,
                        "selected": false,
                        "comment": ""
                    }
                ]
            },
            {
                "filename": "FileNm3",
                "key": "https://statics-cdn.fashionette.net/b/3/a/8/b3a87e853f346dc9e539bcd704b71f9b10487b1f_a0134570_mcm_4_pdp.jpeg",
                "date": "10-10-2022",
                "requestId": "23462",
                "regionData": [
                    {
                        "x": 68,
                        "y": 30,
                        "width": 16,
                        "height": 15,
                        "selected": false
                    },
                    {
                        "x": 27,
                        "y": 76,
                        "width": 33,
                        "height": 24,
                        "selected": false
                    }
                ]
            },
            {
                "filename": "FileNm4",
                "key": "https://statics-cdn.fashionette.net/7/c/0/2/7c0285caef29d2b75c59c373dbd1e21d7873978f_a0134570_mcm_1_pdp.jpeg",
                "date": "10-10-2022",
                "requestId": "1212534",
                "regionData": [
                    {
                        "x": 56,
                        "y": 30,
                        "width": 16,
                        "height": 15,
                        "selected": false
                    },
                    {
                        "x": 67,
                        "y": 88,
                        "width": 33,
                        "height": 24,
                        "selected": false
                    }
                ]
            }
        ]
    }
]
}
  */
}
