import axios from 'axios'

const DownloadTransactionsQuery = `
query DownloadTransactions ($startDate: String, $endDate: String) {
    DownloadTransactions (startDate:$startDate, endDate:$endDate)
}`

const DownloadTransactions = async (data, cb) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: DownloadTransactionsQuery,
      variables: {
        ...data
      }
    }
  })
  return res.data.data.DownloadTransactions
}

export {
  DownloadTransactions
}
