import * as React from "react"

function LinkedinIcon(props) {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_786_436)">
        <path
          d="M25.561 25.562h-4.445V18.6c0-1.66-.03-3.797-2.311-3.797-2.316 0-2.67 1.81-2.67 3.677v7.081H11.69V11.246h4.267v1.956h.06a4.676 4.676 0 014.21-2.312c4.506 0 5.336 2.963 5.336 6.819l-.002 7.853zM6.675 9.289a2.58 2.58 0 11-.001-5.159 2.58 2.58 0 010 5.16zm2.222 16.273h-4.45V11.246h4.45v14.316zM27.777.002H2.214A2.19 2.19 0 000 2.164v25.67A2.19 2.19 0 002.214 30h25.563A2.196 2.196 0 0030 27.835V2.162A2.194 2.194 0 0027.777 0"
          fill="#0A66C2"
        />
      </g>
      <defs>
        <clipPath id="clip0_786_436">
          <path fill="#fff" d="M0 0H30V30H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LinkedinIcon
