import { GridCardCreate } from "assets/components/grid-cards/GridCardCreate";
import { GridCardHome } from "assets/components/grid-cards/GridCardHome";
import { GridCardImageUpload } from "assets/components/grid-cards/GridCardImageUpload";
import { SubmitCompleteModal } from "assets/components/modals/SubmitCompleteModal";
import { CreateRequest } from "api/CreateRequest.js";
import { UpdateRequest } from "api/UpdateRequest.js";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { uploadImage } from "util/api"

export const NewVerificationRegistration = (props) => {
  const params = useParams()
  const navigate = useNavigate()


  const [openModal, setOpenModal] = useState(false)
  const [maxImg, setMaxImg] = useState(6)
  const [init, setInit] = useState(false)
  const [id, setId] =  useState()
  const [files, setFiles] = useState([])

  const [productDetails, setProductDetails] = useState({
    name: '',
    brand: '',
    description: '',
    category: params.id,
    model: '',
  })

  const [imgArr, setImgArr] = useState([
    {
      key: ''
    }
  ])

  const handleUpload = (event) => {
    uploadImage(event.target.files[0], 1, id, (err, out) => {
      console.log('Out from file upload', out)
      setFiles(current => [...current, out.fileId])
      console.log('Image array', imgArr)
      setImgArr(current => [...current, { key: out.key}])
    })
  }

  useEffect(() => {
    console.log('imgarr:', imgArr)
  }, [imgArr])

  useEffect(()=>{
    if(!init){
      setInit(!init)
      CreateRequest({request: {}}, (err, out) => {
         console.log('out', out)
          setId(out.uuid)
        })
    }
  }, )

  const handleChange = (input, slug) => {
    let _product = productDetails
    _product[slug] = input
    setProductDetails({..._product})
  }


  const updateRequest = () => {
    let obj = {uuid: id, update: {...productDetails, files: files, marketplaceId: 'e48f76d9-353d-4957-a4b1-95f9a26f6a8f'}}
    UpdateRequest(obj, (err, update) => {
      if(err) console.log('Error saving')
      //console.log('update:', update)
    })
  }

  return(
    <div className="h-full w-full">
      <SubmitCompleteModal
        isOpen={openModal}
        setIsOpen={setOpenModal}
      />
      <div className="text-xl font-semibold py-4 px-6">
        Information about the {params.id || "product"}
      </div>
      <div className="py-4 px-6 flex ">
        <div>
          <div className="pb-1 font-semibold">
            Name
          </div>
          <input
            className="input-field"
            placeholder="Name... "
            type="text"
            onChange={(e)=>handleChange(e.target.value, "name")}
          />
        </div>
      </div>
      <div className="py-4 px-6 flex ">
        <div>
          <div className="pb-1 font-semibold">
            Brand
          </div>
          <input
            className="input-field"
            placeholder="Brand Name... "
            type="text"
            onChange={(e)=>handleChange(e.target.value, "brand")}
          />
        </div>
        <div className="ml-12">
          <div className="pb-1 font-semibold">
            Model
          </div>
          <input
            className="input-field"
            placeholder="Model Type... "
            type="text"
            onChange={(e)=>handleChange(e.target.value, "model")}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 px-3">
        {imgArr.map((item, index) => (
          <GridCardImageUpload
            requestId={id}
            handleUpload={handleUpload}
            key={index}
            card={item}
            index={index}
          />
        ))}

        <GridCardCreate
          imgArr={imgArr}
          setImgArr={setImgArr}
        />
      </div>
      <div className="my-12 w-full border-t-2 border-vipGreen flex">
        <div
          className="mt-4 w-fit h-fit bg-vipGreen text-white text-lg font-semibold px-4 py-1 rounded cursor-pointer hover:shadow-xl hover:opacity-95"
          onClick={()=>navigate('/new-verification')}
        >
          Back
        </div>
        <div
          className="ml-auto mt-4 w-fit h-fit bg-vipGreen text-white text-lg font-semibold px-4 py-1 rounded cursor-pointer hover:shadow-xl hover:opacity-95"
          onClick={()=>{
            console.log('productDetails', productDetails )
            updateRequest()
            setOpenModal(true)
          }}
        >
          Finish
        </div>
      </div>
    </div>
  )
}
