import axios from 'axios'


const CreateRequestMutation = `
mutation CreateRequest($request: RequestInputType){
  CreateRequest(request: $request){
    category
    uuid
  }
}`

const CreateRequest = (data, cb) => {
  axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: CreateRequestMutation,
      variables: {
        ...data
      }
    }
  })
  .then((result) => cb(false, result.data.data.CreateRequest))
  .catch((err) => cb(true, err))
}

export {
  CreateRequest
}
