import * as React from "react"

function FiveYellow(props) {
  return (
    <svg
      width={171}
      height={63}
      viewBox="0 0 171 63"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.84 26.525l5.76 8.6-5.76 8.68h-4.4l5.36-8.68-5.36-8.6h4.4zm1.92 6.8v3.64H5.36v-3.64h14.4zm51.428 13.2c-1.973 0-3.906-.28-5.8-.84-1.866-.587-3.44-1.387-4.72-2.4l2.2-4.04c1.014.827 2.24 1.493 3.68 2 1.467.507 2.987.76 4.56.76 1.84 0 3.28-.387 4.32-1.16 1.067-.773 1.6-1.813 1.6-3.12 0-.88-.226-1.653-.68-2.32-.426-.667-1.2-1.173-2.32-1.52-1.093-.347-2.6-.52-4.52-.52h-6.76l1.44-15.24h16.44v4.36h-14.52l2.72-2.48-1.08 11.4-2.72-2.44h5.64c2.774 0 5 .373 6.68 1.12 1.707.72 2.947 1.733 3.72 3.04.8 1.28 1.2 2.747 1.2 4.4 0 1.627-.4 3.12-1.2 4.48-.8 1.36-2.026 2.453-3.68 3.28-1.626.827-3.693 1.24-6.2 1.24zm60.324-20l5.76 8.6-5.76 8.68h-4.4l5.36-8.68-5.36-8.6h4.4zm1.92 6.8v3.64h-14.4v-3.64h14.4z"
        fill="#010101"
      />
      <path
        d="M14.84 24.4L20.6 33l-5.76 8.68h-4.4L15.8 33l-5.36-8.6h4.4zm1.92 6.8v3.64H2.36V31.2h14.4zm51.428 13.2c-1.973 0-3.906-.28-5.8-.84-1.866-.587-3.44-1.387-4.72-2.4l2.2-4.04c1.014.827 2.24 1.493 3.68 2 1.467.507 2.987.76 4.56.76 1.84 0 3.28-.387 4.32-1.16 1.067-.773 1.6-1.813 1.6-3.12 0-.88-.226-1.653-.68-2.32-.426-.667-1.2-1.173-2.32-1.52-1.093-.347-2.6-.52-4.52-.52h-6.76L61.188 16h16.44v4.36h-14.52l2.72-2.48-1.08 11.4-2.72-2.44h5.64c2.774 0 5 .373 6.68 1.12 1.707.72 2.947 1.733 3.72 3.04.8 1.28 1.2 2.747 1.2 4.4 0 1.627-.4 3.12-1.2 4.48-.8 1.36-2.026 2.453-3.68 3.28-1.626.827-3.693 1.24-6.2 1.24zm60.324-20l5.76 8.6-5.76 8.68h-4.4l5.36-8.68-5.36-8.6h4.4zm1.92 6.8v3.64h-14.4V31.2h14.4z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default FiveYellow
