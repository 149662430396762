import React, { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'

import { MenuDropdown } from "./MenuDropdown";

 export const LandingNavbar = ( props ) => {
  const location = useLocation()
  const params = useParams()
  const navigate = useNavigate()
  let headerOptions = [
    {
      header: "Home",
      slug: "",
      headerItems:[]
    },
    {
      header: "Item One",
      slug: "item-one",
      headerItems:["Dropdown Item One", "Dropdown Item Two" ]
    }
  ]

  console.log(location.pathname)

  return(<>
    <div className="fixed top-0 w-full py-2 h-[65px] z-20 bg-white shadow">
      <div className="flex max-w-6xl m-auto">
        <div className="ml-4 mr-4 flex-1">
          <img
            className="h-[70px]"
            src={require(!props.darkMode ? './../../files/images/vip-logo-mock.png':'./../../files/images/vip-logo-white-mock.png')}
            alt="..."
            href="/" 
            onClick={()=>navigate("/")}
          />
        </div>
        <div className="flex flex-1">
          <div className="px-4 py-3 cursor-pointer relative" onClick={()=>navigate("/marketplace")}>
            <div className={`${(location.pathname === "/marketplace" || location.pathname === "/") ? "font-semibold" : ""}`}>
              Marketplace
            </div> 
            <div className={`absolute text-vipYellow w-fit  ${(location.pathname === "/marketplace" || location.pathname === "/") ? " opacity-100 font-semibold top-[10px] left-[14px] " : " top-[12px] left-[16px] opacity-0"} animate`}>
              Marketplace
            </div>
          </div>
          <div className="px-4 py-3 cursor-pointer relative" onClick={()=>navigate("/verifiers")}>
            <div className={`${location.pathname === "/verifiers" ? "font-semibold" : ""}`}>
              Verifiers
            </div> 
            <div className={`absolute text-vipYellow w-fit top-[10px] left-[14px] ${location.pathname === "/verifiers" ? "opacity-100  font-semibold " : "opacity-0"} animate`}>
              Verifiers
            </div>
          </div> 
          <div className="px-4 py-3 cursor-pointer relative" onClick={()=>navigate("/about")}>
            <div className={`${location.pathname === "/about" ? "font-semibold" : ""}`}>
              About us
            </div>
            <div className={`absolute text-vipYellow w-fit top-[10px] left-[14px] ${location.pathname === "/about" ? "opacity-100  font-semibold " : "opacity-0"} animate`}>
              About us
            </div>
          </div>
        </div>
        
        <div className="pt-2 flex-1">
          <div className="yellow-button w-fit m-auto"  onClick={()=>navigate('/login')}>
            Sign In
          </div>
        </div>
        {/*<div className="flex ">
          {headerOptions.map(item => (
            <MenuDropdown
              key={item.header}
              headerName={item.header}
              headerItems={item.headerItems}
              headerSlug={item.slug}
            />
          ))}
        </div> */}
        {/* <div className="ml-auto pt-3 mr-4">
          <input
            type="checkbox"
            checked={props.darkMode}
            onChange={()=>props.setDarkMode(!props.darkMode)}
          />
        </div> */}
      </div>

    </div>
  </>)
}
