import React from "react";  
import YellowManImage from './../../img/card-images/laptop-man.png'

import { InlineWidget } from "react-calendly";

export const PlanMeetingModal = ({open, setOpen}) => {
  return(
    <> 
      <div className={`h-screen w-screen fixed z-10 ${!open ?"hidden" : ""}`}>
        <div className="w-full h-full grid place-items-center relative backdrop-blur-sm z-10">
        {/* onClick={()=>setOpen(!open)} */}
          <div className="max-w-3xl w-full bg-white min-h-[550px] rounded-xl p-10 shadow-xl relative overflow-hidden">
            <div className="absolute top-0 right-0 font-bold pr-4 pt-4 text-2xl cursor-pointer" onClick={()=>setOpen(!open)}>
              x
            </div>
            <div className="text-vipYellow font-bold text-4xl w-[60%] ml-auto pb-4" style={{backgroundImage: "url("+YellowManImage+")"}}>
              Plan a meeting
            </div>
            <div className="absolute -top-3 -left-52 animate-wiggle origin-top">
              <img src={YellowManImage} className="w-[700px] " />
            </div> 
            <div className="relative z-100">
              <InlineWidget url="https://calendly.com/vip-ficated/meeting" /> 
            </div>
          </div>
        </div>
      </div> 
    </>
    
  )
}