import React, { useState } from "react";
import { auth } from '../../firebase'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import FacebookIcon from "assets/img/card-images/facebook-icon";
import GoogleIcon from "assets/img/card-images/google-icon";
import LinkedinIcon from "assets/img/card-images/linkedin-icon";
import { ChevronRightIcon, EyeIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import vipLogo from "assets/files/images/vip-logo-mock.png"

export const TestCompleted = () => {
  const navigate = useNavigate()
  return(
    <div className="m-0 pt-4 flex flex-col ">
      <div className="w-fit m-auto">
        <img
          className="scale-[80%]"
          src={vipLogo}
        />
      </div>
      <div className="max-w-2xl m-auto text-center flex-1"> 
        <div className="text-xl pb-4 font-bold">
          Thank you for completing your introduction test!
        </div>
      </div>  
      <div className="max-w-2xl w-full m-auto pt-4 pb-8"> 
        <div className="text-lg text-justify">
          Our team will process your introduction test as soon as possible, and we will reach out to your registered email after your test has been reviewed! 
        </div>
        <div className="text-justify font-semibold button-confirm mt-12 m-auto" onClick={()=> navigate("/home")}>
          To the platform 
        </div>
      </div> 
    </div>
  )
}
