
import React from "react";

import YellowManImage from './../../img/card-images/laptop-man.png' 
import TimesThreeYellow from "assets/files/images/times-three";
import TimesElevenYellow from "assets/files/images/times-eleven";
import PercentYellow from "assets/files/images/36p";
import TimesFourtyYellow from "assets/files/images/times-fourty";
import { CallToActionModal } from "assets/components/basic/CallToActionModal";
import { PlanMeetingModal } from "assets/components/basic/PlanMeetingModal";
import { useState } from "react";
import OneYellow from "assets/files/svgs/one-yellow";
import TwoYellow from "assets/files/svgs/two-yellow";
import ThreeYellow from "assets/files/svgs/three-yellow";
import FourYellow from "assets/files/svgs/four-yellow";
import FiveYellow from "assets/files/svgs/five-yellow";
import SixYellow from "assets/files/svgs/six-yellow";
import SevenYellow from "assets/files/svgs/seven-yellow";
import EightYellow from "assets/files/svgs/eight-yellow";
import WomanImage from "assets/files/svgs/woman-image.png";
import PlusYellow from "assets/files/svgs/plus-yellow";
import PiggyYellow from "assets/files/svgs/piggy-yellow";
import PersonYellow from "assets/files/svgs/person-yellow";
import ScatterImage from "assets/files/svgs/trust-scatter-img-crop.png";
import TrustImage from "assets/files/svgs/trust-word-img.png";

import TabletPlatformImage from "assets/files/svgs/tablet-platform-image.png";
import LinkedinIcon from "assets/files/svgs/linkedin-icon";

import LuisPhoto from "assets/img/card-images/luis-photo.png";
import HectorPhoto from "assets/img/card-images/hector-photo.png";
import netmentoraLogo from "assets/img/card-images/netmentora.png";
import stanfordLogo from "assets/img/card-images/stanford.png";
import unibailLogo from "assets/img/card-images/unibail.png";
import yellowAnon from "assets/img/card-images/yellow-anon.png";

import PrivacyPolicy from "assets/files/VIPFICATED_PRIVACY_POLICY.pdf"

import { AddContact } from "api/AddContact.js";

export const LandingAbout = (props) => {
  const [demoObject, setDemoObject] = useState({
    currentSystem: "",
    currentCountries: 0,
    prefCountries: 0,
    currentProducts: [""],
    email: "",
    checkbox: false
  })
  const [openCta, setOpenCta] = useState(false)
  const [openMeeting, setOpenMeeting] = useState(false)
  let founders = [
    {
      name: 'Luis Ferrero',
      img: "https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2662&q=80",
      title: 'CEO & CMO',
      description: 'Ex-WPP Head of Digital Retail Marketing EMEA',
      linkedin: "https://www.linkedin.com/in/loyaltydigitalevolution/",
      logo1: stanfordLogo,
      logo2: "https://www.netmentora.org/madrid/wp-content/uploads/sites/66/2018/12/yinper.png"    
    },
    {
      name: 'Hector Serna',
      img: "https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2662&q=80",
      title: 'Legal & Ops.',
      description: 'Partnership Operations',
      linkedin: "https://www.linkedin.com/in/h%C3%A9ctor-s-798734b3/",
      logo1: "https://www.netmentora.org/madrid/wp-content/uploads/sites/66/2018/12/yinper.png",
      logo2: unibailLogo    
    },
    {
      name: 'Douwe B. Mulder',
      img: "https://images.unsplash.com/photo-1511367461989-f85a21fda167?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2662&q=80",
      title: 'CTO',
      description: 'Serial Entrepeneur',
      // linkedin: "https://www.linkedin.com/in/loyaltydigitalevolution/",
      logo1: "https://d1p9wirkq0k00v.cloudfront.net/wp-content/uploads/2017/06/12133443/Masterbrand-Logo_color.png",
      // logo2: netmentoraLogo    
    },
  ]

  const toggleCheckbox = () => {
    let obj = {...demoObject}
    obj.checkbox = !obj.checkbox
    setDemoObject({...obj})
  }

  const handleChange = (key, value) => {
    let obj = {...demoObject}
    obj[key] = value
    setDemoObject({...obj})
  }

  const getDisabled = () => { 
    if(demoObject.email == "" || demoObject.checkbox == false) return true
    else return false
  }

  return(
    <div className="w-full min-h-screen text-lg mt-16"> 
      <CallToActionModal open={openCta} setOpen={setOpenCta} />
      <PlanMeetingModal open={openMeeting} setOpen={setOpenMeeting}/>
      <div className="bg-vipBgDark">
        <div className="max-w-[1280px] m-auto px-4">
          <div className="flex w-full md:h-[650px] relative py-8 md:py-0">
            <div className="flex-1 flex flex-col">
              <div className="flex-1"> 
              </div>
              <div className="md:w-[45%] mr-auto relative z-1">
                <div className="text-6xl font-semibold text-vipYellow drop-shadow-lg text-left">
                  We are
                </div> 
                <div className="pt-8">
                  <span className="font-bold text-black relative">Our mission<span className="text-vipYellow font-bold absolute -top-[4px] -left-[2px]">Our mission</span></span> is to facilitate the exchange of high-value products in second-hand markets
                </div>
                <div className="pt-8">
                  <span className="font-bold text-black relative">Our vision<span className="text-vipYellow font-bold absolute -top-[4px] -left-[2px]">Our vision</span></span> is to make second-hand first choice
                </div>
                {/* <div className="yellow-button mt-4 relative z-3" onClick={()=>setOpenCta(!openCta)}>
                  Take the test now
                </div> */}
              </div>
              <div className="flex-1"> 
              </div>
            </div>
            <div className="hidden md:block flex-1 absolute -top-3 right-0 origin-top z-2">
              <img
                className="w-[700px] "
                src={TabletPlatformImage}
              />
            </div>
          </div>
        </div>
        <div className="max-w-7xl m-auto px-4 md:px-0">
          <div className="text-4xl font-semibold text-vipYellow drop-shadow-lg text-left">
            Team
          </div>
          <div className="max-w-2xl py-8">
            A team of founders with over 30 years in e-retail innovation and marketplace technology. With incredible support from mentors we embrace, we are creating a paradigm shift in the secondhand market
          </div>
          <div className="grid grid-cols-1 md:grid-cols-3 space-y-4 md:space-y-0">
            {founders.map(item => (
              <div className="w-[350px] border-4 border-vipYellow rounded-3xl min-h-[350px] bg-white p-4 relative h-[450px]">
                <div className="rounded-full bg-black w-[150px] h-[150px] overflow-hidden grid place-content-center font-bold text-2xl mt-5 m-auto mb-2">
                  <img 
                    className="bg-contain bg-center"
                    src={yellowAnon}
                  />
                </div>
                <div className="text-center font-bold text-vipYellow text-xl">
                  {item.title}
                </div>
                <div className="flex pt-4">
                  <div className="font-bold">
                    {item.name}
                  </div>
                  {item.linkedin ?
                    <a className="w-fit ml-auto cursor-pointer" href={item.linkedin}>
                      <LinkedinIcon className="h-[23px]"/>
                    </a>
                    :
                    <a className="w-fit ml-auto" href="https://www.crunchbase.com/person/douwe-bart-mulder">
                      <img 
                        className="w-[25px]"
                        src="https://static-00.iconduck.com/assets.00/crunchbase-icon-512x512-46e6v2an.png"
                      />
                    </a>  
                  }
                </div> 
                <div className="pt-2">
                  {item.description}
                </div>
                <div className="flex w-[310px] pt-4 absolute bottom-5 h-[80px]">
                  <div className="w-[120px] m-auto grid place-content-center"> 
                    <img 
                      src={item.logo1}
                      className=" "
                    />
                  </div>
                  <div className="w-[120px] m-auto grid place-content-center">
                    <img 
                      className=" "
                      src={item.logo2}
                    />
                  </div> 
                </div>
              </div>
            ))}
          </div>
          <div className="pt-8">
            Want to get to know our founding team more? 
          </div>
          <div className="yellow-button mt-8" onClick={()=>setOpenMeeting(!openMeeting)}>
            Plan a meeting!
          </div>
          <div className="text-4xl font-semibold text-vipYellow drop-shadow-lg text-left pt-12">
            Our story
          </div>
          <div className="py-8 md:w-3/5">
            VIP·Ficated was founded in November of 2020 by Luis and Héctor in The Netherlands where we were selected by Startup BootCamp accelerator and after a few pivots we were accepted in Alchemist accelerator and we moved the company to the US.
            <br/><br/>
            Luis has two degrees in Business and Marketing, with specialization courses in Oxford and Stanford. His background includes worldwide-awarded company WPP and 
            UR-Westfield as head of innovation in retail, where he met Hector, lawyer consultant specialized in retail operations. Since then they have worked in several ways to disrupt the way that people buy and sell, both from companies they have founded and from others they have helped to scale.
            <br/><br/>
            The idea for VIP·ficated came from Luis when he observed the behavior of customers 
            in shopping malls. He measured the relationship between new product customers, used product customers and the level of trust that was necessary for shoppers 
            to decide to buy a used product.
            <br/><br/>
            He was able to measure the huge lack of trust that buyers have on pre-owned products, and applied some market research to apply some trust builders to buyers interested 
            in fashion because of the impact of that industry in pollution and labor conditions.
            <br/><br/>
            Luis and Hector, together with a group of colleagues were able to build a marketplace to validate the product market-fit-price, where every single product was verified 
            by a local professional seller. They got the core of the marketplace, the verification 
            and put it into an API able to create an ecosystem of transparency and protection 
            for any second-hand marketplace in the world.
            <br/><br/>
            Luis and Hector created a delocalized network of verifiers who started to authenticate the real condition of clothes aimed by technology at the beginning of 2022. Since then, the technology to fight counterfeits has been improved on a monthly basis and 
            the integration with brands is motivating our efforts thanks to the satisfaction 
            of the buyers who have second-hand first choice.
          </div>
        </div>
      </div>
      {/* vip makes fashion meet tech~  */}
      <div className="bg-white max-w-7xl m-auto md:py-12 bg-no-repeat bg-right-bottom bg-70 px-4 md:px-0" style={{backgroundImage: "url(" + ScatterImage + ")"}}> 
        <div className="text-4xl font-semibold text-vipYellow drop-shadow-lg text-left pt-12">
          Our principles are
        </div>
        <div className="flex "> 
          <div className="md:w-2/5">
            <div className="pt-12">
              <div className="relative flex">
                <div className="text-4xl font-bold">
                  T
                </div>
                <div className="absolute -top-1 -left-1 font-bold text-4xl text-vipYellow">
                  T
                </div>
                <div className="font-bold grid place-content-end">
                  ransparent
                </div>
              </div>
              <div>
                our process is totally transparent and traceable to marketplaces
              </div>
            </div>
            <div className="pt-4">
              <div className="relative flex">
                <div className="text-4xl font-bold">
                  R
                </div>
                <div className="absolute -top-1 -left-1 font-bold text-4xl text-vipYellow">
                  R
                </div>
                <div className="font-bold grid place-content-end">
                  esponsibility
                </div>
              </div>
              <div>
              we are aware that we deliver trust. Difficult to gain, easy to lose

              </div>
            </div>
            <div className="pt-4">
              <div className="relative flex">
                <div className="text-4xl font-bold">
                  U
                </div>
                <div className="absolute -top-1 -left-1 font-bold text-4xl text-vipYellow">
                  U
                </div>
                <div className="font-bold grid place-content-end">
                  nion
                </div>
              </div>
              <div>
                we have created an inclusive technology in the core of sustainability that welcomes any marketplace with the conviction of delivering quality
              </div>
            </div>
            <div className="pt-4">
              <div className="relative flex">
                <div className="text-4xl font-bold">
                  S
                </div>
                <div className="absolute -top-1 -left-1 font-bold text-4xl text-vipYellow">
                  S
                </div>
                <div className="font-bold grid place-content-end">
                  eeking for knowledge
                </div>
              </div>
              <div>
              our verifiers are continuously audited and have the means to continue learning 
              </div>
            </div>
            <div className="pt-4">
              <div className="relative flex">
                <div className="text-4xl font-bold">
                  T
                </div>
                <div className="absolute -top-1 -left-1 font-bold text-4xl text-vipYellow">
                  T
                </div>
                <div className="font-bold grid place-content-end">
                  ake decisions
                </div>
              </div>
              <div>
                based on unbiased and robust data 
              </div>
            </div>
          </div>
          <div className="hidden md:blockgrid place-content-center ml-12"> 
            <img 
            className="w-[380px]"
              src={TrustImage}
            />
          </div>
        </div>
        <div>
          <div className="font-semibold pt-8">
            Do you want to join us?
          </div>
          <div className="max-w-[60%] pb-8">
            There are no open positions at this moment. You can follow our LinkedIn page, where we will list new job offers in the near future.
          </div>
        </div>
      </div>  
      {/*  do you want to become~ */}
      <div className="bg-vipBgDark w-screen pt-12 pb-12 px-4 md:px-0">
        <div className="max-w-7xl m-auto md:flex">  
          <div className="flex-1">
            <div className="text-left font-bold text-3xl text-vipYellow max-w-5xl">
              Business Cases
            </div>  
            <div className="md:flex">
              <div className="pt-8 max-w-xl mr-4 pb-8">
                Both if you’re a big marketplace in the resale fashion business, or a small ecommerce company willing to get into the circular economy, a business case will be the best way to show off the opportunity to differentiate from competitors.
              </div>
              <div className="flex-1 bg-white px-8 py-4 m-2 rounded-lg">
                <div className="font-semibold text-xl">
                  Please complete the form below according to your most suitable situation:
                </div>
                <div className="pt-2 font-semibold">
                  If you already have a product authentication system in place in your company, it is:
                </div>
                <div>
                  <div className="flex pt-2">
                    <input 
                      type="radio"
                      name="first"
                      onChange={()=>handleChange("currentSystem", "Insource")}
                    /> 
                    <div className="ml-3">
                      Insource
                    </div>
                  </div>
                  <div className="flex pt-2">
                    <input 
                      type="radio"
                      name="first"
                      onChange={()=>handleChange("currentSystem", "Outsource")}

                    /> 
                    <div className="ml-3">
                      Outsource
                    </div>
                  </div>
                  <div className="flex pt-2">
                    <input 
                      type="radio"
                      name="first"
                      onChange={()=>handleChange("currentSystem", "None")}

                    /> 
                    <div className="ml-3">
                      I don't have a authentication system in place
                    </div>
                  </div>
                </div>
                <div className="pt-4 font-semibold">
                  How many countries are you currently serving your products to?
                </div>
                <div>
                  <input 
                    className="w-[50px] focus:outline-vipYellow border-2 rounded px-2 py-1 my-2"
                    type="number"
                    min={0}
                    onChange={(e)=>handleChange("currentCountries", e.target.value)}

                  />
                </div> 
                <div className="pt-4 font-semibold">
                  How many countries would you like to serve your products in 6 months?
                </div>
                <div>
                  <input 
                    className="w-[50px] focus:outline-vipYellow border-2 rounded px-2 py-1 my-2" 
                    type="number"
                    min={0}
                    onChange={(e)=>handleChange("prefCountries", e.target.value)}

                  />
                </div>
                <div className="pt-4 font-semibold">
                  If you already have a product authentication system in place in your company, it is:
                </div>
                <div>
                  <div className="flex pt-2">
                    <input 
                      type="checkbox"
                      name="first"
                      className=""
                    /> 
                    <div className="ml-3">
                      Clothing
                    </div>
                  </div>
                  <div className="flex pt-2">
                    <input 
                      type="checkbox"
                      name="first"
                    /> 
                    <div className="ml-3">
                      Jewelry
                    </div>
                  </div>
                  <div className="flex pt-2">
                    <input 
                      type="checkbox"
                      name="first"
                    /> 
                    <div className="ml-3">
                      Accessories
                    </div>
                  </div>
                  <div className="flex">
                    <input 
                      type="checkbox"
                      name="first"
                    /> 
                    <div className="ml-3">
                      Shoes
                    </div>
                  </div>
                  <div className="pt-4 font-semi">
                    E-mail where we will send the business case
                  </div>
                  <div>
                    <input 
                      className="w-full focus:outline-vipYellow border-2 rounded px-2 py-1 my-2" 
                      type="text"
                      onChange={(e)=>handleChange("email", e.target.value)} 
                    />
                  </div>
                  <div className="flex">
                    <div className="mr-4">
                      <input 
                        type="checkbox"
                        onChange={toggleCheckbox}
                      />
                    </div>
                    <div>
                      By checking the box you agree to be contacted by the VIP·ficated team for the requested info and possible other Vipficated related correspondence
                    </div>
                  </div>
                  <button 
                    className="mt-8 yellow-button disabled:bg-gray-300 disabled:text-gray-500" 
                    disabled={ getDisabled() } 
                    onClick={()=>{
                      console.log('request business case', demoObject)
                      props.notify('Request business case sent', 'success')
                      AddContact(demoObject)
                    }}>
                    Request business case
                  </button>
                </div>
              </div>
            </div>
            {/* <div className="yellow-button mt-12" onClick={()=>setOpenCta(!openCta)}>
              Apply for a free trial
            </div> */}
          </div> 
          {/* <div>
            <img src={WomanImage}/>
          </div> */}
        </div> 
      </div>
       
      {/* footer */}
      <div className="w-screen bg-vipGreen text-white py-12">
        <div className="text-center max-w-4xl m-auto font-semibold">
          Copyright VIP·Ficated 2022
        </div>
        <div className="text-center max-w-4xl m-auto">
          This website contains analytical cookies, accepted by visitor unless they are disactivated through the browser configuration.
        </div>
        <div className="flex w-fit m-auto space-x-8"> 
          <a className="cursor-pointer hover:underline font-bold" href={PrivacyPolicy} download>
            Privacy Policy
          </a>
          <a className="cursor-pointer hover:underline font-bold" href={PrivacyPolicy}>
            Terms and Conditions
          </a>
        </div>
      </div>
    </div>
  )
}