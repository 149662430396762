import React, { useState } from "react";
import { calendarRandomData } from "assets/files/images/calendar-random-data";

export const CalendarOverview = (props) => {
  const [index, setIndex] = useState(0)

  const width = 5
  
  let daysArr = [
    "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"
  ]

  let monthsArr = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ]

  const getOpacity = () => {
    return "bg-mainColor"
  } 

  const isEven = (input) => {
    return input % 2 === 0
  }

  const getBg = (input) => {
    //console.log(input/10)
    if(input < 2)  return `bg-green-100`
    else if(input > 1 && input < 3)  return `bg-green-200`
    else if(input > 2 && input < 4)  return `bg-green-300`
    else if(input > 3 && input < 5)  return `bg-green-400`
    else if(input > 4 && input < 6)  return `bg-green-500`
    else if(input > 5 && input < 7)  return `bg-green-600`
    else if(input > 6 && input < 8)  return `bg-green-700`
    else if(input > 7 && input < 9)  return `bg-green-800`
    else if(input > 8 && input < 10)  return `bg-green-900` 
  }

  return(
    <div className="mt-4 bg-white dark:bg-gray-400 rounded-lg w-full mb-12 mr-5 pb-12 overflow-x-scroll p-5 max-w-[80vw]">  
      <div className="flex ml-[50px] pb-2">
        {monthsArr.map(item => (
          <div className="h-[25px] w-[124px]" key={item}>
            {item}
          </div> 
        ))}
      </div>
      <div className="flex">
        <div className="pr-5">
          {daysArr.map(item => (
            <div className="h-[25px]" key={item}>
              {item}
            </div> 
          ))}
        </div>
        <div className="flex-1 grid grid-rows-7 grid-flow-col h-fit "> 
          {calendarRandomData.map(item => ( 
            <div className={`p-1 aspect-square w-full h-[25px] border-2 rounded-sm ${getBg(item.range)} hover:border-gray-500 animate`} > 
            </div>
          ))}
        </div>  
      </div>
      
      {/* <div className=" mr-5 space-y-[3px] pt-8">
        {daysArr.map(item => (
          <div className="font-light text-small text-gray-400 dark:text-gray-200">
            {item}
          </div>
        ))} 
      </div>
      <div className=" "> 
        <div className="max-w-[85%] overflow-x-scroll"> 
          <div className="flex ">
            {monthsArr.map(item => (
              <div className="font-light text-gray-400 dark:text-gray-200 min-w-[calc(23px*4)] ">
                {item}
              </div>
            ))} 
          </div> 
          <div className={`grid grid-rows-8 grid-flow-col min-w-[28px] max-w-[30px]`}>   
            {calendarRandomData.map(( item, index ) => ( 
              <div className={`text-mainColor w-[25px] hover:border-secondaryColor px-2 aspect-square border-2 border-white dark:border-gray-400 rounded cursor-pointer m-auto ${getOpacity()} `} > 
                {1}
              </div> 
            ))}  
          </div>
        </div> 
      </div> */}
    </div>
  )
}