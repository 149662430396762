import * as React from "react"

function CoinsIconYellow(props) {
  return (
    <svg
      width={41}
      height={35}
      viewBox="0 0 41 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M38.667 24.833a8.333 8.333 0 01-8.334 8.334 8.294 8.294 0 01-4.105-1.08A8.341 8.341 0 0122 24.834c0-2.14.807-4.091 2.132-5.566a8.311 8.311 0 016.201-2.767 8.333 8.333 0 018.334 8.333z"
        stroke="#010101"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.336 9v7.5a8.311 8.311 0 00-6.202 2.767 8.302 8.302 0 00-2.131 5.566 8.341 8.341 0 004.228 7.255c-2.218.67-5.163 1.079-8.395 1.079-6.903 0-12.5-1.866-12.5-4.167V9"
        stroke="#010101"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.336 22.333c0 2.301 5.597 4.167 12.5 4.167 1.506 0 2.95-.088 4.287-.252M5.336 15.667c0 2.3 5.597 4.166 12.5 4.166 2.297 0 4.45-.206 6.298-.566m9.535 5.566h-3.333V21.5m0-12.5c0 2.3-5.597 4.167-12.5 4.167S5.336 11.3 5.336 9c0-2.3 5.597-4.167 12.5-4.167S30.336 6.7 30.336 9z"
        stroke="#010101"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.667 21.833a8.333 8.333 0 01-8.334 8.334 8.294 8.294 0 01-4.105-1.08A8.341 8.341 0 0119 21.834c0-2.14.807-4.091 2.132-5.566a8.311 8.311 0 016.201-2.767 8.333 8.333 0 018.334 8.333z"
        stroke="#F1D848"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.336 6v7.5a8.311 8.311 0 00-6.202 2.767 8.302 8.302 0 00-2.131 5.566 8.341 8.341 0 004.228 7.255c-2.218.67-5.163 1.079-8.395 1.079-6.903 0-12.5-1.866-12.5-4.167V6"
        stroke="#F1D848"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.336 19.333c0 2.301 5.597 4.167 12.5 4.167 1.506 0 2.95-.088 4.287-.252M2.336 12.667c0 2.3 5.597 4.166 12.5 4.166 2.297 0 4.45-.206 6.298-.566m9.535 5.566h-3.333V18.5m0-12.5c0 2.3-5.597 4.167-12.5 4.167S2.336 8.3 2.336 6c0-2.3 5.597-4.167 12.5-4.167S27.336 3.7 27.336 6z"
        stroke="#F1D848"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CoinsIconYellow
