import axios from 'axios';

export const AddContact = async (contactInput) => {

  contactInput.currentCountries = contactInput.currentCountries.toString();
  contactInput.prefCountries = contactInput.prefCountries.toString();
  
  const requestBody = {
    query: `
      mutation AddContact($input: ContactInputType) {
        AddContact(input: $input) {
          _id
          currentSystem
          currentCountries
          prefCountries
          currentProducts
          email
          checkbox
        }
      }
    `,
    variables: {
      input: contactInput,
    },
  };

  try {
    const response = await axios.post(process.env.REACT_APP_API, requestBody, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    const addedContact = response.data.data.addContact;
    return addedContact;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to add contact.');
  }
};
