import axios from "axios";

const DownloadAllPaymentHistoryMutation = `
mutation DownloadAllPaymentHistory($email: String)  {
    DownloadAllPaymentHistory (email: $email)
}`;

const DownloadAllPaymentHistory = async (data, token) => {
    const res = await axios({
        url: process.env.REACT_APP_API,
        method: "post",
        data: {
            query: DownloadAllPaymentHistoryMutation,
            variables: {
                ...data,
            },
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
    });

    return res.data.data.DownloadAllPaymentHistory;
};

export { DownloadAllPaymentHistory };
