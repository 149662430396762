import axios from "axios";

const CalculateTestScoreQuery = `
query CalculateTestScore($verifierId: String)  {
    CalculateTestScore(verifierId: $verifierId)
}`;

const CalculateScore = async (data, token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: CalculateTestScoreQuery,
      variables: {
        ...data,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data.CalculateTestScore;
};

export { CalculateScore };
