import { GridCardHome } from "assets/components/grid-cards/GridCardHome";
import React from "react";

import shoeImg from './../img/card-images/shoe-crop.png'
import watchImg from './../img/card-images/watch-crop.png'
import clothesImg from './../img/card-images/jacket-crop.png'
import bagsImg from './../img/card-images/bag-crop.png'
import jewelryImg from './../img/card-images/jewelry-crop.png'
import otherImg from './../img/card-images/other-crop.png'

export const NewVerification = (props) => {

  let newVerificationCards = [
    {
      name: "Shoes",
      img: shoeImg
    },
    {
      name: "Clothes",
      img: clothesImg
    },
    {
      name: "Bags",
      img: bagsImg
    },
    {
      name: "Jewelry",
      img: jewelryImg
    },
    {
      name: "Other",
      img: otherImg
    },
  ]

  return(
    <div>
      <div className="text-xl font-semibold py-4 px-6">
        Select a product category
      </div>
      <div className="grid grid-cols-3 px-3">
        {newVerificationCards.map((item) => (
          <GridCardHome
            product={item.name.toLocaleLowerCase()}
            card={item}
          />
        ))}
      </div>
    </div>
  )
}
