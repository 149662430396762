import React from "react";
import { useNavigate } from "react-router-dom";
export const GridCardHome = (props) => {
  const navigate = useNavigate()

  return (
    <div 
      className="bg-vipGreen aspect-video m-4 rounded-xl center-items hover:shadow-xl cursor-pointer animate hover:opacity-90 bg-cover text-white relative" 
      onClick={()=>{
        if(props.product) navigate(`/create/${props.product}`)
        else navigate(props.card.slug)
      }} 
      style={{
        backgroundImage: `url(${props.card.img})`
      }}
    >
      <div className="h-full w-full bg-vipGreen rounded-xl hover:opacity-40 opacity-80 animate center-items">
      </div>
      {(props.requests?.length > 0 && props.card.name == "Pending verifications") && 
        <div className="absolute -top-4 -right-4 bg-red-500 w-14 h-14 rounded-full grid place-content-center text-base z-20">
          {(props.pending.length + props.requests.length)} / {Math.ceil((props.pending.length + props.requests.length) * 1.1)}
        </div> 
      }
      {(props.completed?.length > 0 && props.card.name == "Completed verifications") && 
        <div className="absolute -top-4 -right-4 bg-red-500 w-14 h-14 rounded-full grid place-content-center text-base z-20">
          {props.completed.length}
        </div> 
      }
      <div className="absolute text-2xl ">
        {props.card.name} 
      </div>
    </div>
  )

}