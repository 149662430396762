import axios from 'axios'

const GetStatisticsQuery = `
query GetStatistics ($startDate: String , $endDate: String ){
  GetStatistics (startDate: $startDate, endDate: $endDate)
}`

const GetStatistics = async (data) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: GetStatisticsQuery,
      variables: {
        ...data
      }
    }
  })

  return res.data.data.GetStatistics

}

export {
  GetStatistics
}
