import { PlusIcon } from "@heroicons/react/solid";
import React from "react";
import { useNavigate } from "react-router-dom";


export const GridCardCreate = (props) => {
  const navigate = useNavigate()

  const handleClick = () => {
    let arr = props.imgArr
    console.log('props.imgArr', props.imgArr)
    let obj = {
      key: ""
    }
    arr.push(obj)
    props.setImgArr([...arr])
   }

  return (
    <div
      className="aspect-video m-4 rounded-xl center-items hover:shadow-xl cursor-pointer animate hover:opacity-90 bg-cover overflow-hidden text-white bg-gray-300 "
      onClick={()=>handleClick()}
    >
      <div className="h-full w-fullanimate center-items">
      </div>
      <div className="absolute text-2xl text-gray-100">
        <div className="m-auto w-fit text-md ">
          Add Another
        </div>
        <div className="w-[100px] m-auto">
          <PlusIcon />
        </div>

      </div>
    </div>
  )

}
