import React, { useState } from "react";
import { auth } from '../../firebase'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import FacebookIcon from "assets/img/card-images/facebook-icon";
import GoogleIcon from "assets/img/card-images/google-icon";
import LinkedinIcon from "assets/img/card-images/linkedin-icon";
import { EyeIcon } from "@heroicons/react/solid";
import { CreateUser } from "api/CreateUser.js";
import { CheckActivationKey } from "api/CheckActivationKey.js";
import { GetUserAccount } from 'api/GetUserAccount.js'
import { UpdateUserAccount } from 'api/UpdateUserAccount.js'
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import vipLogo from "assets/files/images/vip-logo-mock.png"

export const AccountSettings = (props) => {
  const headers = localStorage.getItem('token')

  const navigate = useNavigate()
  const [showPass, setShowPass] = useState(false)
  const [userType, setUserType] = useState("verifier")
  // const [allowedKey, setAllowedKey] = useState()
  const [allowedKey, setAllowedKey] = useState(true)
  const [init, setInit] = useState(false)

  // const [user, setUser] = useState({
  //   firstName: "John",
  //   lastName: "Doe",
  //   country:"Germany",
  //   city:"Frankfurt",
  //   dob: "14-4-1898",
  //   gender: "Other",
  //   phone:"+3164456789",
  //   email: "john.doe@germany.com",
  //   pass:"123456789",
  //   type: "verifier",
  // })

  const [user, setUser] = useState({})
  // const [page, setPage] = useState(1)

  const updateUserInfo = () => {
    console.log('Make API call to update here')
    UpdateUserAccount({
      user: user
      }, headers)
      .then((out) => {
        console.log('Updated', out)
      })
  }

  //Get user info
  useEffect( () => {
    GetUserAccount(headers)
      .then((out) => {
        console.log('User', out)
        setUser({...out})
      })
  }, [])

  const handleChange = (input, handle) => {
    let obj = user
    obj[handle] = input
    setUser({...obj})
  }

  const getDisabled = () => {
    if(userType === "verifier"){
      if(
          user.firstName == "" ||
          user.lastName == "" ||
          user.country == "" ||
          //user.city ||
          user.dob == "" ||
          //user.gender ||
          user.phone == "" ||
          user.email == ""
        ) return true
        else return false
      }
    else if(userType === "marketplace"){
      if(
        user.fullName == "" ||
        user.email == ""
      ) return true
      else return false
    }
  }

  return(
    <div className="h-screen w-screen">
      <div className="w-fit m-auto">
        <div className="font-xl pt-24 font-semibold text-xl pb-4">
          Account Settings
        </div>
      </div>
     <div className="max-w-2xl m-auto text-center flex">
          <div className="w-1/2">
            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                Name
              </div>
              <div className="">
                <input
                  onChange={(e)=>handleChange(e.target.value, "firstName")}
                  value={user.firstName}
                  placeholder="First name"
                  type="text"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
            </div>
            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                Surname
              </div>
              <div className="">
                <input
                  onChange={(e)=>handleChange(e.target.value, "lastName")}
                  value={user.lastName}
                  placeholder="Surname"
                  type="text"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
            </div>
            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                Country
              </div>
              <div className="">
                <input
                  onChange={(e)=>handleChange(e.target.value, "country")}
                  value={user.country}
                  placeholder="Country"
                  type="text"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
            </div>

            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                E-mail
              </div>
              <div className="">
                <input
                  disabled
                  value={user.email}
                  onChange={(e)=>handleChange(e.target.value, "email")}
                  placeholder="Email address"
                  type="email"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
            </div>

          </div>
          <div className="w-1/2">
            {/* <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                Date of birth
              </div>
              <div className="">
                <input
                  onChange={(e)=>handleChange(e.target.value, "dob")}
                  placeholder="Date of birth"
                  value={user.dob}
                  type="date"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
            </div> */}
            {/* <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                Gender
              </div>
              <div className="flex">
                <div className="mx-2">
                  <input
                    value={user.gender}
                    onChange={(e)=>handleChange("male", "gender")}
                    name="radio"
                    type="radio"
                    className="mr-2 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                  />
                  Male
                </div>
                <div className="mx-2">
                  <input
                    onChange={(e)=>handleChange("female", "gender")}
                    name="radio"
                    type="radio"
                    className="mr-2 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                  />
                  Female
                </div>
                <div className="mx-2">
                  <input
                    onChange={(e)=>handleChange("other", "gender")}
                    name="radio"
                    type="radio"
                    className="mr-2 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                  />
                  Other
                </div>
              </div>
            </div> */}
            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                City
              </div>
              <div className="">
                <input
                  value={user.city}
                  onChange={(e)=>handleChange(e.target.value, "city")}
                  placeholder="City"
                  type="text"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
            </div>
            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                Phone Number
              </div>
              <div className="">
                <input
                  value={user.phone}
                  onChange={(e)=>handleChange(e.target.value, "phone")}
                  placeholder="Phone number"
                  type="text"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
            </div>
            <div className="w-fit m-auto text-left py-1">
              <div className="py-1 text-sm">
                Password
              </div>
              <div className="">
                <input
                  value={user.password}
                  onChange={(e)=>handleChange(e.target.value, "password")}
                  placeholder="********"
                  type="text"
                  className="md:w-[240px] pl-2 text-sm py-1 rounded ring-0 focus:ring-0 focus:outline-vipGreen border-[1px] border-vipGreen focus:shadow-lg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex w-fit m-auto space-x-8">
          <div className="button-cancel w-fit my-8" onClick={()=>navigate("/home")}>
            Go back
          </div>
          <button className="button-confirm w-fit my-8 disabled:bg-gray-300 disabled:border-gray-400 disabled:text-gray-400 disabled:hover:shadow-none" disabled={getDisabled()} onClick={()=>{
            // navigate('/home')
            props.notify("Changes saved successfully.")
            console.log('Updated User', user)
            updateUserInfo()
          }}>
            Save Changes
          </button>
        </div>
      </div>

  )
}
