import { RefreshIcon, SearchIcon, TrashIcon } from "@heroicons/react/solid";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";


export const GridCardImageUpload = (props) => {
  const navigate = useNavigate()
  const inputFile = useRef(null)
  const [img, setImg] = useState('')

  const [open, setOpen] = useState(false);

  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
    // console.log('inputFile', inputFile)
  };

  const getBorderColor = (input) => {
    if (input === 0) return " border-gray-300 "
    else if (input == 2) return " border-green-500 "
    else if (input == 1) return " border-red-500 "
  }


  return (
    <div className="">
      <div
        className={`bg-center bg-vipGreen border-4 border-gray-500 aspect-video m-4 mb-2 rounded-xl center-items hover:shadow-xl cursor-pointer animate hover:opacity-100 bg-cover overflow-hidden text-white`}
        /* onClick={()=>{
           if(props.product) navigate(`/create/${props.product}`)
           else navigate(props.card.slug)
         }} */
        onClick={() => {
          if (props.card.key) {
            setOpen(true)
            props.setSelectedImage(props.card)
            props.setImageIndex(props.index)
            props.handleRefClick()
            //navigate(`/review/${props.productId}/${props.card.key}`)
          }
          else onButtonClick()
        }}
        style={{
          backgroundImage: `url(${`https://vip-cms.ams3.digitaloceanspaces.com/` + props.card.key})`
        }}
      >
        {props.selectedImage !== props.card &&
          <div className="h-full w-full bg-vipGreen hover:opacity-0 opacity-40 animate center-items">
          </div>}
        <div className="absolute text-2xl">
          <div className="text-shadow">
            <input type="file" onChange={props.handleUpload} ref={inputFile} style={{ display: 'none' }} />
            {props.card.key ? 'View' : 'Upload img'}
          </div>
        </div>
      </div>
      <div className="max-w-[70%] m-auto flex justify-between text-gray-400 pb-8">
        <div className="flex center-items cursor-pointer hover:text-gray-600 animate">
          {
            props.verifier ?
              <div className="flex center-items" onClick={() => navigate(`/review/${props.productId}/${props.card.key}`)}>
                <div className="w-[20px]">
                  <SearchIcon />
                </div>
                <div className="ml-2" >
                  View
              </div>
              </div> : (
                <>
                  <div className="w-[20px]">
                    <SearchIcon />
                  </div>
                  <div className="ml-2">
                    View
              </div>
                </>
              )}
        </div>
        {!props.view &&
          <>
            <div className="flex center-items cursor-pointer hover:text-gray-600 animate">
              <div className="w-[20px]">
                <RefreshIcon />
              </div>
              <div className="ml-2">
                Change
            </div>
            </div>
            <div className="flex center-items cursor-pointer hover:text-gray-600 animate">
              <div className="w-[20px]">
                <TrashIcon />
              </div>
              <div className="ml-2">
                Delete
            </div>
            </div>
          </>}
      </div>
    </div>
  )

}
