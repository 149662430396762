import * as React from "react"

function TimesThreeYellow(props) {
  return (
    <svg
      width={228}
      height={51}
      viewBox="0 0 228 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.36 46l9.4-12.24-.08 2.68-8.96-11.8h5.56l6.28 8.4h-2.12l6.32-8.4h5.4l-9.04 11.8.04-2.68L25.52 46h-5.64l-6.6-8.92 2.08.28L8.88 46H3.36zm33.13.4c-1.974 0-3.907-.28-5.8-.84-1.867-.587-3.44-1.387-4.72-2.4l2.24-4.04c1.013.827 2.24 1.493 3.68 2 1.44.507 2.946.76 4.52.76 1.866 0 3.32-.373 4.36-1.12 1.04-.773 1.56-1.813 1.56-3.12 0-1.28-.48-2.293-1.44-3.04-.96-.747-2.507-1.12-4.64-1.12h-2.56v-3.56l8-9.52.68 1.96H27.33V18h19.08v3.48l-8 9.52-2.72-1.6h1.56c3.44 0 6.013.773 7.72 2.32 1.733 1.52 2.6 3.48 2.6 5.88a8.32 8.32 0 01-1.2 4.36c-.8 1.333-2.027 2.413-3.68 3.24-1.627.8-3.694 1.2-6.2 1.2zm35.616 0c-1.947 0-3.694-.32-5.24-.96-1.547-.64-2.76-1.52-3.64-2.64-.88-1.12-1.32-2.413-1.32-3.88 0-1.333.293-2.533.88-3.6.586-1.067 1.52-2.08 2.8-3.04 1.28-.987 2.92-1.973 4.92-2.96 1.573-.773 2.8-1.453 3.68-2.04.906-.587 1.533-1.147 1.88-1.68.373-.533.56-1.093.56-1.68 0-.8-.28-1.44-.84-1.92-.56-.507-1.36-.76-2.4-.76-1.12 0-2 .28-2.64.84-.614.533-.92 1.24-.92 2.12 0 .453.08.893.24 1.32.186.427.506.907.96 1.44.453.533 1.106 1.213 1.96 2.04l14.8 14.2-2.76 3.24-16-15.36c-.907-.88-1.64-1.693-2.2-2.44-.56-.747-.96-1.467-1.2-2.16a6.914 6.914 0 01-.36-2.2c0-1.307.333-2.453 1-3.44.666-1.013 1.613-1.787 2.84-2.32 1.226-.56 2.653-.84 4.28-.84 1.52 0 2.826.253 3.92.76 1.12.48 1.986 1.187 2.6 2.12.64.907.96 2 .96 3.28 0 1.12-.28 2.16-.84 3.12-.56.933-1.44 1.827-2.64 2.68-1.174.853-2.747 1.747-4.72 2.68-1.467.72-2.627 1.4-3.48 2.04-.854.64-1.467 1.28-1.84 1.92a4.131 4.131 0 00-.56 2.12c0 .773.226 1.453.68 2.04.48.587 1.146 1.053 2 1.4.853.32 1.853.48 3 .48 1.706 0 3.253-.373 4.64-1.12 1.413-.773 2.6-1.92 3.56-3.44s1.653-3.4 2.08-5.64l4.04 1.32c-.56 2.693-1.534 5.013-2.92 6.96-1.36 1.92-3.04 3.4-5.04 4.44-2 1.04-4.24 1.56-6.72 1.56zm27.817-.4l9.4-12.24-.08 2.68-8.96-11.8h5.56l6.28 8.4h-2.12l6.32-8.4h5.4l-9.04 11.8.04-2.68 9.36 12.24h-5.64l-6.601-8.92 2.081.28-6.481 8.64h-5.52zm22.523-6.36v-3.6l13.8-18.04h5.56l-13.6 18.04-2.6-.8h22.2v4.4h-25.36zm15.36 6.36v-6.36l.16-4.4V29.6h4.88V46h-5.04z"
        fill="#010101"
      />
      <path
        d="M.36 44l9.4-12.24-.08 2.68-8.96-11.8h5.56l6.28 8.4h-2.12l6.32-8.4h5.4l-9.04 11.8.04-2.68L22.52 44h-5.64l-6.6-8.92 2.08.28L5.88 44H.36zm33.13.4c-1.974 0-3.907-.28-5.8-.84-1.867-.587-3.44-1.387-4.72-2.4l2.24-4.04c1.013.827 2.24 1.493 3.68 2 1.44.507 2.946.76 4.52.76 1.866 0 3.32-.373 4.36-1.12 1.04-.773 1.56-1.813 1.56-3.12 0-1.28-.48-2.293-1.44-3.04-.96-.747-2.507-1.12-4.64-1.12h-2.56v-3.56l8-9.52.68 1.96H24.33V16h19.08v3.48l-8 9.52-2.72-1.6h1.56c3.44 0 6.013.773 7.72 2.32 1.733 1.52 2.6 3.48 2.6 5.88a8.32 8.32 0 01-1.2 4.36c-.8 1.333-2.027 2.413-3.68 3.24-1.627.8-3.694 1.2-6.2 1.2zm35.616 0c-1.947 0-3.694-.32-5.24-.96-1.547-.64-2.76-1.52-3.64-2.64-.88-1.12-1.32-2.413-1.32-3.88 0-1.333.293-2.533.88-3.6.586-1.067 1.52-2.08 2.8-3.04 1.28-.987 2.92-1.973 4.92-2.96 1.573-.773 2.8-1.453 3.68-2.04.906-.587 1.533-1.147 1.88-1.68.373-.533.56-1.093.56-1.68 0-.8-.28-1.44-.84-1.92-.56-.507-1.36-.76-2.4-.76-1.12 0-2 .28-2.64.84-.614.533-.92 1.24-.92 2.12 0 .453.08.893.24 1.32.186.427.506.907.96 1.44.453.533 1.106 1.213 1.96 2.04l14.8 14.2-2.76 3.24-16-15.36c-.907-.88-1.64-1.693-2.2-2.44-.56-.747-.96-1.467-1.2-2.16a6.914 6.914 0 01-.36-2.2c0-1.307.333-2.453 1-3.44.666-1.013 1.613-1.787 2.84-2.32 1.226-.56 2.653-.84 4.28-.84 1.52 0 2.826.253 3.92.76 1.12.48 1.986 1.187 2.6 2.12.64.907.96 2 .96 3.28 0 1.12-.28 2.16-.84 3.12-.56.933-1.44 1.827-2.64 2.68-1.174.853-2.747 1.747-4.72 2.68-1.467.72-2.627 1.4-3.48 2.04-.854.64-1.467 1.28-1.84 1.92a4.131 4.131 0 00-.56 2.12c0 .773.226 1.453.68 2.04.48.587 1.146 1.053 2 1.4.853.32 1.853.48 3 .48 1.706 0 3.253-.373 4.64-1.12 1.413-.773 2.6-1.92 3.56-3.44s1.653-3.4 2.08-5.64l4.04 1.32c-.56 2.693-1.534 5.013-2.92 6.96-1.36 1.92-3.04 3.4-5.04 4.44-2 1.04-4.24 1.56-6.72 1.56zm27.817-.4l9.4-12.24-.08 2.68-8.96-11.8h5.56l6.28 8.4h-2.12l6.32-8.4h5.4l-9.04 11.8.04-2.68 9.36 12.24h-5.64l-6.601-8.92 2.081.28-6.481 8.64h-5.52zm22.523-6.36v-3.6l13.8-18.04h5.56l-13.6 18.04-2.6-.8h22.2v4.4h-25.36zm15.36 6.36v-6.36l.16-4.4V27.6h4.88V44h-5.04z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default TimesThreeYellow
