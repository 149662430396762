import { ChevronRightIcon, SaveIcon, StarIcon, TrashIcon } from "@heroicons/react/solid";
import React from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
//import ReactTextareaAutosize from "react-textarea-autosize";
import { Tooltip } from "../tooltip/Tooltip";
import { RegionDropdown } from "./RegionDropdown";
import { ReviewRequest } from 'api/ReviewRequest.js';
import { useNavigate } from "react-router-dom";
export const RegionSidebarProductVerification = ({ notify, productDetail, setProductDetail, setImageVerdicts, imageVerdicts, selectedRegion, setSelectedRegion, regions, handleRegionDataChange, removeRegion, createRegion, setCreateRegion, testVerificationData, setTestVerificationData, selectedImage, questionIndex, imageIndex, setImageIndex }) => {
  const likertScale = [1, 2, 3, 4]
  const [confidence, setConfidence] = useState(0)
  const [secondOpinion, setSecondOpinion] = useState(false)
  const [testMode, setTestMode] = useState(window.location.href.includes('start-test') ? true : false)
  const params = useParams()
  const [decision, setDecision] = useState(0)
  const navigate = useNavigate();
  const headers = localStorage.getItem('token')

  const reviewRequest = (decision, x) => {
    ReviewRequest({
      uuid: productDetail.uuid,
      comments: productDetail.files,
      verdict: decision,
      secondOpinionRequested: secondOpinion,
      //TO DO CONFIDENCE LEVEL
    }, headers)
      .then((out) => {
        alert('Success')
        notify("✓ Success.")

      })
  }

  const saveAnswers = (verdict, x) => {
    console.log("save")
  }

  const getTrues = () => {
    let number = 0
    imageVerdicts.forEach(item => item == 1 ? number = number + 1 : null)
    return number
  }

  const getButtonColors = (input, yes) => {
    let grayButton = "bg-gray-100 text-gray-300 px-6 py-1 border-2 border-gray-400 rounded-lg cursor-pointer"
    let otherButton = "button-confirm  "
    if (productDetail.verdict) {
      if (productDetail.verdict === 0) return otherButton
      if (productDetail.verdict === 1) {
        if (yes) return otherButton
        else if (!yes) return grayButton
      }
      if (productDetail.verdict === 2) {
        if (!yes) return otherButton
        else if (yes) return grayButton
      }
    }
    else return otherButton
  }

  // const getButtonColors = (input, bool) => {
  //   let grayButton = "bg-gray-100 text-gray-300 px-6 py-1 border-2 border-gray-400 rounded-lg cursor-pointer"
  //   let otherButton = input
  //   let item = imageVerdicts[imageIndex]
  //   console.log('ver', bool)
  //   if(imageVerdicts[imageIndex]){
  //     if(item === 0){
  //       return otherButton
  //     }
  //     if(item === 1) {
  //       if(bool) return otherButton
  //       else if(!bool) return grayButton
  //     }
  //     if(item === 2) {
  //       if(!bool) return otherButton
  //       else if(bool) return grayButton
  //     }
  //   }
  //   else return otherButton
  // }

  const setVerdict = (input) => {
    let detail = productDetail
    detail.verdict = input
    setProductDetail({ ...detail })
  }

  return (<>
    <div className="bg-white rounded-xl p-5 border-2 border-vipGreen ml-4 mt-4">
      {/* <div className="border-b-[1px] mb-4 font-semibold border-vipGreen">
        {selectedRegion > -1 ? "Edit Region" : "Select a region to start"}
      </div> */}
      <div className="w-full">
        <div className="font-semibold text-lg">
          Product Information
        </div>
      </div>
      <div className="mt-2 flex" >
        How confident are you about this product?
        </div>
      <div className="text-sm text-gray-500 cursor-pointer">
        <Tooltip title="info*">
          <div className="text-black text-normal">
            <div className="font-semibold">
              Verdict explanation:
              </div>
            <div className="py-1">
              1 Star: Very unconfident
              </div>
            <div className="py-1">
              2 Stars: Unconfident
              </div>
            <div className="py-1">
              3 Stars: Confident
              </div>
            <div className="py-1">
              4 Stars: Very Confident
              </div>
          </div>
        </Tooltip>
      </div>

      <div className="flex justify-between" >
        {likertScale.map((item, index) => (
          <div>
            <div className="form-check form-check-inline flex cursor-pointer" onClick={() => setConfidence(index)}>
              {/* <input className="radio-button form-check-input form-check-input " type="radio" name="inlineRadioOptions" id="inlineRadio1" value={item} onChange={(e)=> setConfidence(e.target.value)}/> */}
              <div className={`${confidence >= index ? "text-yellow-500" : "text-gray-300"} pt-[2px] animate`}>
                <StarIcon width={20} />
              </div>
              {/* <label className="form-check-label inline-block text-gray-800" >
                  {item}
                </label> */}
            </div>
          </div>
        ))}
      </div>
      {getTrues() > 0 &&
        <div className="mt-4">
          You were not sure about {getTrues()} out of {productDetail.files.length} images, would you like to go over them again?
          </div>
      }
      {useLocation().pathname != "/start-test" &&
        <div className="mt-3 flex" >
          Request a second opinion?
          <div className="w-fit ml-auto">
            <input
              type="checkbox"
              onChange={() => setSecondOpinion(!secondOpinion)}
              className="h-5 w-5 ml-4 mt-[1px] rounded"
              checked={secondOpinion}
            />
          </div>
        </div>}
      <div className="mt-4">
        <div>
          Final comment on entire product:
          </div>
        <textarea
          placeholder="This comment will be displayed on the certificate."
          rows={3}
          className="w-full border-[1px] border-gray-200 rounded mt-3 focus:ring-1 focus:outline-none ring-gray-200 py-1 px-2"
        />
      </div>
      <div className="mt-8 flex" >
        <div className={`${getButtonColors("button-cancel", true)}`} onClick={() => {
          setDecision(1)
          setVerdict(1)
          if (!testMode) reviewRequest(1, regions)
          else saveAnswers(decision, regions[selectedRegion])
        }}>
          Fake
        </div>
        <div className={`${getButtonColors("button-confirm", false)} ml-auto`} onClick={() => {
          setDecision(2)
          setVerdict(2)
          if (!testMode) reviewRequest(2, regions)
          else saveAnswers(decision, regions[selectedRegion])
        }}>
          Authentic
        </div>

      </div>
    </div>
  </>)
}
