import React, { useEffect, useState } from "react";
//import objectAssign from 'object-assign';
import { RegionSelect } from "assets/react-region-select/lib/RegionSelect";
//import { click } from "@testing-library/user-event/dist/click";
import { SaveIcon, TrashIcon } from "@heroicons/react/solid";
import ReactTextareaAutosize from "react-textarea-autosize";
import { useNavigate, useParams } from "react-router-dom";
import { ReviewRequest } from "api/ReviewRequest.js";
import { Tooltip } from "assets/components/tooltip/Tooltip";
import { RegionSidebar } from "assets/components/region-sidebar/RegionSidebar";
import { GetRequest } from "api/GetRequest";
//import { Popover } from "@headlessui/react";
import { getHeaders } from '../../firebase'

export const ImageEdit = (props) => {
  const params = useParams()
  let dataObj1 = {
    comment: "",
    dropdown: 1,
    selected: false
  }

  let dataObj2 = {
    comment: "",
    dropdown: 2,
    selected: false
  }
  const navigate = useNavigate()
  const [init, setInit] = useState(false)
  const [productDetail, setProductDetail] = useState()
  const [files, setFiles] = useState([])
  const [createRegion, setCreateRegion] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState(-1)
  const [mouseDown, setMouseDown] = useState(false)
  const [clickedHandle, setClickedHandle] = useState("")
  const headers = localStorage.getItem('token')

  const [regions, setRegions] = useState(
    [
      {
        x: 22,
        y: 60,
        width: 16,
        height: 15,
        data: dataObj1
      },
      {
        x: 27,
        y: 27,
        width: 33,
        height: 24,
        data: dataObj2
      }
    ])

  useEffect(()=>{
    if(!init){
      setInit(!init)
      GetRequest({uuid: "74204771-6ec3-4ca7-b398-df4c5054c50e"}, headers)
        .then((out) => {
          console.log('out', out)
          setProductDetail({...out})
          setFiles([...out.files])
          
        })
    }
  })


  const removeRegion = (input) => {
    setSelectedRegion(-1)
    let arr = regions
    arr.splice(input, 1)
    setRegions([...arr])
  }

  const regionRenderer = (regionProps) => {
 			return (<>
        {regionProps.selected &&
        <div onClick={()=>removeRegion(regionProps.index)}
        >
          {/* <div
            onClick={()=>removeRegion(regionProps.index)}
            data-wrapper={regionProps.index}
            className="bg-white absolute z-10 -top-9 -right-1 w-[25px] h-[25px] opacity-80 hover:opacity-100 animate"
          >
            <div className="rounded border-2 text-gray-300 cursor-pointer hover:text-red-400 hover:border-red-400 animate">
              <TrashIcon />
            </div>
           </div>
          <div className="absolute -bottom-8 -left-1 flex w-full">
            <select
              className="border-2 border-gray-500 rounded text-black h-[30px]"
              onChange={(event) => {
                changeRegionData(regionProps.index, event)
              }}
              value={regionProps.data.dropdown}
            >
              <option value='1'>Logo</option>
              <option value='2'>Lace</option>
              <option value='3'>Sole</option>
            </select>
            <div className="ml-2 w-full bg-white">
              <div className="border-b-[1px] mr-4">
                Comment:
              </div>
              <div className="w-full">
                <input
                  className="w-[90%] m-2 ml-0"
                  type="textarea"
                  rows="5"
                />
              </div>
            </div>
          </div>  */}
        </div>}
      </>);
  }

  const handleChange = (data, index) => {
     let _regions = data
    setRegions([..._regions])
  }

  // useEffect(()=>{
  //   console.log('test')
  //   regions.forEach((item, index) => {
  //     console.log('test')
  //     updateRegionSelected(index)
    
  //   })
  // },[selectedRegion, regions.length])


  // const updateRegionSelected = (input) => {
  //   console.log('input', input, 'selectedRegion', selectedRegion)
  //   let _arr = regions
  //   //console.log('bool', input == selectedRegion)
  //   _arr[input].selected = input == selectedRegion
  //   setRegions([..._arr])
  // }

  // const changeRegionData = (index, event) => {
 	// 	//let region = regions[index];
  //   let _regions = regions
  //   _regions[index].data.dropdown = event.target.value

  //   setRegions([..._regions])
	// 	// let color;
	// 	// switch (event.target.value) {
  //   //   case '1':
  //   //     color = 'rgba(0, 255, 0, 0.5)';
  //   //     break;
  //   //   case '2':
  //   //     color = 'rgba(0, 0, 255, 0.5)';
  //   //     break;
  //   //   case '3':
  //   //     color = 'rgba(255, 0, 0, 0.5)';
  //   //     break;
  //   //   default:
  //   //     color = 'rgba(0, 0, 0, 0.5)';
  //   // }

	// 	// region.data.regionStyle = {
	// 	// 	background: color
	// 	// };

	// 	// this.onChange([
	// 	// 	...this.state.regions.slice(0, index),
	// 	// 	objectAssign({}, region, {
	// 	// 		data: objectAssign({}, region.data, { dataType: event.target.value })
	// 	// 	}),
	// 	// 	...this.state.regions.slice(index + 1)
	// 	// ]);
	// }

  const regionStyle = {
    //background: 'rgba(255, 255, 255, 0.5)',
    //borderColor: "red",
  }

  // const setRegion = (e) => {
  //   if(!e.target.dataset.wrapper) setSelectedRegion(-1)
  // }

  const handleRegionDataChange = (e) => {
    let _arr = regions
    _arr[selectedRegion].data.comment = e.target.value
    setRegions([..._arr])
  }

  return(
    <div className=" ">
      <div className="flex">
        {productDetail?.files.map((item, index) => (
        <div className="w-[300px]" onClick={()=>navigate(`/review/4f97c629-3004-4a4d-b6ac-74e0cbfd6578/${productDetail.files[index].key}`)}>
          <div
            className="bg-vipGreen aspect-video m-4 mb-2 rounded-xl center-items hover:shadow-xl cursor-pointer animate hover:opacity-90 bg-cover overflow-hidden text-white"
            style={{
              backgroundImage: `url(${`https://vip-cms.ams3.digitaloceanspaces.com/`+ productDetail?.files[index].key})`
            }}
          >
            <div className="h-full w-full  animate center-items">
            </div>
          </div>
        </div>))}
      </div>


      <div className="my-4">
        {/* <div
          className="bg-white w-fit h-fit px-2 py-2 rounded text-black ml-8 cursor-pointer hover:shadow"
          onClick={()=>{
            setCreateRegion(!createRegion)
            setSelectedRegion(-1)
            }}>
          Create region
        </div> */}
      </div>


      <div className="flex w-full ">
        <div className="m-auto select-none w-fit max-w-[66%]">
          <RegionSelect
            clickedHandle={clickedHandle}
            setClickedHandle={setClickedHandle}
            createRegion={createRegion}
            setCreateRegion={setCreateRegion}
            maxRegions={99}
            regions={regions}
            setRegions={setRegions}
            onChange={e=>handleChange(e)}
            regionRenderer={regionRenderer}
            regionStyle={regionStyle}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            debug={false}
            mouseDown={mouseDown}
            setMouseDown={setMouseDown}
            className="select-none"
          >
            <img className="select-none w-fit" src={`https://vip-cms.ams3.digitaloceanspaces.com/` + `${params.image}`} width=''/>
          </RegionSelect>
          {/* {console.log('props', props)} */}
        </div>
        {/* region edit sidebar */}
        <div className="w-1/3 mr-4">
          <RegionSidebar
            setCreateRegion={setCreateRegion}
            createRegion={createRegion}
            selectedRegion={selectedRegion}
            setSelectedRegion={setSelectedRegion}
            regions={regions}
            handleRegionDataChange={handleRegionDataChange}
            removeRegion={removeRegion}
            reviewRequest={ReviewRequest}
          />
        </div>

      </div>
      {/*
      {selectedRegion&&
        <div>
          Selected: {selectedRegion}
        </div>
      }
      {createRegion === true &&
        <div>
          Creating new region
        </div>
      }
      {mouseDown&&
        <div>
          Mouse down
        </div>
      }
      {clickedHandle!== ""&&
        <div>
          Clicked on: {clickedHandle}
        </div>
    */}
    </div>
  )
}
