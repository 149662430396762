import { CalendarOverview } from "assets/components/calendar/calendar-overview";
import { ContentCard } from "assets/components/content-card/ContentCard";
import { GridCardHome } from "assets/components/grid-cards/GridCardHome";
import { PendingVerificationCard } from "assets/components/grid-cards/PendingVerificationCard";
import React, { useEffect, useState } from "react";
import { GetAllVerifications } from "api/GetAllVerifications.js";
import { AssignVerifier } from "api/AssignVerifier.js";

//pendingReview - verifier not yet assigned
//inProgress - verifier assigned and is working on interval
//complete - verifier completed the job
export const AllVerificationsAdminView = (props) => {

  const [requests, setRequests] = useState([])
  const [status, setStatus] = useState('pendingReview');

  let statusLookup = {
    pendingReview: "Pending verifications: Looking for verifiers",
    inProgress: "In Progress verifications: Verifications that verifiers are currently working on",
    complete: "Complete verifications: Verfications that have been completed"
  }

  useEffect(() => {
    GetAllVerifications({status: status})
      .then((out) => {
        setRequests(out)
      })
  }, [status])


const handleAssignment = (request) => {
  AssignVerifier({requests: [request], verifier: 'e48f76d9-353d-4957-a4b1-95f9a26f6b7e'})
  .then((out) => {
    console.log('Success', out.data)
  })
}

const admin = true;

  return(
    <div className=" ">
       <div className="text-xl font-semibold py-4 px-6 select-none ">
          {statusLookup[status]}
      </div>
          <div className="button-confirm" onClick={() => setStatus('pendingReview')}>
            Pending
          </div>
          <div className="button-confirm" onClick={() => setStatus('inProgress')}>
            In Progress
          </div>
          <div className="button-confirm" onClick={() => setStatus('complete')}>
            Completed
          </div>


      <div className="w-full ">
        {requests && requests.map(item=> (
          <PendingVerificationCard
            item={item}
            admin={admin}
            handleAssignment={() => handleAssignment(item.uuid)}
          />
        ))}
      </div>
    </div>
  )
}
