import axios from "axios";

const SendReminderMutation = `
mutation SendReminderEmail($email: String, $firstName: String)  {
    SendReminderEmail (email: $email, firstName: $firstName)
}`;

const SendReminderEmail = async (data, token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: SendReminderMutation,
      variables: {
        ...data,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data.SendReminderEmail;
};

export { SendReminderEmail };
