import { GridCardCreate } from "assets/components/grid-cards/GridCardCreate";
import { GridCardHome } from "assets/components/grid-cards/GridCardHome";
import { GridCardImageUpload } from "assets/components/grid-cards/GridCardImageUpload";
import { VerifierCard } from "assets/components/grid-cards/VerifierCard";
import { SubmitCompleteModal } from "assets/components/modals/SubmitCompleteModal";
import { ProgressDots } from "assets/components/ProgressDots";
import React, { useState } from "react";
import { GetRequest } from "api/GetRequest.js";
import { GetCertificate } from "api/GetCertificate.js";

import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getHeaders } from '../../firebase'
import CertificateElm from "assets/components/pdf";
// import { pdf } from '@react-pdf/renderer';
// import download from 'downloadjs';
// import QRCode from 'qrcode';

import imgOne from "./../img/card-images/bag-crop.png"
import imgTwo from "./../img/card-images/shoe-crop.png"
import { ImageAnnotate } from "assets/components/img-annotate/ImageAnnotate";
import { ImageAnnotateVerification } from "assets/components/img-annotate/ImageAnnotateVerification";

export const ViewVerification = (props) => {
  const headers = localStorage.getItem('token')
  const params = useParams()
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [init, setInit] = useState(false)
  const [productDetail, setProductDetail] = useState({})

  const [item, setItem] = useState({})
  const [currentUser, setCurrentUser] = useState()

  const [testVerificationData, setTestVerificationData] = useState([])
  const [imageIndex, setImageIndex] = useState(0)
  const [selectedImage, setSelectedImage] = useState()
  const [imageVerdicts, setImageVerdicts] = useState([])
  const [questionIndex, setQuestionIndex] = useState(0)

  useEffect(() => {
    if (!init) {
      setInit(!init)
      GetRequest({ uuid: params.id }, headers)
        .then((out) => {
          setProductDetail({ ...out })
        })
      setCurrentUser(localStorage.getItem('user'))
      //createImageVerdicts()
    }
  }, [init])

  const createImageVerdicts = () => {
    if (testVerificationData[questionIndex]) {
      let ver = []
      testVerificationData[questionIndex].files.forEach(item => ver.push(0))
      setImageVerdicts([...ver])
    }
  }



  let verifierProfile = {
    name: "John Doe",
    joined: "02/8/2019",
    img: "",
    experience: "2",
    verifications: "14",
    specialization: ["Bags", "Shoes", "Watches", "Jewelry"],
    verificationStarted: "3 days ago",
    feedbackDate: "Tomorrow"
  }

  let verifier = true


  const generateCodeVerify = async companyId => {
    // const qr_code = await new Promise((resolve, reject) => {
    //     QRCode.toDataURL(`https://www.demo.vipficated.com/`, {
    //         color: {
    //             dark: '#fff', // Blue dots
    //             light: '#0000', // Transparent background
    //         },
    //     })
    //         .then(url => resolve(url))
    //         .catch(err => reject(err));
    // });
    // return qr_code;
  };

  const getCertificateProps = async () => {
    const certficateData = await GetCertificate({ requestId: productDetail._id })
    console.log('certficateData', certficateData)
    const qr = await generateCodeVerify();
    return {
      qr: qr,
      brand: certficateData.requests.brand,
      verficationDate: certficateData.createdDate,
      category: certficateData.requests.category.charAt(0).toUpperCase() + certficateData.requests.category.slice(1),
      certificateId: '8be14584',
      verifierOne: certficateData.verifiers[0].firstName + ' ' + certficateData.verifiers[0].lastName,
      verifierTwo: certficateData.verifiers.length === 2 ? certficateData.verifiers[1].firstName + ' ' + certficateData.verifiers[1].lastName : null
    };
  };

  // const getCertificateGenerated = async () => {
  //     const certProps = await getCertificateProps();
  //     const blob = await pdf(<CertificateElm {...certProps}  />).toBlob();
  //     // download(blob, `Certificate of authenticity`, 'application/pdf');
  //     return true;
  //   };


  return (
    <div className="h-full w-full">
      <SubmitCompleteModal
        isOpen={openModal}
        setIsOpen={setOpenModal}
      />
      <div className="flex w-full">
        <div className="w-[100%]">
          <div className="text-xl font-semibold py-4 px-6">
            {productDetail.name}
          </div>

          <div className="ml-6">
            <ProgressDots
              item={productDetail}
            />
          </div>
          <div className="mb-4">       {productDetail && productDetail.certificate && productDetail.certificate.uuid ? <a className='button-confirm ml-6 mb-4' href={`https://ams3.digitaloceanspaces.com/vip-cms/${productDetail.certificate.uuid}.pdf`} target="_blank" rel="noreferrer" > View Certificate </a> : ''}
          </div>

          {productDetail.files && productDetail.files[0].webMatches.length > 0 ?
            <>
              <div className=" inline-flex">
                <div className="bg-red-500 w-[20px] h-[20px] rounded-full ml-6 mb-5">
                </div>
                <p className="ml-1 font-bold">Copyright Alert</p>
              </div>

              <div className="ml-6">
                Similar image was found on the web here: {productDetail.files[0].webMatches.map(item => <li><a href={item.url}>Image</a></li>)}
              </div>
            </>
            : ""}

          <div className="py-4 px-6 flex ">
            <div>
              <div className=" ">
                Brand
              </div>
              <div className="font-bold">
                {productDetail.brand}
              </div> 
            </div>
            <div className="ml-12">
              <div className=" ">
                Model
              </div>
              <div className="font-bold">
                {productDetail.model}
              </div> 
            </div>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-3 ">
            {productDetail && productDetail.files && productDetail.files.map((item, index) => (
              <GridCardImageUpload
                view={true}
                key={index}
                card={item}
                index={index}
                verifier={currentUser === 'approvedVerifier' ? true : false}
                marketplace={currentUser === 'marketplace' ? true : false}
                productId={productDetail.uuid}
                setSelectedImage={setSelectedImage}
                setImageIndex={setImageIndex}
                imageVerdicts={imageVerdicts}
              />
            ))}
          </div>
        </div>
        {productDetail.status !== 'pendingReview' && currentUser === 'approvedVerifer' &&
          <VerifierCard
            verifierProfile={verifierProfile}
          />
        }
      </div>
      {
        productDetail.files &&
        <div className="pb-48">
          <ImageAnnotateVerification
            img={`https://vip-cms.ams3.digitaloceanspaces.com/` + productDetail.files[imageIndex].key}
            selectedImage={selectedImage || productDetail.files[0]}
            productDetail={productDetail}
            setProductDetail={setProductDetail}
            questionIndex={questionIndex}
            imageIndex={imageIndex}
            setImageIndex={setImageIndex}
            imageVerdicts={imageVerdicts}
            setImageVerdicts={setImageVerdicts}
          />
        </div>
      }
      {/* <div className="my-12 w-full border-t-2 border-vipGreen flex">
        <div
          className="mt-4 w-fit h-fit bg-vipGreen text-white text-lg font-semibold px-4 py-1 rounded cursor-pointer hover:shadow-xl hover:opacity-95"
          onClick={()=>navigate('/new-verification')}
        >
          Back
        </div>
        <div
          className="ml-auto mt-4 w-fit h-fit bg-vipGreen text-white text-lg font-semibold px-4 py-1 rounded cursor-pointer hover:shadow-xl hover:opacity-95"
          onClick={()=>setOpenModal(true)}
        >
          Finish
        </div>
      </div> */}
    </div >
  )
}
