import axios from "axios";

const GetUsersQuery = `
query GetUsers ($filter: UserFilterType) {
    GetUsers (filter: $filter){
        email
        firstName
        lastName
        company
        uuid
        status
        type
        phone
        city
        date
        _id
        progression
    }
}`;

const GetUsers = async (data, token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: GetUsersQuery,
      variables: {
        ...data,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data.GetUsers;
};

export { GetUsers };
