import React, { Component } from 'react';
import { PropTypes } from 'prop-types'; 
import objectAssign from 'object-assign';
import style from './style';

export const Region = (props) => {  

	const renderHandles = () => {
		return ( 
			<div>
				{props &&
          <>
            <div 
              data-dir='se' style={style.RegionHandleSE} 
              data-wrapper={props.wrapper}
              onMouseDown={e=>{
                props.setClickedHandle('SE')
                props.setSelectedRegion(props.index)
              }}  
              onMouseUp={e=>props.setClickedHandle("")}
            >
              {props.test}
            </div>
            <div 
              data-dir='sw' style={style.RegionHandleSW} 
              data-wrapper={props.wrapper}
              onMouseDown={e=>{
                props.setClickedHandle('SW')
                props.setSelectedRegion(props.index)
              }}  
              onMouseUp={e=>props.setClickedHandle("")}
            />
            <div 
              data-dir='nw' style={style.RegionHandleNW} 
              data-wrapper={props.wrapper}
              onMouseDown={e=>{
                props.setClickedHandle('NW')
                props.setSelectedRegion(props.index)
              }}  
              onMouseUp={e=>props.setClickedHandle("")}
            />
            <div 
              data-dir='ne' style={style.RegionHandleNE}
              data-wrapper={props.wrapper}
              onMouseDown={e=>{
                props.setClickedHandle('NE')
                props.setSelectedRegion(props.index)
              }}  
              onMouseUp={e=>props.setClickedHandle("")}
            />
          </>
        }
			</div>
		);
	}

  const localStyle = {
    width: props.width + '%',
    height: props.height + '%',
    left: `${props.x}%`,
    top: `${props.y}%`
  }; 

  const dataRenderArgs = {
    data: props.data,
    //isChanging: props.changing,
    index: props.index
  }; 

  // console.log('testt', props.regions[props.selectedRegion].label)

  return ( 
    <div 
      onMouseDown={e=>{ 
        props.setSelectedRegion(props.index)
      }}  
      className='text-black relative z-[50]'
      //cons={console.log(props.onCropStart)}
      style={objectAssign({}, parseInt(props.index) === parseInt(props.selectedRegion) ? style.RegionSelected : style.Region, localStyle, props.data?.regionStyle)}
      //onMouseDown={props.onCropStart}
      //onTouchStart={props.onCropStart} 
      data-wrapper={props.wrapper}
      > 
      {/* {props.selectedRegion>-1 && props.regions[props.selectedRegion].data.selected} */}
      <div data-wrapper={props.wrapper} className='select-none absolute -top-9 -left-2 font-bold bg-white px-2 py-1 opacity-70 rounded min-w-[100px] min-h-[30px]' onClick={()=>props.setSelectedRegion(props.index)}>
        <div data-wrapper={props.wrapper} className=''>
          {props.regions[props.index]?.label || `Region ${props.index+1} `}
        </div>
      </div>
      
      {props.handles && renderHandles()}
      {/* {props.dataRenderer(dataRenderArgs)} */}
    </div>
  ); 
} 