import * as React from "react"

function PlusYellow(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.5 27c.496 0 .912-.168 1.248-.504.335-.335.502-.75.502-1.246v-3.5h3.5c.496 0 .912-.168 1.248-.504.335-.335.502-.75.502-1.246s-.167-.912-.502-1.248a1.698 1.698 0 00-1.248-.502h-3.5v-3.5c0-.496-.167-.912-.502-1.248A1.698 1.698 0 0022.5 13c-.496 0-.911.167-1.246.502a1.695 1.695 0 00-.504 1.248v3.5h-3.5c-.496 0-.911.167-1.246.502A1.695 1.695 0 0015.5 20c0 .496.168.911.504 1.246.335.336.75.504 1.246.504h3.5v3.5c0 .496.168.911.504 1.246.335.336.75.504 1.246.504zM5 36.756V9.5c0-.963.343-1.787 1.029-2.473C6.714 6.342 7.537 6 8.5 6h28c.962 0 1.787.342 2.473 1.027C39.658 7.713 40 8.537 40 9.5v21c0 .962-.342 1.787-1.027 2.473-.686.685-1.51 1.027-2.473 1.027H12l-4.025 4.025c-.554.554-1.189.678-1.904.371C5.357 38.09 5 37.544 5 36.756zm3.5-4.2l2.056-2.056H36.5v-21h-28v23.056zM8.5 9.5v23.056V9.5z"
        fill="#010101"
      />
      <path
        d="M19.5 24c.496 0 .912-.168 1.248-.504.335-.335.502-.75.502-1.246v-3.5h3.5c.496 0 .912-.168 1.248-.504.335-.335.502-.75.502-1.246s-.167-.912-.502-1.248a1.698 1.698 0 00-1.248-.502h-3.5v-3.5c0-.496-.167-.912-.502-1.248A1.698 1.698 0 0019.5 10c-.496 0-.911.167-1.246.502a1.695 1.695 0 00-.504 1.248v3.5h-3.5c-.496 0-.911.167-1.246.502A1.695 1.695 0 0012.5 17c0 .496.168.911.504 1.246.335.336.75.504 1.246.504h3.5v3.5c0 .496.168.911.504 1.246.335.336.75.504 1.246.504zM2 33.756V6.5c0-.963.343-1.787 1.029-2.473C3.714 3.342 4.537 3 5.5 3h28c.962 0 1.787.342 2.473 1.027C36.658 4.713 37 5.537 37 6.5v21c0 .962-.342 1.787-1.027 2.473-.686.685-1.51 1.027-2.473 1.027H9l-4.025 4.025c-.554.554-1.189.678-1.904.371C2.357 35.09 2 34.544 2 33.756zm3.5-4.2L7.556 27.5H33.5v-21h-28v23.056zM5.5 6.5v23.056V6.5z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default PlusYellow
