import axios from "axios"


const uploadImage = (img, objectId, requestId, cb) => {
  console.log('Upload image')
  //let previewUrl = URL.createObjectURL(img)
  console.log('Request Id', requestId)
  const formData = new FormData()
  formData.append("operations", JSON.stringify({ "query": "mutation UploadFile($file:Upload!, $requestId:String, $id:Int) { UploadFile(file: $file, requestId:$requestId, id:$id)}", "variables": { "requestId": requestId, "id":objectId} }))
  formData.append("map", JSON.stringify({"0": ["variables.file"]}))
  formData.append("0", img)

  axios({
      url: process.env.REACT_APP_API,
      method: "post",
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: progressEvent => console.log('progress', progressEvent.loaded)
  })
  .then(response => {
    console.log(`response:${JSON.stringify(response)}`)
    cb(false, response.data.data.UploadFile)
  })
  .catch(error => cb(false, 'xxx'));
}


export {
  uploadImage
}
