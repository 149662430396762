
import React from "react";

import YellowManImage from './../../img/card-images/laptop-man.png' 
import TimesThreeYellow from "assets/files/images/times-three";
import TimesElevenYellow from "assets/files/images/times-eleven";
import PercentYellow from "assets/files/images/36p";
import TimesFourtyYellow from "assets/files/images/times-fourty";
import { CallToActionModal } from "assets/components/basic/CallToActionModal";
import { useState } from "react";
import OneYellow from "assets/files/svgs/one-yellow";
import TwoYellow from "assets/files/svgs/two-yellow";
import ThreeYellow from "assets/files/svgs/three-yellow";
import FourYellow from "assets/files/svgs/four-yellow";
import FiveYellow from "assets/files/svgs/five-yellow";
import SixEndYellow from "assets/files/svgs/SixEndYellow.PNG";
import SevenYellow from "assets/files/svgs/seven-yellow";
import EightYellow from "assets/files/svgs/eight-yellow";
import WomanImage from "assets/files/svgs/woman-image.png";
import PlusYellow from "assets/files/svgs/plus-yellow";
import PiggyYellow from "assets/files/svgs/piggy-yellow";
import PersonYellow from "assets/files/svgs/person-yellow";
import StatsYellow from "assets/files/svgs/stats-yellow";
import { useNavigate } from "react-router-dom";

import LampSuit from './../../img/card-images/lamp-suit.png'
import WorldLines from './../../img/card-images/world-lines.png'

export const LandingVerifiers = () => {
  const navigate = useNavigate()
  const [openCta, setOpenCta] = useState(false)

  let howItWorks = [
    {
      text: "Sign in and take the test",
      sub: "To define what brands / categories will you receive",
      icon: <OneYellow />
    },
    {
      text: "Get access to the verifiers platform",
      sub: "In a matter of days you will have full access to the verifiers platform",
      icon: <TwoYellow />
    },
    {
      text: "Customize your preferences",
      sub: "Such as your time availability when you will receive verification requests",
      icon: <ThreeYellow />
    },
    {
      text: "Accomplish verifications",
      sub: "The technology and a huge community of verifiers are on your side",
      icon: <FourYellow />
    },
    {
      text: "Get paid",
      sub: "In a bi-weekly basis and based on your authentications performed",
      icon: <FiveYellow />
    },
    {
      text: "Get more verification requests",
      sub: "As you improve your skills, you will qualify to more brands and categories",
      icon: <img className="mt-3" src={SixEndYellow} />
    },
    // {
    //   text: "Get paid",
    //   sub: "In a bi-weekly basis and based on your authentications accomplished",
    //   icon: <SevenYellow />
    // },
    // {
    //   text: "Get more verification requests",
    //   sub: "As you improve your skills",
    //   icon: <EightYellow />
    // },
  ]
  
  return(
    <div className="w-full min-h-screen text-lg mt-16"> 
      <CallToActionModal open={openCta} setOpen={setOpenCta} />
      <div className="bg-vipBgDark">
        <div className="max-w-[1280px] m-auto px-4">
          <div className="flex w-full md:h-[650px] relative py-12 md:py-0">
            <div className="flex-1 flex flex-col">
              <div className="flex-1"> 
              </div>
              <div className="w-full md:w-[45%] md:mr-auto relative z-1">
                <div className="text-5xl md:text-6xl font-semibold text-vipYellow drop-shadow-lg text-left">
                  Your Passion
                </div>
                <div className="text-5xl md:text-6xl font-semibold text-vipYellow drop-shadow-lg md:text-center">
                  Your Schedule
                </div>
                <div className="text-5xl md:text-6xl font-semibold text-vipYellow drop-shadow-lg md:text-right">
                  Your Priorities
                </div>
                <div className="pt-8">
                  Welcome to the largest verification network, 
                  where professionals and lovers are paid to check 
                  the real condition of pre-owned fashion 
                </div>
                <div className="yellow-button mt-4 relative z-3" onClick={()=>navigate('/signup')}>
                  Take the test now
                </div>
              </div>
              <div className="flex-1"> 
              </div>
            </div>
            <div className="hidden md:block flex-1 absolute bottom-0 right-0 origin-top z-2">
              <img
                className="w-[700px] "
                src={LampSuit}
              />
            </div>
          </div>
        </div>
      </div>
      {/* vip makes fashion meet tech~  */}
      {/* <div className="bg-white max-w-7xl m-auto py-12 bg-no-repeat bg-right-bottom bg-50 min-h-[650px] font-bold text-4xl grid place-content-center" >
         TABLET VIDEO
      </div> */}
      {/* easy api to start */}
      <div className="bg-white w-screen ">
        <div className="max-w-7xl m-auto py-12">  
          <div className="text-left font-bold text-3xl text-vipYellow ml-6">
            How it works
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3">
            {howItWorks.map(item => (
              <div className="mx-6 pt-6">
                <div>
                  {item.icon}
                </div>
                <div className="font-semibold py-1">
                  {item.text}
                </div>
                <div>
                  {item.sub}
                </div>
              </div>
            ))}
          </div>
          <div className="yellow-button mt-8 ml-6" onClick={()=>navigate('/signup')}>
            Take the test now
          </div>

        </div> 
      </div>
      {/* reasons for marketplace~ */}
      <div className="bg-vipBgDark py-12 pl-6 "> 
        <div className="text-left font-bold text-3xl text-vipYellow max-w-7xl m-auto">
          <div className="flex"> 
            <div className="grid place-content-center">
              Join the largest network of verifiers to contribute to brands and fashion resale marketplaces 
            </div>
            <div className="w-full hidden md:block">
              <img
                className="w-[450px]"
                src={WorldLines}
              />
            </div>
          </div>
          <div className="yellow-button mt-8 text-black text-lg " onClick={()=>navigate('/signup')}>
            Take the test now
          </div>
        </div>   
      </div>
      {/*  do you want to become~ */}
      <div className="bg-white w-screen pt-12 px-4 md:px-0 pb-8">
        <div className=" max-w-7xl m-auto flex">  
          <div className="flex-1">
            <div className="text-left font-bold text-3xl text-vipYellow max-w-5xl">
              Main Features
            </div>  
            <div className="md:w-[60%] pt-4">
              <div>
                <div className="flex space-x-12 pb-12 pt-12">
                  <div className="flex-1">
                    <div className="">
                      <PlusYellow />
                      {/* <CheckmarkWithText header="x3 chance to buy x4 average ticket" text="when the product is verified by an independent professional"/> */}
                    </div>
                    <div className="">
                    Set frames to add your own comments

                    </div>
                  </div> 
                  <div className="flex-1"> 
                    <div className="">
                      <PiggyYellow />
                    </div>
                    <div className="">
                      Handle your payments
                    </div>
                  </div> 
                </div>
                <div className="flex space-x-12">
                  <div className="flex-1">
                    <div className="">
                      <PersonYellow />
                      {/* <CheckmarkWithText header="x3 chance to buy x4 average ticket" text="when the product is verified by an independent professional"/> */}
                    </div>
                    <div className="">
                      Request support (multiple verification)
                    </div>
                  </div> 
                  <div className="flex-1"> 
                    <div className="">
                      <StatsYellow/>
                    </div>
                    <div className="">
                      Gain new skills to qualify for more brands and categories
                    </div>
                  </div> 
                </div>
              </div> 
            </div> 
            <div className="yellow-button mt-8" onClick={()=>navigate('/signup')}>
              Take the test now
            </div>
          </div> 
          <div className="hidden md:block">
            <img src={WomanImage}/>
          </div>
        </div> 
      </div>
       
      {/* footer */}
      <div className="w-screen bg-vipGreen text-white py-12">
        <div className="text-center max-w-4xl m-auto font-semibold">
          Copyright VIP·Ficated 2022
        </div>
        <div className="text-center max-w-4xl m-auto">
          This website contains analytical cookies, accepted by visitor unless they are disactivated through the browser configuration.
        </div>
      </div>
    </div>
  )
}