import { LandingNavbar } from 'assets/components/basic/LandingNavbar'
import { Navbar } from 'assets/components/basic/Navbar'
import { Sidebar } from 'assets/components/basic/Sidebar'
import React, { useState } from 'react' 

export const WebsiteLayout = ({ children, item }) => { 
  const [darkMode, setDarkMode] = useState(false)
  return (
    <div className={`${darkMode ? 'dark' : ''} animate`}> 
      <div className={`w-screen max-w-screen min-h-screen text-vipBlack`}>   
        <LandingNavbar darkMode={darkMode} setDarkMode={setDarkMode} /> 
        <div className='flex'> 
          <div className='flex-1'>
            {item}  
          </div>
        </div> 
      </div> 
    </div>
  )
} 