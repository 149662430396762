import { CalendarOverview } from "assets/components/calendar/calendar-overview";
import { ContentCard } from "assets/components/content-card/ContentCard";
import { GridCardHome } from "assets/components/grid-cards/GridCardHome";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GetRequests } from "api/GetRequests.js";

export const Home = (props) => {
  const [currentUser, setCurrentUser] = useState(localStorage.getItem("user"));
  let [homeCards, setHomeCards] = useState([]);
  const navigate = useNavigate();

  const [requests, setRequests] = useState([])
  const [pending, setPending] = useState([])

  const [status, setStatus] = useState('inProgress');
  const headers = localStorage.getItem('token')
  const [completed, setCompleted] = useState([]) 

  useEffect(() => {
    GetRequests({status: 'complete'}, headers)
      .then((out) => {
        setCompleted(out)
      })
  }, [])

  useEffect( () => { 
    GetRequests({status: status}, headers)
      .then((out) => {
        setRequests(out) 
      })
  }, [status]) 

  useEffect( () => { 
    GetRequests({status: 'pendingReview'}, headers)
      .then((out) => {
        setPending(out) 
      })
  }, [status]) 
  

  let statusLookup = {
    pendingReview: "Pending : Looking for verifiers",
    inProgress: "In Progress : Verifications that verifiers are currently working on",
    complete: "Complete : Verfications that have been completed"
  }

  useEffect(() => {
    if (!currentUser) location.reload();
  });

  useEffect(() => {
    if (currentUser === "unapprovedVerifier") {
      setHomeCards([
        {
          name: "Verification Test",
          slug: "/test-explanation",
        },
        {
          name: "Account Settings",
          slug: "/account",
        },
      ]);
    } else if (currentUser === "approvedVerifier") {
      setHomeCards([
        {
          name: "Pending verifications",
          slug: "/pending",
        },
        {
          name: "Completed verifications",
          slug: "/performed",
        },

        {
          name: "Statistics",
          slug: "/stats",
        },
        {
          name: "My payments",
          slug: "/my-payments",
        },
        {
          name: "Account Settings",
          slug: "/account",
        },
      ]);
    } else if (currentUser === "marketplace") {
      setHomeCards([
        {
          name: "Pending verifications",
          slug: "/pending",
        },
        {
          name: "Completed verifications",
          slug: "/performed",
        },

        {
          name: "Statistics",
          slug: "/stats",
        },
      ]);
    } else if (currentUser === "admin") {
      navigate("/admin");
    }
  }, []);

  return (
    <div className=" ">
      <div className="text-xl font-semibold py-4 px-6 select-none invisible">
        Select a product category
      </div>
      <div className="grid grid-cols-3 px-3">
        {" "}
        {console.log("User", currentUser)}
        {homeCards.length > 0 &&
          homeCards.map((item) => <GridCardHome card={item} requests={requests} completed={completed} pending={pending}/>)}
      </div>
      {currentUser !== "unapprovedVerifier" && (
        <>
          <div className="text-huge font-light pt-10 border-b-2 border-vipGreen ml-5">
            Your year in review
          </div>
          <div className="pt-6 text-default pl-5 pb-4">
            All completed and pending verifications this month
          </div>
          <div className="flex">
            <div className="ml-5 mt-5 w-1/4">
              <div className="font-light">This month:</div>{" "}
              <p className="text-header">120 reviews</p>
              <div className="font-light pt-8">This year:</div>{" "}
              <p className="text-header">560 reviews</p>
              <div className="font-light pt-8">Lifetime:</div>{" "}
              <p className="text-header">1370 reviews</p>
            </div>

            <div className="w-3/4 mr-5">
              <CalendarOverview />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

{
  /* <div className="text-default flex ">
        <div className="font-light w-1/3 ml-5">
          <div className="text-huge font-light pt-10 border-b-2 pr-2 border-mainColor">
            Welcome back, Rene
          </div>
          <div className="text-header pt-6 pr-4">
            There are 9 verifications waiting for you
          </div>
          <div className="text-default hover:underline cursor-pointer w-fit pr-4 text-gray-400 hover:text-gray-700">
            go to tasks
          </div>

          <div className="text-header pt-6 pr-4">
            You have 5 pending reviews
          </div>
          <div className="text-default hover:underline cursor-pointer w-fit pr-4 text-gray-400 hover:text-gray-700">
            go to history
          </div>
        </div>
        <div className="w-1/3">
          <ContentCard
            header="This is the first header"
            footer="go to content"
            url="/content"
          >
            <div className="h-full center-items">
              This is the body
            </div>
          </ContentCard>
          <ContentCard
            header="This is the second header"
            footer="go to content"
            url="/content"
          >
            <div>
              This is the second body
            </div>
          </ContentCard>
        </div>
        <div className="w-1/3">
          <ContentCard
            header="This is the third header"
            footer="go to content"
            url="/content"
          >
            <div className="">
              This is the body
            </div>
          </ContentCard>
          <ContentCard
            header="This is the fourth header"
            footer="go to content"
            url="/content"
          >
            <div className="">
              This is the body
            </div>
          </ContentCard>
        </div>
      </div>  */
}
