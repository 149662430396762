import * as React from "react"

function CostsIconYellow(props) {
  return (
    <svg
      width={40}
      height={38}
      viewBox="0 0 40 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M26.713 14.478c-1.355-.348-3.24-.861-4.75-.861-7.45 0-13.486 4.597-13.486 10.267 0 3.259 1.993 6.164 5.102 8.044l-.842 2.758a1.025 1.025 0 00.16.916 1.04 1.04 0 00.837.418h3.064c.225 0 .445-.073.625-.207l2.423-1.805h4.232l2.423 1.805c.18.134.4.206.625.206h3.064a1.048 1.048 0 00.837-.417 1.03 1.03 0 00.16-.916l-.842-2.758c1.853-1.12 3.308-2.604 4.184-4.31m-7.816-13.14l7.816-2.585-.146 6.252 3.62 1.503v6.032l-3.346 1.723"
        stroke="#282828"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.447 22.233a.872.872 0 01-.614-.252.858.858 0 010-1.219.872.872 0 011.482.61.859.859 0 01-.254.609.872.872 0 01-.614.252zM5 17.063s0 4.136 3.474 5.17"
        stroke="#282828"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.756 13.19c.225-.64.347-1.33.347-2.046C24.103 7.75 21.381 5 18.024 5c-3.357 0-6.079 2.75-6.079 6.144 0 1.621.622 3.098 1.64 4.196"
        stroke="#282828"
        strokeWidth={3}
        strokeLinejoin="round"
      />
      <path
        d="M23.713 11.478c-1.355-.348-3.24-.861-4.75-.861-7.45 0-13.486 4.597-13.486 10.267 0 3.259 1.993 6.164 5.102 8.044l-.842 2.758a1.026 1.026 0 00.529 1.223c.145.073.305.11.468.11h3.064c.225 0 .444-.072.625-.206l2.423-1.805h4.232l2.423 1.805c.18.134.4.206.625.206h3.064a1.048 1.048 0 00.837-.417 1.031 1.031 0 00.16-.916l-.842-2.758c1.853-1.12 3.308-2.604 4.184-4.31m-7.816-13.14l7.816-2.585-.146 6.252 3.62 1.503v6.032l-3.346 1.723"
        stroke="#F1D848"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.447 19.233a.872.872 0 01-.614-.252.858.858 0 010-1.219.872.872 0 011.482.61.859.859 0 01-.254.609.872.872 0 01-.614.252zM2 14.063s0 4.136 3.474 5.17"
        stroke="#F1D848"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.756 10.19c.225-.64.347-1.33.347-2.046C21.103 4.75 18.381 2 15.024 2c-3.357 0-6.079 2.75-6.079 6.144 0 1.621.622 3.098 1.64 4.196"
        stroke="#F1D848"
        strokeWidth={3}
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CostsIconYellow
