import axios from 'axios'

const DownloadCsvQuery = `
query DownloadCsv ($startDate: String , $endDate: String ){
  DownloadCsv (startDate: $startDate, endDate: $endDate)
}`

const DownloadCsv = async (data) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: DownloadCsvQuery,
      variables: {
        ...data
      }
    }
  })

  return res.data.data.DownloadCsv

}

export {
  DownloadCsv
}
