
import axios from 'axios'

const GetPaymentsQuery = `
query GetAllPayments ($status: String, $startDate: String, $endDate: String) {
    GetAllPayments (status: $status, startDate:$startDate, endDate:$endDate){
        verificationId
        date
        status
        _id
    }
}`

const GetAllPayments = async (data, cb) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: GetPaymentsQuery,
      variables: {
        ...data
      }
    }
  })
  return res.data.data.GetAllPayments
}

export {
  GetAllPayments
}
