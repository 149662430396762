import React, { useState } from "react";
import { auth } from '../../firebase'
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import FacebookIcon from "assets/img/card-images/facebook-icon";
import GoogleIcon from "assets/img/card-images/google-icon";
import LinkedinIcon from "assets/img/card-images/linkedin-icon";
import { ChevronRightIcon, EyeIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import vipLogo from "assets/files/images/vip-logo-mock.png"

import SidebarImg from 'assets/img/explanation-img/SidebarImage.png'
import RegionsImage from 'assets/img/explanation-img/RegionsImage.PNG'
import ChangeRegion from 'assets/img/explanation-img/ChangeRegion.png'
import FinalVerdict from 'assets/img/explanation-img/FinalVerdict.png'
import ImageTiles from 'assets/img/explanation-img/ImageTiles.png'
import PlatformOverview from 'assets/img/explanation-img/PlatformOverview.PNG'

export const TestExplanation = () => {
  const navigate = useNavigate()
  return(
    <div className="m-0 pt-4 flex flex-col ">
      <div className="w-fit m-auto">
        <img
          className="scale-[70%]"
          src={vipLogo}
        />
      </div>
      <div className="max-w-2xl m-auto text-center flex-1">
        <div className="text-2xl font-semibold py-4 ">
          Welcome to Vipficated
        </div>
        <div className="text-xl font-normal pb-4 ">
          Thank you for creating a verifier account, lets get started!
        </div>

      </div>
      <div className="max-w-3xl m-auto pt-4 pb-12">
        <div className="font-semibold">
          A few considerations before you start the test 
        </div>
        <div>
          The goal of this “entry test” is to discover your strengths. To that end, you will be presented with 100 products which you will be able to assess or skip.
        <br/><br/>
          As a result, once you complete the test, you will receive renumerated verification orders according to your current strengths.
        <br/><br/>
          Your strengths are defined at product category and brand level and are calculated as the ratio of your assessment (authentic / counterfeit) over the truth.
        <br/><br/>
          In the future, you will have the opportunity to take additional tests if you want to expand your specialization and take on more orders.
        </div>

        <div className="font-semibold  pt-4">
          Navigation through the test 
        </div>
        <div>
          You have only one attempt to pass this test. But you can leave whenever you need and resume where you left off as long as it does not take you longer than 7 calendar days.
          <br/><br/>
          Whether you assess the product or you skip it, it’s not possible to go back to a previous product.
        </div>

        
      </div>

      <div className="max-w-5xl w-full m-auto pb-12">
        <div className="text-center font-semibold text-2xl">
         How to verify a product
        </div>
        <div className="w-[650px] m-auto pt-6">
          <img 
            className=" "
            src={PlatformOverview}
          />
        </div>
        <div className="pt-4">
          When you start the test, this is the overview page you will find yourself on. Continue reading for a quick explanation of the different parts of the platform.
        </div>
        <div className="font-semibold text-xl pt-12 pb-2">
          The Images Overview
        </div> 
        <div>
          <img 
            className=""
            src={ImageTiles}
          />
        </div>
        <div> 
          At the top of the page you will find an overview of all available images for this product. <br/>
          <br/>
          Each image has a individual state regarding your verdict; authentic (green), fake (red), not assessed yet (gray)
          {/* Each images has a individual state regarding the verification: <br/>
          - A image that has <span className="font-semibold">no verdict</span> yet will have a <span className="font-semibold">gray</span> outline.<br/>
          - A image that has a <span className="font-semibold">negative verdict</span> will have a <span className="font-semibold">red</span> outline. <br/>
          - A image that has a <span className="font-semibold">positive verdict</span> will have a <span className="font-semibold">green</span> outline. <br/>
        */}
        </div>

        <div className="font-semibold text-xl pt-4 pb-2">
          The Product
        </div>
        <div className="flex">
          <div className="w-[350px]">
            <img 
              className=" "
              src={RegionsImage}
            />
          </div>
          <div className="flex-1 ml-4 pt-4">
            The first thing you need are the images of the product you will verify.
            <br/><br/>
            Inside each image you will find one or multiple <span className="font-semibold">regions.</span> Each region has several fields you can stipulate using the sidebar. As a verifier you have the ability to add as many regions you need.
            <br/><br/>
            Each individual picture needs a verdict: fake or authentic. You can select your verdict immediately, or come back to a picture after looking at the other available pictures.
          </div>
        </div>

        <div className="font-semibold text-xl pt-12 pb-2">
          The Sidebar
        </div> 
        <div className="flex">
          <div className="flex-1 mr-4 pt-4">
            The sidebar is the main control unit during your verification. <br/>
            At the top of the sidebar you will find your <span className="font-semibold">basic image controls</span>, here you can rotate and scale the image you are verifying. 
            <br/><br/>
            After that you will see the <span className="font-semibold">Region Controls</span>. Click on a region name to open up the details for this region.<br/>
            Click "Add Region" and click&drag your mouse on the image to create a new region.
            {/* If the region shows any inappropriate content, please select the <span className="font-semibold">Report inappropriate image</span> checkbox to exclude this image from the final product certificate. */}
          </div>
          <div className="w-fit">
            <img 
              src={SidebarImg}
            />
          </div> 
        </div>

        
        <div className="font-semibold text-xl pt-12 pb-2">
          The Region Editor
        </div> 
        <div className="flex">
          <div className="w-fit">
            <img 
              src={ChangeRegion}
            />
          </div> 
          <div className="flex-1 ml-4 pt-4">
            Click on any region name inside the region editor to open up the settings. <br/><br/>
            Here you can set your own region name, select a tag from the dropdown menu (note: you can also start typing to find available tags) and leave any comments regarding this specific region. <br/>
            Lastly you also have the option to delete this current region by clicking the <span className="font-semibold">Remove Region</span> button.<br/><br/>
            Any changes you make are saved automatically.
          </div>
        </div>

        <div className="font-semibold text-xl pt-12 pb-2">
          The Final Verdict
        </div> 
        <div className="flex">
          <div className="flex-1 ml-4 pt-4">
            Once you gave a verdict on each individual image, the <span className="font-semibold">Product Information</span> sidebar will appear. <br/><br/>
            In the product information sidebar you will be able to give a final product-level verdict. <br/>
            For this verdict you can select from 1 to 4 stars ranging on how confident you are about this verdict, after that you have the ability to add a final comment for this product. <br/> Please note: this comment will be used to describe the product on the final product certification.
            <br/><br/>
            Lastly you select either the Fake or Authentic button define your product verification, after which you can continue to the next product using the "Save Verdict" button at the bottom right of your screen.
          </div>
          <div className="w-fit">
            <img 
              src={FinalVerdict}
            />
          </div> 
        </div>
        <div className="font-semibold pt-4 text-center">
          What should you expect?
          </div>
          <div className="">
            You have untill 7 days after starting the test to complete it, please make sure you finish your test in time. Any changes you make during the verification process are saved automatically. 
            <br/><br/>
            If you do not feel like the current product fits your expertise, you can skip the verification of this product by clicking the "Skip verification" button in the bottom right hand side of the screen. 
            <br/><br/>
            Once you have completed the test, we will send you your score and credentials to become a member of the largest Fashion Verification Network. 
            <br/><br/>
            Please prepare and take the test. Good luck!
          </div>
        </div>

      {/* <div className="max-w-2xl w-full m-auto ">
        <div className="text-left font-bold text-2xl pt-4">
          Step One:
        </div>
        <div className="text-lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lacus mauris, ornare at malesuada in, semper vitae erat. Sed et orci vel nibh laoreet vulputate id sed lorem. Nam eget cursus nisi.

        </div>
      </div> */}

      {/* <div className="max-w-2xl w-full m-auto pt-4">
        <div className="text-left font-bold text-2xl pt-4">
          Step Two:
        </div>
        <div className="text-lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lacus mauris, ornare at malesuada in, semper vitae erat. Sed et orci vel nibh laoreet vulputate id sed lorem. Nam eget cursus nisi.
        </div>
      </div> */}

      {/* <div className="max-w-2xl w-full m-auto pt-4 pb-8">
        <div className="text-left font-bold text-2xl pt-4">
          Step Three:
        </div>
        <div className="text-lg">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas lacus mauris, ornare at malesuada in, semper vitae erat. Sed et orci vel nibh laoreet vulputate id sed lorem. Nam eget cursus nisi.
        </div>
      </div> */}

      <div className="bg-vipGreen w-full text-white text-center ">
        <div className="text-2xl font-bold flex w-fit m-auto py-24 cursor-pointer h-fit" onClick={() => navigate('/start-test')}>
          Get started <span><ChevronRightIcon className="w-[45px] mt-[-5px]"/> </span>
        </div>
      </div>
    </div>
  )
}
