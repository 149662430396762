import * as React from "react"

function GraphIconYellow(props) {
  return (
    <svg
      width={37}
      height={37}
      viewBox="0 0 37 37"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.61 4.605V31.66c0 .897.355 1.757.989 2.392.633.634 1.492.99 2.388.99h27.017"
        stroke="#010101"
        strokeWidth={3}
        strokeMiterlimit={5.759}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.406 22.652l6.333-6.341 6.332 6.341 9.498-9.512"
        stroke="#010101"
        strokeWidth={3}
        strokeMiterlimit={5.759}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.82 13.14h4.75v4.756"
        stroke="#010101"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 2v27.055c0 .897.356 1.757.99 2.391.632.635 1.492.99 2.387.99h27.018"
        stroke="#F1D848"
        strokeWidth={3}
        strokeMiterlimit={5.759}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.797 20.047l6.332-6.341 6.333 6.34 9.498-9.51"
        stroke="#F1D848"
        strokeWidth={3}
        strokeMiterlimit={5.759}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.21 10.535h4.75v4.756"
        stroke="#F1D848"
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default GraphIconYellow
