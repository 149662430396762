import { GridCardImageUpload } from "assets/components/grid-cards/GridCardImageUpload";
import { SubmitCompleteModal } from "assets/components/modals/SubmitCompleteModal";
import React, { useRef, useState } from "react";
import { GetTests } from "api/GetTests.js";
import { SaveAnswers } from "api/SaveTestAnswers.js";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { TestVerificationData } from "assets/files/images/TestVerificationData";

import { ImageAnnotate } from "assets/components/img-annotate/ImageAnnotate";

export const TestVerification = (props) => {
  const headers = localStorage.getItem('token')
  //const params = useParams()
  const navigate = useNavigate()
  const [openModal, setOpenModal] = useState(false)
  const [init, setInit] = useState(false)
  //const [productDetail, setProductDetail] = useState(TestVerificationData[0])
  const [testVerificationData, setTestVerificationData] = useState([])
  const [imageIndex, setImageIndex] = useState(0)
  const [currentUser, setCurrentUser] = useState()
  const [selectedImage, setSelectedImage] = useState()
  const [imageVerdicts, setImageVerdicts] = useState([])
  const [questionIndex, setQuestionIndex] = useState(0)

  const ref = useRef()

  useEffect(()=>{
    if(!init){
      createImageVerdicts()
      setInit(!init)
    }
  },[init])

  const createImageVerdicts = () => {
    if(testVerificationData[questionIndex])
    {
      let ver = []
      testVerificationData[questionIndex].files.forEach(item => ver.push(0))
      setImageVerdicts([...ver])
    }
  }

  const saveTestAnswer = () => {
    SaveAnswers({answers: testVerificationData[questionIndex]}, headers)
      .then((out) => {
        console.log('out', out)
      })
  }


  const handleQuestionReset = () => {
    setImageVerdicts([])
    setSelectedImage()
    setImageIndex(0)
  }

  useEffect(()=>{
    handleQuestionReset()
  }, [questionIndex])


  // useEffect(()=>{
  //   console.log('there was a change')
  // }, [testVerificationData])


  useEffect(()=>{
      GetTests({}, headers)
        .then((out) => {
          setTestVerificationData([...out])
        })
  }, [])

  const handleRefClick = () => {
    ref.current?.scrollIntoView({block: 'start', behavior: 'smooth'});
  }

  // useEffect(()=>{
  //   console.log('selectedImage', selectedImage)
  //   if(questionIndex !== 0) {
  //     console.log('Here', questionIndex)
  //     console.log('TestVerificationData', testVerificationData)
  //     let obj = testVerificationData[questionIndex]
  //     console.log('Object', obj)
  //     const productDataFile = obj.files.map(v => ({...v, regionData: regionData, key: `https://vip-cms.ams3.digitaloceanspaces.com/` + v.key}))
  //     const productDetailToSave = {brand: obj.brand, name: obj.name, model: obj.model, description: obj.description, files: productDataFile }
  //     setProductDetail({...productDetailToSave})
  //     setSelectedImage()
  //   }
  // }, [questionIndex] )

  return(
     <div className="h-full w-full pt-12">
      <SubmitCompleteModal
        isOpen={openModal}
        setIsOpen={setOpenModal}
      />
      <div className="">
         {testVerificationData.length > 0 &&
         <div className="w-full ">
          <div className="text-xl font-semibold py-4 px-6">
            {testVerificationData[questionIndex].name}
          </div>
          {/* <div className="ml-6">
            <ProgressDots
              item={productDetail}
            />
          </div> */}
          <div className="py-4 px-6 flex ">
            <div>
              <div className="pb-1 font-light">
                Brand
              </div>
              <div className="font-semibold">
                {testVerificationData[questionIndex].brand}
              </div>
            </div>
            <div className="ml-12">
              <div className="pb-1 font-light">
                Model
              </div>
              <div className="font-semibold">
                {testVerificationData[questionIndex].model}
              </div>
            </div>
          {/*<div className="ml-12">
            <div className="pb-1 font-semibold">
              Description
            </div>
            <div>
              {testVerificationData[questionIndex].description}
            </div>
          </div>*/}
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-3 ">
            {testVerificationData[questionIndex] && testVerificationData[questionIndex].files && testVerificationData[questionIndex].files.map((item, index) => {
              return <GridCardImageUpload
                view={true}
                key={index}
                card={item}
                index={index}
                verifier={currentUser === 'approvedVerifier' ? true : false}
                marketplace={currentUser === 'marketplace' ? true : false}
                productId={testVerificationData[questionIndex].uuid}
                imgLink={item.key}
                setSelectedImage={setSelectedImage}
                selectedImage={selectedImage}
                setImageIndex={setImageIndex}
                imageVerdicts={imageVerdicts}
                handleRefClick={handleRefClick}
              />
            })}
          </div>
          {testVerificationData[questionIndex].files[imageIndex] &&
            <div className="pb-48" ref={ref}>
              <ImageAnnotate
                img={`https://vip-cms.ams3.digitaloceanspaces.com/` + testVerificationData[questionIndex].files[imageIndex].key}
                regionData={testVerificationData[questionIndex].files[imageIndex].regionData}
                selectedImage={testVerificationData[questionIndex].files[imageIndex]}
                // productDetail={productDetail}
                // setProductDetail={setProductDetail}
                testVerificationData={testVerificationData}
                setTestVerificationData={setTestVerificationData}
                questionIndex={questionIndex}
                imageIndex={imageIndex}
                setImageIndex={setImageIndex}
                imageVerdicts={imageVerdicts}
                setImageVerdicts={setImageVerdicts}
                ref={ref}
              />
            </div>
          }
        </div>}
      </div>
      <div className="py-6 w-full border-t-2 border-vipGreen flex fixed z-10 bg-white bottom-0">
        {/*<div
          className="w-fit ml-4 h-fit bg-vipGreen text-white text-lg font-semibold px-4 py-1 rounded cursor-pointer hover:shadow-xl hover:opacity-95"
          onClick={()=>navigate('/test/'+params.type)}
        >
          Back
        </div>*/}
      { testVerificationData.length > 0 &&  <div
          className="ml-auto mr-4 w-fit h-fit bg-vipGreen text-white text-lg font-semibold px-4 py-1 rounded cursor-pointer hover:shadow-xl hover:opacity-95"
          onClick={()=> {
             saveTestAnswer()
            if(questionIndex === testVerificationData.length - 1) {
              // alert('End of test, you will receive an email shortly')
              props.notify('End of test, you will receive an email shortly')
              navigate('/end-test')
            } else {
              setQuestionIndex(questionIndex + 1)
            }
          }}
        >
          {/* {console.log('verdict', testVerificationData[questionIndex] && testVerificationData[questionIndex].verdict)} */}
          { testVerificationData[questionIndex] && testVerificationData[questionIndex].verdict !== null ? "Save Verdict" : "Skip Verification"}
        </div>}
      </div>
      {

        testVerificationData.length === 0 && 
          <div className ="pt-12 ml-12 grid place-content-center"> 
            You already completed your introduction test, check your email to continue. 
            <br/> 
            <div className="flex">
              If you haven't received an email please contact <a className="ml-1 font-semibold hover:underline" href={"mailto:support@vipficated.com"} >support@vipficated.com</a> 
            </div>
            <div className="m-auto w-fit button-confirm mt-12" onClick={()=>navigate('/home')}>
              To the platform
            </div>
          </div>
            
      }
    </div>
  )
}
