import React, { useEffect, useState } from "react";
//import objectAssign from 'object-assign';
import { RegionSelect } from "assets/react-region-select/lib/RegionSelect";
//import { click } from "@testing-library/user-event/dist/click";
// import { SaveIcon, TrashIcon } from "@heroicons/react/solid";
// import ReactTextareaAutosize from "react-textarea-autosize";
// import { useNavigate, useParams } from "react-router-dom";
import { ReviewRequest } from "api/ReviewRequest.js";
// import { Tooltip } from "assets/components/tooltip/Tooltip";
import { RegionSidebar } from "assets/components/region-sidebar/RegionSidebar";
import { RegionSidebarProduct } from "../region-sidebar/RegionSidebarProduct";
import RotateIcon from "./rotate-icon";
import RotateIconFlip from "./rotate-icon-flip";
import { MinusIcon, PlusIcon } from "@heroicons/react/solid";
// import { GetRequest } from "api/GetRequest";
//import { Popover } from "@headlessui/react";
//import { getHeaders } from '../../firebase'

export const ImageAnnotate = ( props  ) => {
  // const params = useParams()
  // let dataObj1 = {
  //   comment: "",
  //   dropdown: 1,
  //   selected: false
  // }

  // let dataObj2 = {
  //   comment: "",
  //   dropdown: 2,
  //   selected: false
  // }
  // const navigate = useNavigate()
  // const [init, setInit] = useState(false)
  // const [productDetail, setProductDetail] = useState()
  // const [files, setFiles] = useState([])
  const [createRegion, setCreateRegion] = useState(false)
  const [selectedRegion, setSelectedRegion] = useState(-1)
  const [mouseDown, setMouseDown] = useState(false)
  const [clickedHandle, setClickedHandle] = useState("")
  const headers = localStorage.getItem('token')
  const [rotationIndex, setRotationIndex] = useState(1)
  const [zoomIndex, setZoomIndex] = useState(2)
  const [confidence, setConfidence] = useState(0)

  const [zoomString, setZoomString] = useState("scale-[200%]")

  const handleRotationCW = () => {
    if(rotationIndex < 4) 
      setRotationIndex(rotationIndex + 1) 
    else
      setRotationIndex(1) 
  }

  const handleRotationCCW = () => {
    if(rotationIndex > 1) 
      setRotationIndex(rotationIndex - 1) 
    else
      setRotationIndex(4)
  }

  const handleZoomIn = () => {
  if(zoomIndex < 4)
    setZoomIndex(zoomIndex + 1)
    const amount = (zoomIndex +1) * 10 + 100
    const string = "scale-[" + amount + "%]" 
    setZoomString(string)
  }

  const handleZoomOut = () => {
    if(zoomIndex > 1) {
    setZoomIndex(zoomIndex - 1) 
    const amount = (zoomIndex -1) * 10 + 100
    const string = "scale-[" + amount + "%]" 
    setZoomString(string)}
  }

  const getZoomAmount = () => {
    var index = zoomIndex
    var amount = index * 10 + 100
    var string = "scale-[" + amount + "%]" 
    console.log('string ', string)
    return string
  }

  const handleReset = () => {
    setZoomIndex(1)
    setRotationIndex(1) 
  }

  const handleQuestionReset = () => {
    setConfidence(0) 
  }

  useEffect(()=>{
    handleReset()
    handleQuestionReset()
  }, [props.questionIndex])

  useEffect(()=>{
    handleReset() 
  }, [props.imageIndex])

  const rotationIndexLookup = {
    1: " rotate-0",
    2: " rotate-90",
    3: " rotate-180",
    4: " -rotate-90"
  } 

  const zoomIndexLookup = {
    1: " scale-[100%]",
    2: " scale-[125%]",
    3: " scale-[150%]",
    4: " scale-[200%]"
  } 

  // useEffect(()=>{
  //   console.log('imgVerdicts:', props.imageVerdicts)
  // }, [props.imageVerdicts])
 
  useEffect(()=>{
    setSelectedRegion(-1)
  }, [props.imageIndex])
  //const [regions, setRegions] = useState(props.regionData)

  // useEffect(()=>{
  //   console.log('Product Detail', props.productDetail.files.find(item => item.filename === props.selectedImage.filename)) 

  // },[props.selectedImage])


  // const [regions, setRegions] = useState(
  //   [
  //     {
  //       x: 22,
  //       y: 60,
  //       width: 16,
  //       height: 15,
  //       data: dataObj1
  //     },
  //     {
  //       x: 27,
  //       y: 27,
  //       width: 33,
  //       height: 24,
  //       data: dataObj2
  //     }
  //   ])

  // useEffect(()=>{
  //   if(!init){
  //     setInit(!init)
  //     GetRequest({uuid: "74204771-6ec3-4ca7-b398-df4c5054c50e"}, headers)
  //       .then((out) => {
  //         console.log('out', out)
  //         setProductDetail({...out})
  //         setFiles([...out.files]) 
  //       })
  //   }
  // })
 
  const removeRegion = (input) => {
    setSelectedRegion(-1)
    let data = props.testVerificationData
    data[props.questionIndex].files[props.imageIndex].regionData.splice(input, 1)
    props.setTestVerificationData([...data]) 
    // let arr = props.testVerificationData[props.questionIndex].files[props.imageIndex].regionData
    // arr.splice(input, 1)
    //setRegions([...arr])
  }
  

  const regionRenderer = (regionProps) => {
 			return (<>
        {regionProps.selected &&
        <div onClick={()=>removeRegion(regionProps.index)}
        >
        </div>}
      </>);
  }

  // const handleChange = (data, index) => {
  //    let _regions = data
  //   setRegions([..._regions])
  // }

  // useEffect(()=>{  
  //   props.testVerificationData[props.questionIndex].files[props.imageIndex].regionData.forEach((item, index) => { updateRegionSelected(index) }) 
  // },[selectedRegion, props.testVerificationData[props.questionIndex].files[props.imageIndex].regionData.length])

  // const updateRegionSelected = (input) => {  
  //   let data = props.testVerificationData  
  //   data[props.questionIndex].files[props.imageIndex].regionData[input].selected = input == selectedRegion 
  //   props.setTestVerificationData([...data])  
  // }

  const regionStyle = {
    //background: 'rgba(255, 255, 255, 0.5)',
    //borderColor: "red",
  }

  // const handleRegionDataChange = (e) => {
  //   let data = props.testVerificationData
  //   data[props.questionIndex].files[props.imageIndex].regionData[selectedRegion].data.comment = e.target.value
  //   props.setTestVerificationData([...data])
  //   // //let _arr = props.testVerificationData[props.questionIndex].files[props.imageIndex].regionData
  //   // _arr[selectedRegion].data.comment = e.target.value
  //   // setRegions([..._arr])
  // }

  const updateRegion = (input) => {
    let data = props.testVerificationData 
    data[props.questionIndex].files[props.imageIndex].regionData = input 
    // data[props.questionIndex].files[props.imageIndex].regionData[selectedRegion] = input[0]
    props.setTestVerificationData([...data])
  }

  const getTrues = () => {
    let number = 0
    props.imageVerdicts.forEach(item => item > 0 ? number = number +1 : null )
    return number
  } 

  useEffect(()=>{
    console.log('zooom', zoomString)
  }, [zoomString])
 
  return(
    props.img&&
      <div className=" ">  
        <div className="flex w-full ">
          <div className="select-none w-full m-auto">
            <RegionSelect
              clickedHandle={clickedHandle}
              setClickedHandle={setClickedHandle}
              createRegion={createRegion}
              setCreateRegion={setCreateRegion}
              maxRegions={99}
              //regions={props.selectedImage.regionData}
              regions={props.testVerificationData[props.questionIndex].files[props.imageIndex].regionData}
              setRegions={updateRegion}
              //onChange={e=>handleChange(e)}
              regionRenderer={regionRenderer}
              //regionStyle={regionStyle}
              selectedRegion={selectedRegion}
              setSelectedRegion={setSelectedRegion}
              debug={false}
              mouseDown={mouseDown}
              setMouseDown={setMouseDown}
              className="select-none w-full"
            >
              <div className="overflow-auto"> 
                <img className={`select-none w-full ${zoomIndex != 1 && zoomIndexLookup[zoomIndex]} ${rotationIndex != 1 && rotationIndexLookup[rotationIndex]}`} src={props.img} width='' ref={props.ref}/>   
              </div>
              {/* <img tag="test" className="select-none w-fit" src={`https://vip-cms.ams3.digitaloceanspaces.com/` + `${params.image}`} width=''/> */}
              {/* tag="test" */}
            </RegionSelect> 
          </div> 
          <div className="w-[350px] mr-8"> 
            <div className="sticky-sidebar"> 
              <div className="flex space-x-6 ml-4 pb-4 ">
                <div className="border-2 w-fit flex space-x-2 px-2 py-1 border-vipGreen rounded bg-white opacity-80 hover:opacity-100 animate">
                  <div className="w-[25px] cursor-pointer" onClick={()=>handleRotationCCW()}> 
                    <RotateIcon />
                  </div>
                  <div className="w-[25px] pt-[2px] cursor-pointer" onClick={()=>handleRotationCW()}>
                    <RotateIconFlip />
                  </div>
                </div> 
                <div className="border-2 w-fit flex space-x-2 px-2 py-1 border-vipGreen rounded bg-white opacity-80 hover:opacity-100 animate">
                  <div className={`w-[25px] mr-2 cursor-pointer ${zoomIndex >= 4 ? "text-gray-300" : ""} `} onClick={()=>handleZoomIn()}> 
                    <PlusIcon />
                  </div>
                  <div className={`w-[25px] mr-3 cursor-pointer ${zoomIndex == 1 ? "text-gray-300" : ""} `} onClick={()=>handleZoomOut()}> 
                    <MinusIcon />
                  </div>
                </div>
                <div className="w-fit ml-5 text-sm cursor-pointer pt-[5px] opacity-80 hover:opacity-100 animate select-none" onClick={()=>handleReset()}> 
                  reset controls 
                </div>
              </div> 
            { 
              <RegionSidebar
                // cons={console.log(props.imageVerdicts[props.imageIndex])}
                setCreateRegion={setCreateRegion}
                createRegion={createRegion}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                regions={props.testVerificationData[props.questionIndex].files[props.imageIndex].regionData}
                //handleRegionDataChange={handleRegionDataChange}
                removeRegion={removeRegion}
                reviewRequest={ReviewRequest}
                //productDetail={props.productDetail}
                //setProductDetail={props.setProductDetail}
                testVerificationData={props.testVerificationData}
                setTestVerificationData={props.setTestVerificationData}
                selectedImage={props.selectedImage}
                questionIndex={props.questionIndex}
                imageIndex={props.imageIndex}
                setImageIndex={props.setImageIndex}
                imageVerdicts={props.imageVerdicts}
                setImageVerdicts={props.setImageVerdicts}
                zoomIndex={zoomIndex}
                rotationIndex={rotationIndex}
                totalImages={props.testVerificationData[props.questionIndex].files.length}
                confidence={confidence}
                setConfidence={setConfidence}
              /> 
            }
            {getTrues() == props.testVerificationData[props.questionIndex].files.length &&
              <RegionSidebarProduct
                setCreateRegion={setCreateRegion}
                createRegion={createRegion}
                selectedRegion={selectedRegion}
                setSelectedRegion={setSelectedRegion}
                regions={props.testVerificationData[props.questionIndex].files[props.imageIndex].regionData} 
                removeRegion={removeRegion}
                reviewRequest={ReviewRequest} 
                testVerificationData={props.testVerificationData}
                setTestVerificationData={props.setTestVerificationData}
                selectedImage={props.selectedImage}
                questionIndex={props.questionIndex}
                imageIndex={props.imageIndex}
                setImageIndex={props.setImageIndex}
                imageVerdicts={props.imageVerdicts}
                setImageVerdicts={props.setImageVerdicts}
              />
            }
            </div>
          </div> 
        </div> 
    </div>
  )
}
