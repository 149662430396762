import React, { Fragment, useEffect, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid'
// import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

const people = [
  { id: 1, name: 'Undefined' },
  { id: 2, name: 'Defect' },
  { id: 3, name: 'Logo' },
  { id: 4, name: 'Board' },
  { id: 5, name: 'Zipper' },
  { id: 6, name: 'Collar' },
  { id: 7, name: 'Size Label' },
  { id: 8, name: 'Washing Instructions' },
  { id: 9, name: 'Generic Label' },
  { id: 10, name: 'Stitch' },
  { id: 11, name: 'Pattern' },
  { id: 12, name: 'Pockets' },
  { id: 13, name: 'Button' },
  { id: 14, name: 'Belt loops' },
  { id: 15, name: 'Laces' },
  { id: 16, name: 'Other' },
]

export const ComboBoxRegion = ( props ) => {
  const [selected, setSelected] = useState(people[0])
  const [query, setQuery] = useState('')

  useEffect(()=>{ 
    if(!selected)
    console.log('selected', selected.name)
      props.handleChangeTimeout(selected.name, "category")
  },[selected])

  const getValue = () => {
    var input = props.testVerificationData[props.questionIndex].files.find(item => item.filename === props.selectedImage.filename).regionData[props.selectedRegion].category || "Undefined"
    let obj = people.find(item => item.name == input)
    return obj
  }

  const filteredPeople =
    query === ''
      ? people
      : people.filter((person) =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )

  return (
    <div className="">
      <Combobox 
        value={getValue()} 
        onChange={setSelected}
      >
        <div className="relative mt-1">
          <div className="">
            <Combobox.Input
              className="mt-1 w-full pl-2 py-1 focus:ring-1 ring-gray-200 focus:outline-none border-[1px] border-gray-200 rounded placeholder:text-gray-400 placeholder:text-light placeholder:text-sm "
              displayValue={(person) => person.name}
              onChange={(event) => setQuery(event.target.value)} 
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              /> 
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
              {filteredPeople.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredPeople.map((person) => (
                  <Combobox.Option
                    key={person.name}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-vipGreen text-white' : 'text-gray-900'
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? 'font-medium' : 'font-normal'
                          }`}
                        >
                          {person.name}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? 'text-white' : 'text-vipGreen'
                            }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}