import React, { useEffect, useState } from "react";
import objectAssign from 'object-assign';
import { RegionSelect } from "assets/react-region-select/lib/RegionSelect";
// import { click } from "@testing-library/user-event/dist/click";
import { SaveIcon, TrashIcon } from "@heroicons/react/solid";
import ReactTextareaAutosize from "react-textarea-autosize";
import { useNavigate, useParams } from "react-router-dom";
import { GetLogos } from "api/GetLogos.js";

export const LogoDetection = (props) => {
  const [brand, setBrand] = useState()
  const [modelConfidence, setModelConfidence] = useState()
  const params = useParams()
  let dataObj1 = {
    comment: "",
    dropdown: 1,
    selected: false
  }

  let dataObj2 = {
    comment: "",
    dropdown: 2,
    selected: false
  }

  const fileName = `https://vip-cms.ams3.digitaloceanspaces.com/aed6c8e7-2dee-4bcf-802a-0424310a9b54.png`
  const fetchLogos = (fileName) => {
    //localStorage.setItem('user', 'marketplace')
    GetLogos({file : fileName})
      .then((out) => {
        console.log('Out', out)
        setBrand(out.description)
        setModelConfidence(out.score)
      })
  }


  return(
    <div className=" " >
      <div className="mt-[50px] mb-[50px]">

        <div
          className="bg-white w-fit h-fit px-2 py-2 rounded text-black ml-8 cursor-pointer hover:shadow"
          onClick={()=>{

            }}>
        </div>
      </div>
      <div className="flex w-full ">
        <div className="bg-white m-auto select-none w-2/3">


            {/* <img tag="test" className="select-none" src={fileName} width=''/> */}

        </div>
        {/* region edit sidebar */}
        <div className="w-1/3 animate ml-4">
          <div className="bg-white rounded-xl p-5 border-2 border-vipGreen">
              <div className="button-confirm" onClick = {() => fetchLogos(fileName) }>Get Results</div>
              <div>{brand && `Brand: ${brand}`}</div>
              <div>{modelConfidence && `Confidence Score: ${modelConfidence.toFixed(2)*100}%`}</div>
          </div>
        </div>

      </div>

    { /* {selectedRegion&&
        <div>
          Selected: {selectedRegion}
        </div>
      }
      {createRegion === true &&
        <div>
          Creating new region
        </div>
      }
      {mouseDown&&
        <div>
          Mouse down
        </div>
      }
      {clickedHandle!== ""&&
        <div>
          Clicked on: {clickedHandle}
        </div>
      } */}

    </div>
  )
}
