import { CalendarOverview } from "assets/components/calendar/calendar-overview";
import { ContentCard } from "assets/components/content-card/ContentCard";
import { GridCardHome } from "assets/components/grid-cards/GridCardHome";
import { PendingVerificationCard } from "assets/components/grid-cards/PendingVerificationCard";
import React, { useEffect, useState } from "react";
import { GetRequests } from "api/GetRequests.js";
import { getHeaders } from '../../firebase'

import imgOne from "./../img/card-images/bag-crop.png"
import imgTwo from "./../img/card-images/shoe-crop.png"

//Pending verifications for the verifier aka verifications assifned to me
export const CompletedVerifications = (props) => {

  const [requests, setRequests] = useState([])
  const headers = localStorage.getItem('token')

  useEffect(() => {
    GetRequests({status: 'complete'}, headers)
      .then((out) => {
        setRequests(out)
      })
  }, [])

  // let requests1 = [
  //   {
  //     name: "Expensive Bag",
  //     id: "1234",
  //     status: 1,
  //     date: "24/3/2022",
  //     img: imgOne,
  //     imgArr: ["img1", "img2"]
  //   },
  //   {
  //     name: "Some Shoes",
  //     id: "2345",
  //     status: 0,
  //     date: "18/6/2022",
  //     img: imgTwo,
  //     imgArr: ["img1", "img2"]
  //   },
  //
  //   {
  //     name: "More Bags",
  //     id: "2345",
  //     status: 2,
  //     date: "14/5/2022",
  //     img: imgOne,
  //     imgArr: ["img1", "img2"]
  //   },
  // ]

  return(
    <div className=" ">
       <div className="text-xl font-semibold py-4 px-6 select-none ">
          Your Completed verifications
      </div>
      <div className="w-full "> {console.log('Requests', requests)}
        {requests && requests.map(item=> (
          <PendingVerificationCard
            item={item}
          />
        ))}
      </div>
    </div>
  )
}
