import axios from "axios";

const GetUserAccountQuery = `
query GetUserAccount  {
    GetUserAccount {
      email
      firstName
      lastName
      company
      country
      city
      phone
    }
}`;

const GetUserAccount = async (token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: GetUserAccountQuery,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data.GetUserAccount;
};

export { GetUserAccount };
