import * as React from "react"

function ClipboardIconYellow(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_698_122)">
        <path
          d="M23.75 0A1.25 1.25 0 0125 1.25a1.25 1.25 0 001.25 1.25 1.25 1.25 0 011.25 1.25V5a1.25 1.25 0 01-1.25 1.25h-12.5A1.25 1.25 0 0112.5 5V3.75a1.25 1.25 0 011.25-1.25A1.25 1.25 0 0015 1.25 1.25 1.25 0 0116.25 0h7.5z"
          fill="#010101"
        />
        <path
          d="M7.5 6.25A1.25 1.25 0 018.75 5H10a1.25 1.25 0 000-2.5H8.75A3.75 3.75 0 005 6.25v30A3.75 3.75 0 008.75 40h22.5A3.75 3.75 0 0035 36.25v-30a3.75 3.75 0 00-3.75-3.75H30A1.25 1.25 0 0030 5h1.25a1.25 1.25 0 011.25 1.25v30a1.25 1.25 0 01-1.25 1.25H8.75a1.25 1.25 0 01-1.25-1.25v-30z"
          fill="#010101"
        />
        <path
          d="M27.137 19.635a1.252 1.252 0 00-1.77-1.77l-6.615 6.618-2.865-2.868a1.252 1.252 0 00-1.77 1.77l3.75 3.75a1.25 1.25 0 001.77 0l7.5-7.5z"
          fill="#010101"
        />
        <path
          d="M20.75-3A1.25 1.25 0 0122-1.75 1.25 1.25 0 0023.25-.5 1.25 1.25 0 0124.5.75V2a1.25 1.25 0 01-1.25 1.25h-12.5A1.25 1.25 0 019.5 2V.75A1.25 1.25 0 0110.75-.5 1.25 1.25 0 0012-1.75 1.25 1.25 0 0113.25-3h7.5z"
          fill="#F1D848"
        />
        <path
          d="M4.5 3.25A1.25 1.25 0 015.75 2H7A1.25 1.25 0 007-.5H5.75A3.75 3.75 0 002 3.25v30A3.75 3.75 0 005.75 37h22.5A3.75 3.75 0 0032 33.25v-30A3.75 3.75 0 0028.25-.5H27A1.25 1.25 0 1027 2h1.25a1.25 1.25 0 011.25 1.25v30a1.25 1.25 0 01-1.25 1.25H5.75a1.25 1.25 0 01-1.25-1.25v-30z"
          fill="#F1D848"
        />
        <path
          d="M24.137 16.635a1.252 1.252 0 00-1.77-1.77l-6.615 6.618-2.865-2.868a1.252 1.252 0 00-1.77 1.77l3.75 3.75a1.25 1.25 0 001.77 0l7.5-7.5z"
          fill="#F1D848"
        />
      </g>
      <defs>
        <clipPath id="clip0_698_122">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default ClipboardIconYellow
