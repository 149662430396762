import axios from "axios";

const InviteUserMutation = `
  mutation InviteUser ($email: String, $type: String, $name: String) {
      InviteUser (email: $email, type: $type, name: $name)
  }`;

const InviteUser = async (data, token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: InviteUserMutation,
      variables: {
        ...data,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data.InviteUser;
};

export { InviteUser };
