import { InformationCircleIcon } from '@heroicons/react/solid'
import React, { useState } from 'react'
import { useEffect } from 'react'

export const Tooltip = (props) => {
  const [showTooltip, setShowTooltip] = useState(false)

  let test = true

  // useEffect(()=>{
  //   console.log('tooltip', showTooltip)
  // },[showTooltip])

  return (
    <div> 
      <div
        onClick={()=>console.log('clicked', showTooltip)}
          className='m-2 ml-0 mt-0'
          onMouseEnter={()=>setShowTooltip(true)}
          onMouseLeave={()=>setShowTooltip(false)} 
      >
          {props.title}
      </div>
      {showTooltip == true && 
      <div className={`absolute bg-white rounded p-4 shadow-lg ${!showTooltip ? 'invisible -translate-y-3 opacity-0' : 'translate-x-0 visible opacity-100'} animate`}>
          {props.children} 
      </div>
      } 
    </div>
  )
}