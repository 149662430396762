import * as React from "react"

function ThreeYellow(props) {
  return (
    <svg
      width={143}
      height={51}
      viewBox="0 0 143 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.84 26.4L23.6 35l-5.76 8.68h-4.4L18.8 35l-5.36-8.6h4.4zm1.92 6.8v3.64H5.36V33.2h14.4zm50.948 13.2c-1.973 0-3.906-.28-5.8-.84-1.866-.587-3.44-1.387-4.72-2.4l2.24-4.04c1.014.827 2.24 1.493 3.68 2 1.44.507 2.947.76 4.52.76 1.867 0 3.32-.373 4.36-1.12 1.04-.773 1.56-1.813 1.56-3.12 0-1.28-.48-2.293-1.44-3.04-.96-.747-2.506-1.12-4.64-1.12h-2.56v-3.56l8-9.52.68 1.96h-15.04V18h19.08v3.48l-8 9.52-2.72-1.6h1.56c3.44 0 6.014.773 7.72 2.32 1.734 1.52 2.6 3.48 2.6 5.88a8.32 8.32 0 01-1.2 4.36c-.8 1.333-2.026 2.413-3.68 3.24-1.626.8-3.693 1.2-6.2 1.2zm60.726-20l5.76 8.6-5.76 8.68h-4.4l5.36-8.68-5.36-8.6h4.4zm1.92 6.8v3.64h-14.4V33.2h14.4z"
        fill="#010101"
      />
      <path
        d="M14.84 24.4L20.6 33l-5.76 8.68h-4.4L15.8 33l-5.36-8.6h4.4zm1.92 6.8v3.64H2.36V31.2h14.4zm50.948 13.2c-1.973 0-3.906-.28-5.8-.84-1.866-.587-3.44-1.387-4.72-2.4l2.24-4.04c1.014.827 2.24 1.493 3.68 2 1.44.507 2.947.76 4.52.76 1.867 0 3.32-.373 4.36-1.12 1.04-.773 1.56-1.813 1.56-3.12 0-1.28-.48-2.293-1.44-3.04-.96-.747-2.506-1.12-4.64-1.12h-2.56v-3.56l8-9.52.68 1.96h-15.04V16h19.08v3.48l-8 9.52-2.72-1.6h1.56c3.44 0 6.014.773 7.72 2.32 1.734 1.52 2.6 3.48 2.6 5.88a8.32 8.32 0 01-1.2 4.36c-.8 1.333-2.026 2.413-3.68 3.24-1.626.8-3.693 1.2-6.2 1.2zm60.726-20l5.76 8.6-5.76 8.68h-4.4l5.36-8.68-5.36-8.6h4.4zm1.92 6.8v3.64h-14.4V31.2h14.4z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default ThreeYellow
