import * as React from "react"

function StatsYellow(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_774_294)">
        <path
          d="M40.5 39.5a1 1 0 01-1 1h-8a1 1 0 01-1-1V9a1 1 0 011-1h8a1 1 0 011 1v30.5zM33 37a1 1 0 001 1h3a1 1 0 001-1V11.5a1 1 0 00-1-1h-3a1 1 0 00-1 1V37zm-5 2.5a1 1 0 01-1 1h-8a1 1 0 01-1-1V19a1 1 0 011-1h8a1 1 0 011 1v20.5zM20.5 37a1 1 0 001 1h3a1 1 0 001-1V21.5a1 1 0 00-1-1h-3a1 1 0 00-1 1V37zm-5 2.5a1 1 0 01-1 1h-8a1 1 0 01-1-1v-13a1 1 0 011-1h8a1 1 0 011 1v13zM8 37a1 1 0 001 1h3a1 1 0 001-1v-8a1 1 0 00-1-1H9a1 1 0 00-1 1v8z"
          fill="#282828"
        />
        <path
          d="M37.5 36.5a1 1 0 01-1 1h-8a1 1 0 01-1-1V6a1 1 0 011-1h8a1 1 0 011 1v30.5zM30 34a1 1 0 001 1h3a1 1 0 001-1V8.5a1 1 0 00-1-1h-3a1 1 0 00-1 1V34zm-5 2.5a1 1 0 01-1 1h-8a1 1 0 01-1-1V16a1 1 0 011-1h8a1 1 0 011 1v20.5zM17.5 34a1 1 0 001 1h3a1 1 0 001-1V18.5a1 1 0 00-1-1h-3a1 1 0 00-1 1V34zm-5 2.5a1 1 0 01-1 1h-8a1 1 0 01-1-1v-13a1 1 0 011-1h8a1 1 0 011 1v13zM5 34a1 1 0 001 1h3a1 1 0 001-1v-8a1 1 0 00-1-1H6a1 1 0 00-1 1v8z"
          fill="#F1D848"
        />
      </g>
      <defs>
        <clipPath id="clip0_774_294">
          <path fill="#fff" d="M0 0H40V40H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default StatsYellow
