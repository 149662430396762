import * as React from "react"

function SamplesIconYellow(props) {
  return (
    <svg
      width={40}
      height={40}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.563 28.063a2.188 2.188 0 014.374 0v2.187a2.188 2.188 0 01-4.375 0v-2.188zm13.124-8.75a2.188 2.188 0 014.375 0V30.25a2.188 2.188 0 01-4.375 0V19.312zm-6.562 4.375a2.188 2.188 0 014.375 0v6.562a2.188 2.188 0 01-4.375 0v-6.563z"
        fill="#011"
      />
      <path
        d="M12.563 7.281h-2.188A4.375 4.375 0 006 11.656v22.969A4.375 4.375 0 0010.375 39H32.25a4.375 4.375 0 004.375-4.375V11.656a4.375 4.375 0 00-4.375-4.375h-2.188V9.47h2.188a2.187 2.187 0 012.188 2.187v22.969a2.188 2.188 0 01-2.188 2.188H10.375a2.188 2.188 0 01-2.188-2.188V11.656a2.187 2.187 0 012.188-2.187h2.188V7.28z"
        fill="#011"
      />
      <path
        d="M24.594 6.188a1.094 1.094 0 011.093 1.093V9.47a1.093 1.093 0 01-1.093 1.094H18.03a1.093 1.093 0 01-1.093-1.094V7.28a1.093 1.093 0 011.093-1.093h6.563zM18.03 4a3.281 3.281 0 00-3.281 3.281V9.47a3.28 3.28 0 003.281 3.281h6.563a3.28 3.28 0 003.281-3.281V7.28A3.281 3.281 0 0024.594 4H18.03z"
        fill="#011"
      />
      <path
        d="M9.563 25.063a2.187 2.187 0 114.374 0v2.187a2.188 2.188 0 01-4.374 0v-2.188zm13.124-8.75a2.188 2.188 0 014.375 0V27.25a2.188 2.188 0 01-4.375 0V16.312zm-6.562 4.375a2.188 2.188 0 014.375 0v6.562a2.188 2.188 0 01-4.375 0v-6.563z"
        fill="#F1D848"
      />
      <path
        d="M9.563 4.281H7.374A4.375 4.375 0 003 8.656v22.969A4.375 4.375 0 007.375 36H29.25a4.375 4.375 0 004.375-4.375V8.656a4.375 4.375 0 00-4.375-4.375h-2.188V6.47h2.188a2.187 2.187 0 012.188 2.187v22.969a2.188 2.188 0 01-2.188 2.188H7.375a2.188 2.188 0 01-2.188-2.188V8.656A2.188 2.188 0 017.376 6.47h2.188V4.28z"
        fill="#F1D848"
      />
      <path
        d="M21.594 3.188a1.094 1.094 0 011.093 1.093V6.47a1.093 1.093 0 01-1.093 1.093H15.03a1.094 1.094 0 01-1.094-1.093V4.28a1.093 1.093 0 011.094-1.093h6.563zM15.03 1a3.281 3.281 0 00-3.281 3.281V6.47a3.281 3.281 0 003.281 3.281h6.563a3.281 3.281 0 003.281-3.281V4.28A3.281 3.281 0 0021.594 1H15.03z"
        fill="#F1D848"
      />
    </svg>
  )
}

export default SamplesIconYellow
