import axios from 'axios'


const GetLogosQuery = `
query DetectLogos($file: String)  {
    DetectLogos(file: $file)
}`

const GetLogos = async (data) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: GetLogosQuery,
      variables: {
        ...data
      }
    }
  })

  return res.data.data.DetectLogos

}

export {
  GetLogos
}
