import axios from "axios";

const DownloadVerificationHistoryMutation = `
mutation DownloadVerificationHistory($email: String)  {
    DownloadVerificationHistory (email: $email)
}`;

const DownloadVerificationHistory = async (data, token) => {
  const res = await axios({
    url: process.env.REACT_APP_API,
    method: "post",
    data: {
      query: DownloadVerificationHistoryMutation,
      variables: {
        ...data,
      },
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  return res.data.data.DownloadVerificationHistory;
};

export { DownloadVerificationHistory };
