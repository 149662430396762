import axios from 'axios'

const CreateUserMutation = `
mutation AddUser ($user: UserInputType, $activationKey: String) {
    AddUser (user: $user, activationKey: $activationKey){
        email
    }
}`

const CreateUser = (data, cb) => {
  axios({
    url: process.env.REACT_APP_OPEN_API,
    method: "post",
    data: {
      query: CreateUserMutation,
      variables: {
        ...data
      }
    }
  })
  .then((result) => cb(false, result.data.data.CreateUser))
  .catch((err) => cb(true, err))
}

export {
  CreateUser
}
