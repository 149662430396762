import { Navbar } from 'assets/components/basic/Navbar'
import { Sidebar } from 'assets/components/basic/Sidebar'
import React, { useState, useEffect } from 'react'
import { useNavigate, Navigate } from 'react-router-dom'
import { refreshToken, useAuth, isApprovedVerifier } from '../../firebase'
import jwt_decode from "jwt-decode";


export const PlatformLayout = ({ children, item }) => {
  const [darkMode, setDarkMode] = useState(false)
  const navigate = useNavigate()
  const authUser = useAuth()

  console.log("au", authUser);

  const RenderLayout = () => {
    return (
      <>
        <div className={`${darkMode ? 'dark' : ''} animate flex flex-col`}>
          <div className={`w-screen h-screen text-gray-900 dark:text-gray-200 flex-1`}>
            <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />
            <div className='bg-gray-100 dark:bg-gray-500 min-h-screen pt-14'>
              {item && item}
            </div>
            {/*<div className='flex bg-gray-50 dark:bg-gray-600  pt-14'>
              <div className='h-[calc(100vh-65px)] '>
                <Sidebar />
              </div>
              <div className='bg-gray-100 dark:bg-gray-500 min-h-screen py-4'>
                {item && item}
              </div>
            </div>*/}
          </div> 
        <footer >
          <div className='bg-vipGreen h-12 flex justify-center items-center'>
            <p className='text-white'>
              support@vipficated.com
            </p>
          </div>
        </footer>
        </div>

      </>
    )
  }

  if (authUser === undefined) {
    console.log('authedUser is undefined')
    return null;
  } else {
    let userToSet = isApprovedVerifier()
    userToSet.then(out => {
      console.log('userToSet:', out)
      if(out) localStorage.setItem('user', out)
    })
  }

  return authUser ? <RenderLayout /> : <Navigate to="/login" />;


}
